import React, { useState } from 'react';
import {
  IconButton,
  Box,
  Typography,
  Divider,
  Stack,
  Modal,
  Card,
  Popover,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { CheckForLight } from '../util/common/CommanFunction';
import {
  isToday,
  isYesterday,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subDays,
  subWeeks,
  subMonths,
  isSameWeek,
  isSameMonth,
  format,
} from 'date-fns';

const CustomDatePickers = ({ apiCallFn }) => {
  const currentDate = new Date();
  const lastWeek = new Date();
  lastWeek.setDate(currentDate.getDate() - 7);
  const [shortcutTemp, setShortcutTemp] = useState({
    states: false,
    temp: 'This Week',
  });
  const [selectionRange, setSelectionRange] = useState({
    startDate: lastWeek,
    endDate: currentDate,
    key: 'selection',
  });
  const handleChange = (val) => {
    console.log(val, 'rangesranges');
  };
  const [firstClick, setFirstClick] = useState(true);
  let start = selectionRange.startDate.toLocaleDateString();
  let end = selectionRange.endDate.toLocaleDateString();
  function checkDate(selectedDate) {
    console.log(selectedDate, 'selectedDate');
    const today = new Date();
    const startOfLastWeek = startOfWeek(subWeeks(today, 1), {
      weekStartsOn: 0,
    });
    const endOfLastWeek = endOfWeek(subWeeks(today, 1), { weekStartsOn: 0 });
    const startOfThisWeek = startOfWeek(today, { weekStartsOn: 0 });
    const endOfThisWeek = endOfWeek(today, { weekStartsOn: 0 });
    const startOfLastMonth = startOfMonth(subMonths(today, 1));
    const endOfLastMonth = endOfMonth(subMonths(today, 1));
    const startOfThisMonth = startOfMonth(today);
    const endOfThisMonth = endOfMonth(today);
    console.log(endOfThisMonth, 'startOfThisWeek');
    if (isToday(selectedDate.startDate)) {
      return 'Today';
    } else if (isYesterday(selectedDate.startDate)) {
      return 'Yesterday';
    } else if (
      selectedDate.startDate >= startOfThisWeek &&
      selectedDate.endDate <= endOfThisWeek
    ) {
      return 'This Week';
    } else if (
      selectedDate.startDate >= startOfLastWeek &&
      selectedDate.endDate <= endOfLastWeek
    ) {
      return 'Last Week';
    } else if (
      selectedDate.startDate >= startOfThisMonth &&
      selectedDate.endDate <= endOfThisMonth
    ) {
      return 'This Month';
    } else if (
      selectedDate.startDate >= startOfLastMonth &&
      selectedDate.endDate <= endOfLastMonth
    ) {
      return 'Last Month';
    } else {
      return 'Other';
    }
  }
  const formattedDate = (date) => (date ? format(date, 'dd-MM-yyyy') : null);

  const handleSelect = (ranges) => {
    let apiStartDate = formattedDate(ranges.selection.startDate);
    let apiEndDate = formattedDate(ranges.selection.endDate);
    let newTime = new Date(ranges.selection.endDate);
    let hh = newTime.getHours();
    let mm = newTime.getMinutes();
    let ss = newTime.getSeconds();
    let endTime = `${hh} ${mm} ${ss}`;
    console.log(endTime, 'endTime');
    if (endTime == '23 59 59') {
      let temp = checkDate({
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
      });
      setShortcutTemp({
        states: true,
        temp: temp,
      });
      setSelectionRange({
        ...selectionRange,
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
      });
      apiCallFn({ apiStartDate, apiEndDate });
      setAnchorEl(null);
    } else if (endTime == '0 0 0') {
      setShortcutTemp({
        states: false,
        temp: null,
      });
      apiCallFn({ apiStartDate, apiEndDate });
      if (firstClick) {
        setSelectionRange({
          ...selectionRange,
          startDate: ranges.selection.startDate,
          endDate: ranges.selection.endDate,
        });
        // apiCallFn({ apiStartDate, apiEndDate });
        console.log('first clicksCount');
        setFirstClick(false);
      } else {
        // alert('!First Click')
        if (
          selectionRange.startDate.getDate() >= selectionRange.endDate.getDate()
        ) {
          setSelectionRange({
            ...selectionRange,
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
          });
          apiCallFn({ apiStartDate, apiEndDate });
        } else {
          setSelectionRange({
            ...selectionRange,
            endDate: ranges.selection.endDate,
          });
          apiCallFn({ apiStartDate, apiEndDate });
        }
        setAnchorEl(null);
        console.log('second clicksCount');
        setFirstClick(true);
      }
    }
    console.log(apiStartDate, apiEndDate, 'apiDateapiDate');
  };
  console.log(firstClick, 'rangesranges');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleLeftRight = (e) => {
    if (e === 'left') {
      if (shortcutTemp.states === null) {
      } else {
      }
    } else if (e === 'right') {
      if (shortcutTemp.states === null) {
      } else {
      }
    }
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      borderRadius="4px"
      padding="4px 8px"
      width="290px"
      justifyContent="space-between"
      p="0"
      height="4.25vh"
      style={{
        backgroundColor: CheckForLight() ? '#ffffff' : '#121212',
        border: CheckForLight() ? '1px solid #c4c4c4' : '1px solid #494949',
      }}
    >
      <IconButton onClick={() => handleLeftRight('left')}>
        <ChevronLeftIcon />
      </IconButton>
      <Divider orientation="vertical" flexItem />
      <Stack
        direction="row"
        style={{ cursor: 'pointer', flex: '1' }}
        onClick={(e) => handleClick(e)}
      >
        <IconButton>
          <CalendarMonthIcon />
        </IconButton>
        {shortcutTemp.temp === null ? (
          <Typography mt="11px" ml="5px" fontSize="13px">
            {`${start} - ${end}`}
          </Typography>
        ) : (
          <Typography
            mt="8px"
            ml="2.5px"
            style={{ color: CheckForLight() ? '#c8c8c8' : '#757575' }}
          >
            {shortcutTemp.temp}
          </Typography>
        )}
      </Stack>
      <Divider orientation="vertical" flexItem />
      <IconButton onClick={() => handleLeftRight('right')}>
        <ChevronRightIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <DateRangePicker
          ranges={[selectionRange]}
          onChange={handleSelect}
        // preview={(DateRange) => handleChange(DateRange)}
        />
      </Popover>
    </Box>
  );
};

export default CustomDatePickers;
