import axios from 'axios';

export const fetchEquipmentTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENTTYPE_TABLE}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const    fetchEquipmentTableById  = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_EQUIPMENTTYPE_TABLE_BYID
      }/${sessionStorage.getItem('customerId')}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

// export const putEquipmenttype = async (data, id) => {
//   console.log(data, "vbnm");
//   try {
//     const response = await axios.put(
//       `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENTTYPE_UPDATE}/${id}`, data
//     );
//     console.log(response);
//     return response.data;
//   } catch (error) {
//     console.error("Error posting data:", error.message);
//     throw error;
//   }
// };
export const putEquipmenttype = async (data, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENTTYPE_UPDATE}/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
/*---------------------- POST Equipment Save ---------------------*/
export const postEquipmentSave = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENTTYPE_SAVE}`,
      obj
    );

    return response.data;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};

export const Equipmentcategorydropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_CATEGORY}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const Equipmenttypedropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_TYPE}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const Getdropdownvalue = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_Sector_dropdown_SectortypeList}`
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const CategorylistDropdown = async (Id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_SECTOR_CATEGORY_LI_DROPDOWN}/${Id}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};