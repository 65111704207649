import React, { useState, useEffect } from 'react';

import { Box, Paper, Grid, Typography, Card, CardContent } from '@mui/material';
import CustomBreadcrumbs from '../util/components/CustomBread';
import { useLocation } from 'react-router-dom';
import ApexChart from '../Components/AnalyticsDashboard/ApexexChart';
import CombinedChart from '../Charts/CombainedChart';
import FadeLoader from 'react-spinners/FadeLoader';
import { removeQuestionMark } from '../util/common/CommanFunction';
import { decryptPaddedQuery } from '../util/security/Cipher';
import moment from 'moment';
import {
  CheckForLight,
  roundedUp,
  transformDataForSpecificYield,
} from '../util/common/CommanFunction';
import { SiteListDetail } from '../../Api/SiteoverviewApi';
import {
  fetchEquipDropdownBySite,
  fetchSiteDropdownByUser,
} from '../../Api/CommonApi';
import {
  fetchSpecificYieldEira,
  fetchParameterComparisonEira,
} from '../../Api/DataAnalystApi';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { ViewAnalytics } from '../Components/AnalyticsDashboard/ViewAnalyst';
import SiteoverviewTable from './SiteoverviewTable';
import TicketView from '../Ticket/TicketView';
const Siteoverview = () => {
  const location = useLocation();
  const [tableData, setTableData] = useState([]);
  const [chart, setChart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableValue, setTableValue] = useState([]);
  const [customView, setCustomView] = useState();
  const [datavalue, setDatavalue] = useState([]);
  const [multiChartData, setMultichartData] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [unitChange, SetUnitChange] = useState(false);
  const [siteIdName, setSiteId] = useState([]);
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);
  const params = {};
  console.log(params);

  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  const isFromSiteList = Object.keys(params).length > 0;
  let SiteId = params.id;
  let name = params.name;
  let irradiation = params.irradiation;
  console.log(params.irradiation);

  console.log(SiteId);
  useEffect(() => {
    fetchData();
    fetchEquipmentDetails();
    getSiteCall();
  }, []);
  const changeUnit = () => {
    SetUnitChange(!unitChange);
  };
  const convertKWhToMWh = (kWh) => kWh / 1000;
  const specificYiled = (data) => {
    return !isNaN(data) ? data.toFixed(1) : '0';
  };

  const fetchData = async () => {
    try {
      const dataa = await SiteListDetail(SiteId);
      const updatedSites = dataa.map((site) => ({
        ...site,
        specificYield: specificYiled(site.specificYield),
      }));

      setDatavalue(updatedSites);
    } catch (e) {}
    setLoading(false);
  };
  console.log(siteIdName);

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem('id');
      let data = await fetchSiteDropdownByUser(id);
      const equipList = await fetchEquipDropdownBySite(data[0]?.siteId);
      setSiteId([...new Set(data)]);
      setEquipments(equipList);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchEquipmentDetails = async () => {
    try {
      let datavalue = await fetchEquipDropdownBySite(SiteId);
      console.log(datavalue);

      setEquipments(datavalue);
      fetchSpecific(datavalue);
      fetchActivepower(datavalue);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchSpecific = async (datavalue) => {
    const toDateValue = new Date().toISOString().split('T')[0];
    const id = SiteId;
    console.log(datavalue);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === params.name;
    });
    let filteredObjects = datavalue?.filter((obj) =>
      ['Weather Station', 'Energy Meter'].includes(obj.equipmentCategory)
    );
    console.log(filteredObjects);

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    console.log(equipmentIds);

    const datas = {
      GraphType: 'Specific Yield',
      energyFlag: 0,
      capacity: siteIdFilter[0]?.installationCapacity,
      energyGenBasedOn: siteIdFilter[0]?.energyGenBasedOn,
      equipmentIds: equipmentIds,
      fromDate: toDateValue,
      intervalMins: 5,
      siteId: SiteId,
      timeperiod: 'Daily',
      range: 'daily',
      toDate: toDateValue,
    };
    console.log(datas);
    setCustomView(datas);
    const EquipmentForSpecificYield = transformDataForSpecificYield(datavalue);
    console.log(EquipmentForSpecificYield);

    datas.equipmentIdAndCapacity = EquipmentForSpecificYield;
    console.log(EquipmentForSpecificYield);
    try {
      const dataa = await fetchSpecificYieldEira(datas);

      function transformToChartData(initialData) {
        const equipmentMap = new Map();
        datavalue.forEach((item) => {
          equipmentMap.set(item.equipmentId, item);
        });

        // Transform the initialData array
        const transformedData = initialData.map((item) => {
          const equipment = equipmentMap.get(item.equipmentId);
          return {
            todayEnergy: roundedUp(item.todayEnergy),
            timeStamp: item.timestamp,
            specificYield: roundedUp(item.specificYield),
            equipmentid: item.equipmentId,
            equipmentName: equipment ? equipment.displayName : 'Unknown',
          };
        });

        return transformedData;
      }
      const result = transformToChartData(dataa);
      setTableData(result);
      setLoading(false);
    } catch (e) {}
    setLoading(false);
  };
  const fetchActivepower = async (datavaluee) => {
    const toDateValue = new Date().toISOString().split('T')[0];
    const id = SiteId;
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === params.name;
    });

    const equipmentId = datavaluee
      .map((item) => item.equipmentId)
      .sort((a, b) => a - b);

    const datas = {
      GraphType: 'Active Power',
      energyFlag: 0,
      capacity: siteIdFilter[0]?.installationCapacity,
      energyGenBasedOn: siteIdFilter[0]?.energyGenBasedOn,
      equipmentIds: equipmentId,
      parameters: ['ActivePower'],
      fromDate: toDateValue,
      intervalMins: 5,
      siteId: SiteId,
      timeperiod: 'Daily',
      range: 'daily',
      toDate: toDateValue,
    };

    try {
      let responseData = [];
      responseData = await fetchParameterComparisonEira(datas);
      setChart(responseData);
      console.log(responseData);

      const dataFields = ['ActivePower'];
      const seriesData = [];

      dataFields.forEach(async (field) => {
        responseData.forEach(async (data) => {
          console.log(field);

          let equipmentName = datavaluee?.filter((dataValue) => {
            return (
              dataValue.equipmentId ===
              Number(data.equipmentId ? data.equipmentId : data.EquipmentId)
            );
          });
          console.log(equipmentName);
          console.log(data);

          const timeStampData = data.timeStamp
            ? data.timeStamp
            : data.TimeStamp;

          const timeStamp = new Date(timeStampData).getTime();

          console.log(timeStamp);

          const value =
            data[field] !== null ? Number(roundedUp(data[field])) : 0;
          const seriesName = `${equipmentName[0]?.displayName} - ${field}`;
          console.log(value);

          const existingSeries = seriesData.find(
            (series) => series.name === seriesName
          );
          if (existingSeries) {
            existingSeries.data.push([timeStamp, value]);
          } else {
            const newSeries = {
              name: seriesName,
              data: [[timeStamp, value]],
            };
            seriesData.push(newSeries);
            console.log(seriesData);

            let filteredArray = seriesData.filter((obj) => {
              return !obj.data.every(([_, value]) => isNaN(value));
            });

            setMultichartData(filteredArray);
            return filteredArray;
          }
        });
      });
      console.log(seriesData);

      let filteredArray = seriesData.filter((obj) => {
        return !obj.data.every(([_, value]) => isNaN(value));
      });
      console.log(filteredArray);

      setMultichartData(filteredArray);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };
  const fallBack = (
    <Card
      sx={{
        width: '100%',
        height: '53vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '53vh',
        }}
      >
        <Typography
          style={{
            fontSize: '1.5rem',
            textTransform: 'capitalize',
            lineHeight: '14.52px !important',
            color: ' rgb(113, 118, 111)',
          }}
        >
          No Data Available
        </Typography>
      </CardContent>
    </Card>
  );

  const paths = isFromSiteList
    ? [
        { label: 'Home', path: '/menu' },
        { label: 'Site List', path: '/menu/siteList' },
        { label: datavalue[0]?.siteName, path: -1 },
      ]
    : '';
  return loading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20%',
      }}
    >
      <FadeLoader color="#000440" loading={true} />
    </div>
  ) : (
    <div>
      <div>
        <Box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Paper
            elevation={0}
            style={{
              width: '100%',
              borderRadius: '2px 2px 0px 0px',
              userSelect: 'none',
              height: '5vh',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              width={'100%'}
              direction="row"
              justifyContent="space-between"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Grid
                item
                xs={4}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <CustomBreadcrumbs
                  paths={paths || []}
                  separatorSize="18px"
                  fontSize="14px"
                />
              </Grid>

              <Grid
                item
                xs="auto"
                style={{ display: 'flex', alignItems: 'center' }}
              ></Grid>
            </Grid>
          </Paper>
        </Box>
      </div>
      <div style={{ marginTop: '1%' }}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginTop: '0.5%',
          }}
        >
          <Paper
            elevation={0}
            style={{
              display: 'flex',
              width: '100%',
              borderRadius: '2px 2px 0px 0px',
              userSelect: 'none',
              height: '20vh',
              userSelect: 'none',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              spacing={4}
              columns={48}
              style={{ padding: '20px 20px 20px 20px' }}
            >
              <Grid item xs={8}>
                <Typography style={{ color: 'gray' }}>
                  Specific Yield
                </Typography>
                <Typography>{datavalue[0]?.specificYield}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: 'gray' }}>Today Energy</Typography>
                <Typography>{datavalue[0]?.sumOfTodayEnergy}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: 'gray' }}>Total Energy</Typography>
                <Typography style={{ position: 'center' }}>
                  {unitChange
                    ? `${(
                        parseFloat(datavalue[0]?.sumOfTotalEnergy || 0) / 1000
                      ).toFixed(3)} MWh`
                    : `${parseFloat(
                        datavalue[0]?.sumOfTotalEnergy || 0
                      ).toFixed(2)} kWh`}
                  {unitChange ? (
                    <ArrowDropUpOutlinedIcon
                      style={{ alignItems: 'center' }}
                      onClick={changeUnit}
                    />
                  ) : (
                    <ArrowDropDownOutlinedIcon onClick={changeUnit} />
                  )}
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography style={{ color: 'gray' }}>Active Power</Typography>
                <Typography>{datavalue[0]?.sumOfActivePower}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: 'gray' }}>Capacity</Typography>
                <Typography>{`${datavalue[0]?.installationCapacity} kWp`}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: 'gray' }}>
                  Inverter Capacity
                </Typography>
                <Typography style={{ alignItems: 'center' }}>
                  {datavalue[0]?.invertersCapacity}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: 'gray' }}>CUF</Typography>
                <Typography>{datavalue[0]?.cuf}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: 'gray' }}>Co2 Avoided</Typography>
                <Typography>{datavalue[0]?.co2}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: 'gray' }}>Today Events</Typography>
                <Typography style={{ alignItems: 'center' }}>
                  {datavalue[0]?.todayEvents}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: 'gray' }}>Total Events</Typography>
                <Typography>{datavalue[0]?.totalEvents}</Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography style={{ color: 'gray' }}>PR</Typography>
                <Typography>{datavalue[0]?.performanceRatio}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: 'gray' }}>Status</Typography>
                <Typography>{datavalue[0]?.siteStatus}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </div>

      <div>
        <Grid container columnSpacing={2} style={{ marginTop: '1%' }}>
          <Grid item lg={6}>
            <Paper elevation={0}>
              {tableData.length > 0 ? (
                <CombinedChart
                  tableData={tableData}
                  customView={customView}
                  WidgetName={`Specific Yield`}
                  chartName={`Specific Yield`}
                  from={'Vanalytics'}
                />
              ) : (
                <div style={{ color: 'black' }}>{fallBack}</div>
              )}
            </Paper>
          </Grid>
          <Grid item lg={6}>
            <Paper elevation={0}>
              {chart.length > 0 ? (
                <ApexChart
                  tableData={chart}
                  chartName="Parameter Comparision"
                  multiChartData={multiChartData}
                  name="paramter"
                  timeValue="Today"
                  widgetName={`Daily Generation - ${name}`}
                  from={'Vanalytics'}
                />
              ) : (
                <div>{fallBack}</div>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: '1%' }}>
        <SiteoverviewTable SiteId={SiteId} irradiation={irradiation} />
      </div>
    </div>
  );
};
export default Siteoverview;
