export function extractAndSortString(data) {
  console.log(data);
  
    const inputEntries = Object?.entries(data)
      .filter(([key, value]) => key.startsWith('inputCurrent'))
      .map(([key, value]) => ({ key, value }));
  
    // Filter voltage entries
    const voltageEntries = Object?.entries(data)
      .filter(([key, value]) => key.startsWith('inputVoltage'))
      .map(([key, value]) => ({ key, value }));
  
    // Sort input entries
    inputEntries.sort((a, b) => {
      const aIndex = parseInt(a.key.split('_')[1]);
      const bIndex = parseInt(b.key.split('_')[1]);
      return aIndex - bIndex;
    });
  
    let usedVoltageValue = null;
  
    // If only one voltage value is present and the rest are null, use that for all power calculations
    const nonNullVoltageEntries = voltageEntries.filter(
      ({ value }) => value !== null
    );
    if (nonNullVoltageEntries.length === 1) {
      usedVoltageValue = nonNullVoltageEntries[0].value;
    }
  
    // Create the sorted string entries
    const sortedString = inputEntries.map(({ key, value }, index) => {
      const inputKey = key;
      const voltageKey =
        usedVoltageValue !== null
          ? voltageEntries[0].key
          : voltageEntries[index].key;
      const inputValue = value;
      const voltageValue =
        usedVoltageValue !== null && inputValue !== 0
          ? usedVoltageValue
          : voltageEntries[index].value;
      const inputPower = `inputPower_${index + 1}`;
      const result = (inputValue * voltageValue).toFixed(2);
      console.log(inputValue, voltageValue, result, 'power');
      const stringKey = `String_${index < 9 ? '0' : ''}${index + 1}`;
  
      return {
        [stringKey]: {
          [inputKey]: inputValue == null ? '    -' : inputValue,
          [voltageKey]: voltageValue == null ? 0 : voltageValue,
          [inputPower]: result,
          down: inputValue === 0 || voltageValue === 0 ? true : false,
        },
      };
    });
  
    return sortedString;
  }
  
  export function isAnyDown(obj, index) {
    console.log(obj);
    
    const data = extractAndSortString(obj);
    // Loop through each object until the given index
    for (let i = 0; i <= index; i++) {
      // Get the keys of the object at index i
      let keys = Object.keys(data[i]);
  
      // Loop through each key in the object
      for (let key of keys) {
        // Check if the down value is true
        if (data[i][key].down === true) {
          return true;
        }
      }
    }
    return false;
  }