import axios from "axios";
export const fetchequipmentTable = async (id) => {

  try {
    const response = await axios.get(`${process.env.REACT_APP_EVENT_TABLE_USING_SITEID}=${id}`);
    console.log(response, "response");
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
}
export const SiteListDetail = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        process.env.REACT_APP_SITE_DETAILS
      }/${id}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

  export const fetchParameterListDetails = async (data) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PARAMETERLIST}=${data}`);
    
      return response.data
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };
  
export const parameterComparisonApplyChanges = async (data) =>{ 
    try {
      const response = await axios.post(`${process.env.REACT_APP_PARAMTERCOMPARISON}`,data);
      return response.data;
    } catch (error) {
      throw error;
    }
  
   }

   export const fetchEquipmentIdDetails = async (data) => {
    try {
  
      const response = await axios.get(`${process.env.REACT_APP_EQUIPMENTLIST}/${data}`);
   
      return response.data
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };