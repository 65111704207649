import React, { useEffect, useState, useMemo } from "react";
import "./style.css";
import {
  styled,
  useTheme,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { useNavigate } from "react-router-dom";
import TextFormatSharpIcon from "@mui/icons-material/TextFormatSharp";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CustomSnackbar from "../util/components/CustomSnackbar";
/*---------------------------------------------- MUI Components ----------------------------*/
import {
  Box,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Popper,
  Snackbar,
  Alert,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Badge,
  Grid,
  Autocomplete,
  TextField,
  Stack,
  Checkbox,
  Divider,
} from "@mui/material";
/*----------------------------------------------- Mui icons ----------------------------------*/
import TuneIcon from "@mui/icons-material/Tune";
import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import errorMsg from "../util/errorMessage.json";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import SolarPowerRoundedIcon from "@mui/icons-material/SolarPowerRounded";
import MenuIcon from "@mui/icons-material/Menu";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import TrendingUpSharpIcon from "@mui/icons-material/TrendingUpSharp";
import CategoryIcon from "@mui/icons-material/Category";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import DataThresholdingOutlinedIcon from "@mui/icons-material/DataThresholdingOutlined";
import { TbFileReport } from "react-icons/tb";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import HandshakeIcon from "@mui/icons-material/Handshake";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FactorySharpIcon from "@mui/icons-material/FactorySharp";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ConstructionIcon from "@mui/icons-material/Construction";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import GamepadOutlinedIcon from "@mui/icons-material/GamepadOutlined";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import MotionPhotosAutoOutlinedIcon from "@mui/icons-material/MotionPhotosAutoOutlined";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
/*--------------------------- Images-----------------------------*/
// import Image from '../../images/eiralogo.jpg'
import lightThemeIcon from "../../images/headerLogo.svg";
import Modal from "@mui/material/Modal";
import userIconLight from "../../images/wBadge.svg";
import userIconDark from "../../images/Avatar.svg";

import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
// ======add ticket Api================
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ticketSchema } from "../util/ValidateSchema";
import {
  EquipmentlistDropdown,
  CategorylistDropdown,
  EquipmentTypelistDropdown,
  postticketdata,
  fetchTicketTable,
  fetchSiteDetails,
} from "../../Api/TicketApi";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { ticketConfigField } from "../util/TextField";
import { useAuth } from "../Auth/AuthContext";
import {
  getBroadcastChannel,
  verifyToken,
} from "../util/security/BroadcastChannel";
import { paddedEncryptQuery, queryDecrypt } from "../util/security/Cipher";
import {
  CheckForLight,
  getActivityPermissions,
} from "../util/common/CommanFunction";
const drawerWidth = 250;

const isOEM = sessionStorage.getItem("userTypeId") == 1;

let LogoImage = isOEM
  ? sessionStorage.getItem("companyLogoPath")
  : sessionStorage.getItem("customerLogoPath");

const isLogoPresent = LogoImage !== "null" && LogoImage !== null;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "15ch",
    },
  },
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
const Item = styled(Paper)(({ theme }) => ({}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
};
const Type = [
  { label: "Operation", value: "1" },
  { label: "Manitenance", value: "0" },
];
const Priority = [
  { label: "High", value: "1" },
  { label: "Medium", value: "0" },
  { label: "Low", value: "0" },
];
const ColorModeContext = React.createContext({ toggleColorMode: () => {} });
function MenuBar({ showPrompt }) {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openvalue, setvalueOpen] = React.useState(false);
  const [opencollapse, setOpenCollapse] = React.useState(false);
  const [logout, setlogout] = React.useState();
  const [imageset, setimageset] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [siteIdName, setSiteId] = useState([]);
  const [site, setSite] = React.useState("");
  const [Equipment, setEquipmet] = React.useState("");
  const [EquipmetId, setEquipmentId] = useState([]);
  const [expandedList, setExpandedList] = useState("");
  const [showEiraInSidebar, setShowEiraInSidebar] = React.useState(false);
  const location = useLocation();

  const [toBackend, setToBackend] = useState(false);
  const [currentPage, setCurrentPage] = React.useState("");
  const handlevalueOpen = () => setvalueOpen(true);
  const [SiteReport, setSiteReport] = useState();

  // =============Ticket state==============
  const [textField, setTextField] = useState([]);
  const handleClosee = () => setOpen(false);
  const [categoryvalue, setCategoryvalue] = useState([]);
  const [equipmevalue, setEquipmevalue] = useState([]);
  const { state, dispatch } = useAuth();
  const initialState = {
    stateName: "",
    description: "",
    siteId: "",
    remarks: "",
    equipmentId: "",
    siteName: "",
    equipmentType: "",
    createdBy: "",
    categoryName: "",
    closedTimestamp: "",
    subject: "",
    ticketCategoryId: "",
    startedTimestamp: "",
    ticketTypeId: "",
    statusName: "",
    ticketTypeName: "",
    scheduleOn: "",
    assignedTo: "",
    ticketStateId: "",
    ticketCode: "",
    ticketStatusId: "",
    ticketId: "",
    priority: "",
  };
  const initialSnack = { open: false, severity: "error", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [ticketFields, setTicketFields] = useState(initialState);
  const [rolesString, setRolesString] = useState("");
  console.log(ticketFields);
  const [edit, setEdit] = useState(false);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };

  useEffect(() => {
    // getSiteCall();
    // getEquipmentCall();
    const rolesString = queryDecrypt(sessionStorage.getItem("roles"));
    setRolesString(rolesString);
  }, []);
  const rolesArray = rolesString ? JSON.parse(rolesString) : [];

  const HasTicketing = getActivityPermissions(
    rolesArray,
    "ticketConfiguration"
  );

  const TicketIsActive =
    HasTicketing.isPresent &&
    HasTicketing.view &&
    HasTicketing.isActive &&
    HasTicketing.create;
  const handleSite = (data, val) => {
    if (val === null) {
      setSite("");
    } else {
      setSite(val);
    }
  };
  const handleEquipment = (data, val) => {
    if (val === null) {
      setEquipmet("");
    } else {
      setEquipmet(val);
    }
  };
  let Access;
  // const handlevalueOpen = () => {
  //   setvalueOpen(true)
  //   setSnack(initialSnack)
  //   setTicketFields(initialSnack)
  // }
  // const handlevalueClose = () => setvalueOpen(false);
  useEffect(() => {
    const pageName = location.pathname.split("/").slice(-2).join("/");
    // const pageName = location.pathname.split('/').pop();

    setCurrentPage(pageName);
    if (
      pageName === "menu/view" ||
      pageName === "menu/report" ||
      pageName === "menu/customise-alerts" ||
      pageName === "menu/equipmentreplace" ||
      pageName === "menu/equipmentlist" ||
      pageName === "menu/analytics" ||
      pageName === "menu/ticket" ||
      pageName === "menu/view-analyst" ||
      pageName === "menu/add_analyst"
    ) {
      setExpandedList("Monitor");
    } else if (
      pageName === "menu/Customerlist" ||
      pageName === "menu/userconf" ||
      pageName === "menu/siteconfiguration" ||
      pageName === "menu/equipmentconfiguration" ||
      pageName === "menu/configurationequipment" ||
      pageName === "menu/userrole" ||
      pageName === "menu/multiSite" ||
      pageName === "menu/userroletable" ||
      pageName === "menu/Configuration" ||
      pageName === "menu/companylist"
    ) {
      setExpandedList("Admin");
    } else if (
      pageName === "menu/Standardparameter" ||
      pageName === "menu/error" ||
      pageName === "menu/sector" ||
      pageName === "menu/industrytype" ||
      pageName === "menu/equipmenttype" ||
      pageName === "menu/downtime" ||
      pageName === "menu/valueScreen" ||
      pageName === "menu/equipmentCategory" ||
      pageName === "menu/statusConfiguration" ||
      pageName === "menu/ftpConfiguration" ||
      pageName === "menu/formula" ||
      pageName === "menu/subscription"
    ) {
      setExpandedList("Config");
    }
  }, [location.pathname]);

  const handlelogClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const logopen = Boolean(anchorEl);
  const id = logopen ? "simple-popper" : undefined;
  const handleDrawerOpen = () => {
    setOpen(true);
    setimageset(false);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setimageset(true);
  };
  const handleClick = () => {
    setOpenCollapse(!opencollapse);
  };

  const handlechange = (page) => {
    if (expandedList === page) {
      setExpandedList("");
    } else {
      setExpandedList(page);
    }
  };

  const handleNavigate = async (value, currentSection) => {
    if (value === "") {
      navigate("/menu");
    } else if (currentSection === "menu") {
      navigate(`/menu/${value}`);
    } else {
      navigate(`/config/${value}`);
    }
    setTimeout(() => {
      handleDrawerClose();
    }, 100);
  };

  const hostname = window.location;
  const firstPath = window.location.pathname
    .split("/")
    .filter((e) => e !== "")[0];
  const isNotSubPath = firstPath === "menu" || firstPath === "config";
  const token = sessionStorage.getItem("jwtToken");
  const broadcastChannel = getBroadcastChannel();

  const handleMyProfile = () => {
    const dataToSend = { key: sessionStorage.getItem("id") };
    const queryParams = new URLSearchParams(dataToSend).toString();
    const encryptedQuery = paddedEncryptQuery(queryParams);

    window.open(
      isNotSubPath
        ? `/config/profile/profilePage?${encryptedQuery}`
        : `/${firstPath}/config/profile/profilePage?${encryptedQuery}`
    );
    dispatch({ type: "LOGIN" });
    broadcastChannel.postMessage({
      type: "profileOpened",
      data: { profileId: "123" },
      token: token,
    });
  };

  const handleAdmin = () => {
    const route = hasPermission("userConfiguration")
      ? "userconf"
      : hasPermission("userRoleConfiguration")
      ? "userrole"
      : hasPermission("userSiteMap")
      ? "multiSite"
      : null;
    window.open(
      isNotSubPath
        ? `/config/admin/${route}`
        : `/${firstPath}/config/admin/${route}`
    );
    dispatch({ type: "LOGIN" });
    broadcastChannel.postMessage({
      type: "adminOpened",
      data: { profileId: "123" },
      token: token,
    });
  };

  function hasPermission(activityName) {
    const rolesArray = rolesString ? JSON.parse(rolesString) : [];
    const activityEntry = rolesArray.find(
      (entry) => entry.activityName === activityName
    );
    return (
      activityEntry && activityEntry.status == 1 && activityEntry.view == 1
    );
  }
  const canAccessAdmin = !(
    hasPermission("userConfiguration") ||
    hasPermission("userRoleConfiguration") ||
    hasPermission("userSiteMap")
  );

  const hasNonEmptyValue = Object.values(ticketFields).some(
    (value) => value !== ""
  );
  const isdisable = !(
    (ticketFields.siteName?.length > 0 &&
      ticketFields.equipmentName?.length > 0 &&
      ticketFields.priority?.length > 0 &&
      ticketFields.equipmentCategory?.length > 0 &&
      ticketFields.equipmentType?.length > 0 &&
      ticketFields.description?.length > 0 &&
      ticketFields.subject?.length > 0) ||
    ticketFields.description?.length > 0
  );
  console.log(ticketFields);

  const handleLogout = () => {
    broadcastChannel.postMessage({ type: "logout", token: token });
  };

  const handleLogoutCall = () => {
    //set theme to local storage for recovery
    localStorage.setItem("theme", sessionStorage.getItem("theme"));

    //clear session storage
    sessionStorage.clear();

    //if there is a theme in local storage, set it to session storage or default and clear local storage
    localStorage.getItem("theme") &&
      sessionStorage.setItem("theme", localStorage.getItem("theme") || "light");
    localStorage.clear();

    //navigate to login
    navigate("/");
    if (
      document.visibilityState !== "visible" &&
      window.opener &&
      !window.opener.closed
    ) {
      window.close();
    }
    dispatch({ type: "LOGOUT" });
  };

  // Add event listener for messages from other browsing contexts
  useEffect(() => {
    const handleMessage = (event) => {
      console.log(event, "channel");
      const receivedData = event.data;
      if (verifyToken(receivedData.token)) {
        if (receivedData.type === "logout") {
          handleLogoutCall();
        }
      } else if (!receivedData.token) {
        handleLogoutCall();
        throw new Error("No Token found.");
      } else {
        handleLogoutCall();
        throw new Error("Invalid token.");
      }
    };

    broadcastChannel.onmessage = handleMessage;
  }, []);

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const buttonBg = (route) => ({
    ...(imageset
      ? {
          backgroundColor:
            currentPage.split("/").pop() === route || expandedList === route
              ? theme.palette.mode === "light"
                ? "rgba(179, 215, 255, 0.3)"
                : "rgba(179, 215, 255, 0.1)"
              : null,
          width: imageset ? "30px" : "40px",
          height: imageset ? "30px" : "40px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          marginLeft: "9%",
          borderRadius: "5px 5px 5px 5px",
        }
      : {
          width: "40px",
          height: "40px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          borderRadius: "5px 5px 5px 5px",
        }),
  });

  const toolStyle = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  };

  const buttonBgMain = (route) => ({
    ...(imageset
      ? {
          backgroundColor:
            currentPage.split("/").pop() === route || expandedList === route
              ? theme.palette.mode === "light"
                ? "rgba(179, 215, 255, 0.3)"
                : "rgba(179, 215, 255, 0.1)"
              : null,
          width: "40px",
          height: "40px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          borderRadius: "5px 5px 5px 5px",
        }
      : {
          width: "40px",
          height: "40px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          borderRadius: "5px 5px 5px 5px",
        }),
  });

  const iconColor = (route) => {
    return {
      color:
        currentPage.split("/").pop() === route || expandedList === route
          ? "rgba(0, 74, 255, 1)"
          : theme.palette.mode === "light"
          ? "rgba(50, 71, 92, 0.6)"
          : "#DBDBEB",
      fontSize: currentPage.split("/").pop() !== "menu" ? "22px" : "20px",
    };
  };

  const iconColorMain = (route) => {
    return {
      color:
        currentPage.split("/").pop() === route || expandedList === route
          ? "rgba(0, 74, 255, 1)"
          : theme.palette.mode === "light"
          ? "rgba(50, 71, 92, 0.6)"
          : "#DBDBEB",
      fontSize: "22px",
    };
  };
  const txtColor = (route) => {
    return {
      color:
        currentPage.split("/").pop() === route || expandedList === route
          ? "rgba(0, 74, 255, 1)"
          : theme.palette.mode === "light"
          ? "rgba(50, 71, 92, 0.6)"
          : "#DBDBEB",
      fontSize: "15px",
    };
  };

  const MyListItem = ({
    route,
    page,
    label,
    icon,
    configLabel,
    pageName,
    currentSection,
  }) => {
    const segments = location.pathname.split("/");
    const PresentSection = segments.length > 3 ? segments[2] : segments[1];
    const buttonStyles = buttonBg(page);
    const role = rolesArray.find((data) => data.activityName === pageName);
    const hasPermission =
      role && role.view === 1 && PresentSection === currentSection;
    if (!hasPermission) {
      return null;
    }
    return (
      <Tooltip
        title={
          imageset ? (configLabel ? label + " " + configLabel : label) : null
        }
        placement="right"
      >
        <ListItemButton
          onClick={(e) => {
            handleNavigate(route, currentSection);
          }}
          style={{
            paddingTop: "2px",
            paddingBottom: "2px",
          }}
        >
          <ListItemIcon>
            <div style={buttonStyles}>
              {React.cloneElement(icon, {
                open: open,
                style: iconColor(page),
              })}
            </div>
          </ListItemIcon>
          <ListItemText style={txtColor(page)}>
            {imageset ? (
              <Typography style={{ visibility: "hidden" }}>{label}</Typography>
            ) : (
              <>
                <Typography style={{ fontSize: "15px" }}>{label}</Typography>{" "}
                {configLabel}
              </>
            )}
          </ListItemText>
        </ListItemButton>
      </Tooltip>
    );
  };

  const MyListMain = ({
    page,
    label,
    icon,
    children,
    pageNames,
    currentSection,
  }) => {
    const segments = location.pathname.split("/");

    const PresentSection = segments.length > 3 ? segments[2] : segments[1];
    const buttonStyles = buttonBgMain(page);
    const hasPermission = pageNames.some((page) => {
      const role = rolesArray.find((data) => data.activityName === page);

      return role && role.view === 1 && PresentSection === currentSection;
    });
    if (!hasPermission) {
      return null;
    }
    return (
      <>
        <Tooltip title={imageset ? label : null} placement="right">
          <ListItemButton
            onClick={() => handlechange(page)}
            style={{
              paddingTop: "2px",
              paddingBottom: "2px",
              marginRight: "5%",
            }}
          >
            <ListItemIcon>
              <div style={buttonStyles}>
                {React.cloneElement(icon, {
                  open: open,
                  style: iconColorMain(page),
                })}
              </div>
            </ListItemIcon>
            <ListItemText style={txtColor(page)}>
              <Typography>{label}</Typography>
            </ListItemText>
            {expandedList === page ? (
              <ExpandLess style={iconColor(page)} />
            ) : (
              <ExpandMore style={iconColor(page)} />
            )}
          </ListItemButton>
        </Tooltip>
        <Collapse in={expandedList === page} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{
              marginLeft: imageset ? null : "20px",
              // backgroundColor: "#ececec",
            }}
          >
            {children}
          </List>
        </Collapse>
      </>
    );
  };

  React.useEffect(() => {
    document.body.style.backgroundColor =
      theme.palette.mode === "light" ? "#F6F8FC" : "black";
    document.body.style.color =
      theme.palette.mode === "light" ? "black" : "white";

    // Cleanup when the component unmounts
    return () => {
      document.body.style.backgroundColor = "";
      document.body.style.color = "";
    };
  }, [theme.palette.mode]);

  // =============Ticket function============

  useEffect(() => {
    getSiteCall();
    fetchEquipmenttype();
    // getEquipmentcategory()
    // fetchData()
  }, []);
  const fetchEquipmenttype = async () => {
    try {
      let data = await EquipmentTypelistDropdown();
      setEquipmevalue(data);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchData = async () => {
    try {
      const data = await fetchTicketTable();
      if (Array.isArray(data)) {
        setSiteReport(data);
      } else if (typeof data === "object" && data !== null) {
        setSiteReport([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleClose = () => {
    setvalueOpen(false);
    setTicketFields(initialState);
  };

  const handleSave = async (value) => {
    console.log(ticketFields, value);
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === ticketFields.siteName;
    });

    let equipmentnameIdFilter = EquipmetId?.filter((data) => {
      return data.displayName === ticketFields.equipmentName;
    });

    let equipmenttypeIdFilter = equipmevalue?.filter((data) => {
      return data.ticketTypeName === ticketFields.equipmentType;
    });

    let categoryIdFilter = categoryvalue?.filter((data) => {
      return data.categoryName === ticketFields?.equipmentCategory;
    });
    console.log(
      categoryIdFilter,
      "categoryIdFilter",
      categoryvalue,
      categoryvalue
    );
    let siteIds = [];

    if (ticketFields.equipmentType === "Maintenance") {
      ticketFields.equipementArray.forEach((name) => {
        let equipmentnameIdFilter = EquipmetId.filter((data) => {
          if (data.displayName === name) {
            siteIds.push(data.equipmentId);
          }
        });
      });
    }
    let data = {
      siteId: siteIdFilter[0]?.siteId,
      equipmentId:
        ticketFields.equipmentType !== "Maintenance"
          ? [equipmentnameIdFilter[0]?.equipmentId]
          : siteIds,
      ticketTypeId: equipmenttypeIdFilter[0]?.ticketTypeId,
      ticketCategory: categoryIdFilter[0]?.categoryId,
      subject: value?.subject,
      priority:
        ticketFields.priority === "Medium"
          ? 2
          : ticketFields.priority === "High"
          ? 3
          : 1,
      description: value?.description,
      createdBy: Number(sessionStorage.getItem("id")),
    };

    try {
      setToBackend(true);
      const responseData = await postticketdata(data);
      console.log();

      fetchData();
      // setSnack((prev) => ({
      //   open: true,
      //   severity: 'success',
      //   message: `Data Saved Successfully`,
      // }));
      navigate("/menu/ticket");
      window.location.reload();
      setSnack(errorMsg.success);
      handleClose();
      setToBackend(false);
      setOpen(false);
      return 1;
    } catch (e) {
      setSnack(errorMsg.failure);
      console.error("Error:", e.response.status);
      setToBackend(false);
      return 0;
    }
  };
  const getEquipmentcategory = async (Id) => {
    try {
      let data = await CategorylistDropdown(Id);
      console.log(data, "data");
      setCategoryvalue(data);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (ticketFields.ticketTypeName !== "") {
      let NameID = equipmevalue?.filter((data) => {
        return data.ticketTypeName === ticketFields?.ticketTypeName;
      });
      console.log(NameID, "NameID");
      getEquipmentcategory(NameID[0]?.ticketTypeId);
    }
  }, [ticketFields.ticketTypeName]);

  console.log(EquipmetId, "df");
  const getSiteCall = async (id) => {
    try {
      let data = await fetchSiteDetails(id);
      setSiteId(data);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (ticketFields.siteName !== "") {
      let SitenameID = siteIdName?.filter((data) => {
        return data.siteName === ticketFields?.siteName;
      });

      getEquipmentCall(SitenameID[0]?.siteId);
    }
  }, [ticketFields.siteName]);

  const getEquipmentCall = async (Id) => {
    try {
      let data = await EquipmentlistDropdown(Id);
      setEquipmentId(data);
    } catch (e) {
      console.error(e);
    }
  };
  function getOptions(propName) {
    let option;
    switch (propName) {
      case "siteName":
        return (option = siteIdName.map((option) => option.siteName));
      case "equipmentCategory":
        // return (option = categoryvalue.map((option) => option.equipmentCategory));
        return categoryvalue.map((option) => option.categoryName);
      case "equipmentType":
        return (option = equipmevalue.map((option) => option.ticketTypeName));
      case "equipmentName":
        return (option = EquipmetId.map((option) => option.displayName));
      case "priority":
        return (option = Priority.map((option) => option.label));
      default:
    }
    return option;
  }
  const handleTxtChange = (event, name) => {
    setTicketFields({ ...ticketFields, [name]: event.target.value });
  };
  useEffect(() => {
    if (ticketFields.equipmentType === "Maintenance") {
      getEquipmentcategory(2);
    } else if (ticketFields.equipmentType === "Operation") {
      getEquipmentcategory(1);
    }
  }, [ticketFields]);
  const handleDropdownChange = (event, value, name) => {
    if (name === "equipmentType") {
      setTicketFields({
        ...ticketFields,
        equipmentType: value,
        equipmentName: null,
        equipementArray: null,
      });
    } else {
      setTicketFields({ ...ticketFields, [name]: value });
    }
  };

  const handleMultipleChange = (event, value, name) => {
    setTicketFields({ ...ticketFields, [name]: value });
  };
  let noOptions = ["No Options"];
  const handleUpdate = () => {};
  useEffect(() => {
    let data = ticketConfigField();
    setTextField(data);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        boxShadow: "none",
        // backgroundColor: '',
        height: "6vh",
      }}
    >
      <CssBaseline enableColorScheme />
      <AppBar
        position="fixed"
        open={open}
        elevation={0}
        style={
          theme.palette.mode === "light"
            ? {
                // backgroundColor: "#1C243C",
                // backgroundColor: "#E8E8EA",
                backgroundColor: "#F6F8FC",
                userSelect: "none",
              }
            : { backgroundColor: "black", userSelect: "none", color: "black" }
        }
      >
        <Toolbar style={{ paddingLeft: "14px", paddingRight: "20px" }}>
          {imageset ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!open && (
                <IconButton
                  onMouseEnter={handleDrawerOpen}
                  onClick={handleDrawerOpen}
                  style={{ marginRight: "0", marginLeft: "0" }}
                  sx={{
                    marginRight: 2,
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon
                    sx={{
                      fontSize: "30px",
                      color:
                        theme.palette.mode === "light" ? "black" : "#DBDBEB",
                    }}
                  />
                </IconButton>
              )}
              <img
                alt={`eira logo?${new Date().getTime()}`}
                src={
                  isLogoPresent
                    ? `${LogoImage}?${new Date().getTime()}`
                    : lightThemeIcon
                }
                className="image"
                style={{
                  width: "auto",
                  marginTop: "0",
                  marginLeft: "1%",
                }}
              />
              {/* <Typography
                variant="p"
                sx={{
                  color: "black",
                  marginTop: "8px",
                  marginLeft: "5px",
                  cursor: "default",
                  fontWeight: "bold",
                  userSelect: "none",
                }}
              >
                Eira
              </Typography> */}
            </div>
          ) : (
            <div style={{ marginRight: "30px" }}></div>
          )}
          <div className="header-menu">
            <div>
              <div>
                {/* <TextField
                  size="small"
                  className='searchbar'
                  style={theme.palette.mode === 'light' ? { color: "MidnightBlue", width: '20vw', borderRadius: '90px', marginLeft: '2vw', backgroundColor: 'white' } : { width: '20vw', borderRadius: '90px', marginLeft: '2vw' }}
                  placeholder="Search sites, equipments etc here..."
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"
                    > <Button style={theme.palette.mode === 'light' ? { color: "MidnightBlue" } : { color: "white" }}><SearchOutlinedIcon /></Button></InputAdornment>,
                  }}
                />*/}
              </div>
            </div>
            <div className="header-right" style={{ marginRight: "30px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: " space-evenly",
                  alignItems: "center",
                }}
              >
                <div style={{ cursor: "pointer" }}>
                  <Tooltip
                    title={
                      theme.palette.mode === "light"
                        ? "Dark Mode"
                        : "Light Mode"
                    }
                    userSelect="none"
                    followCursor
                    PopperProps={toolStyle}
                  >
                    <IconButton
                      onClick={() => {
                        const mode = colorMode.toggleColorMode();
                        console.log(theme.palette.mode, mode);
                        // broadcastChannel.postMessage({
                        //   type: 'theme',
                        //   token: token,
                        // });
                        return;
                      }}
                    >
                      {theme.palette.mode === "light" ? (
                        <DarkModeOutlinedIcon
                          sx={{ color: "#32475C", fontSize: "24px" }}
                        />
                      ) : (
                        <LightModeOutlinedIcon
                          sx={{ color: "#DBDBEB", fontSize: "24px" }}
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>

                {TicketIsActive && (
                  <div
                    style={{
                      cursor: "pointer",
                      visibility: TicketIsActive ? "visible" : "hidden",
                    }}
                  >
                    <Tooltip
                      title={`Add Ticket`}
                      userSelect="none"
                      followCursor
                      PopperProps={toolStyle}
                    >
                      <IconButton onClick={handlevalueOpen}>
                        <AddCommentOutlinedIcon
                          sx={{
                            color:
                              theme.palette.mode === "light"
                                ? "#32475C"
                                : "#DBDBEB",
                            fontSize: "24px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                <div>
                  <CustomSnackbar
                    open={snack.open}
                    onClose={handleSnackClose}
                    autoHideDuration={5000}
                    severity={snack.severity}
                    message={snack.message}
                  />
                  <div>
                    {/* ---------   -----save andupdate---- popup----------- */}

                    <Formik
                      key={edit ? "edit" : "add"}
                      enableReinitialize={true}
                      initialValues={ticketFields}
                      validationSchema={ticketSchema}
                      onSubmit={async (values, { resetForm }) => {
                        let submitValue = values;
                        if (edit) {
                          const result = await handleUpdate(submitValue);

                          if (result === 1) {
                            resetForm();
                          }
                        } else {
                          const result = await handleSave(submitValue);

                          if (result === 1) {
                            resetForm();
                          }
                        }
                      }}
                    >
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        handleReset,
                      }) => (
                        <Form>
                          <Modal
                            open={openvalue}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <div
                              // style={{padding:"5px 5px 5px 5px"}}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    // alignItems: 'center',
                                    padding: "30px",
                                    background: CheckForLight()
                                      ? "rgb(246, 248, 252)"
                                      : "#4f4f4f",
                                    borderTopLeftRadius: "20px",
                                    borderTopRightRadius: "20px",
                                  }}
                                >
                                  <div>
                                    <Typography
                                      id="transition-modal-title"
                                      variant="h6"
                                      component="h4"
                                      className="modal-head"
                                      style={{ marginTop: "2%" }}
                                    >
                                      {edit
                                        ? " Update Ticket "
                                        : "Add New Ticket"}
                                    </Typography>
                                  </div>
                                  <div>
                                    <Stack spacing={2} direction="row">
                                      <CloseIcon
                                        onClick={() => handleClose()}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </Stack>
                                  </div>
                                </div>
                                <Divider sx={{ borderColor: "#888" }} />
                                <div
                                  style={{ padding: "20px", marginLeft: "14%" }}
                                >
                                  <Grid
                                    container
                                    rowSpacing={2}
                                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                    style={{ marginTop: "2%" }}
                                  >
                                    <Grid item xs={8}>
                                      <div
                                        style={{
                                          display: "flex",
                                          marginBottom: "1rem",
                                        }}
                                      >
                                        <Grid item xs={6}>
                                          <Typography
                                            style={{
                                              textTransform: "capitalize",
                                              opacity: "0.8",
                                            }}
                                          >
                                            Site Name *
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Autocomplete
                                            disableClearable
                                            disablePortal
                                            // id={`combo-box-demo-${index}`}
                                            size="small"
                                            options={getOptions("siteName")}
                                            onChange={(event, value) =>
                                              handleDropdownChange(
                                                event,
                                                value,
                                                "siteName"
                                              )
                                            }
                                            sx={{ width: "15vw" }}
                                            value={ticketFields.siteName}
                                            renderInput={(params) => (
                                              <div>
                                                <TextField
                                                  {...params}
                                                  placeholder={"E.g: IFP"}
                                                />
                                              </div>
                                            )}
                                            classes={{
                                              option: "autocomplete",
                                            }}
                                          />
                                        </Grid>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          marginBottom: "1rem",
                                        }}
                                      >
                                        <Grid xs={6}>
                                          <Typography
                                            style={{
                                              textTransform: "capitalize",
                                              opacity: "0.8",
                                            }}
                                          >
                                            Ticket Type *
                                          </Typography>
                                        </Grid>

                                        <Grid xs={2}>
                                          <Autocomplete
                                            disableClearable
                                            disablePortal
                                            size="small"
                                            options={getOptions(
                                              "equipmentType"
                                            )}
                                            onChange={(event, value) =>
                                              handleDropdownChange(
                                                event,
                                                value,
                                                "equipmentType"
                                              )
                                            }
                                            sx={{ width: "15vw" }}
                                            value={ticketFields.equipmentType}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder={"E.g: Operation"}
                                              />
                                            )}
                                            classes={{
                                              option: "autocomplete",
                                            }}
                                          />
                                        </Grid>
                                      </div>

                                      {/* Operation & Maintenance */}
                                      {ticketFields?.equipmentType ===
                                        "Operation" && (
                                        <div
                                          style={{
                                            display: "flex",
                                            marginBottom: "1rem",
                                          }}
                                        >
                                          <Grid xs={6}>
                                            <Typography
                                              style={{
                                                textTransform: "capitalize",
                                                opacity: "0.8",
                                              }}
                                            >
                                              Equipment Name *
                                            </Typography>
                                          </Grid>
                                          <Grid xs={2}>
                                            <Autocomplete
                                              size="small"
                                              disableClearable
                                              disabled={!ticketFields.siteName}
                                              value={ticketFields.equipmentName}
                                              options={getOptions(
                                                "equipmentName"
                                              )}
                                              onChange={(event, value) =>
                                                handleDropdownChange(
                                                  event,
                                                  value,
                                                  "equipmentName"
                                                )
                                              }
                                              sx={{ width: "15vw" }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  placeholder={"E.g: INV-01"}
                                                />
                                              )}
                                            />
                                          </Grid>
                                        </div>
                                      )}
                                      {ticketFields?.equipmentType ===
                                        "Maintenance" && (
                                        <div
                                          style={{
                                            display: "flex",
                                            marginBottom: "1rem",
                                          }}
                                        >
                                          <Grid xs={6}>
                                            <Typography
                                              style={{
                                                textTransform: "capitalize",
                                                opacity: "0.8",
                                              }}
                                            >
                                              Equipment Name *
                                            </Typography>
                                          </Grid>

                                          <Grid xs={2}>
                                            <Select
                                              sx={{ width: "15vw" }}
                                              size="small"
                                              disabled={!ticketFields.siteName}
                                              // labelId="demo-multiple-checkbox-label"
                                              id="demo-multiple-checkbox"
                                              multiple
                                              value={
                                                ticketFields.equipementArray ||
                                                []
                                              }
                                              onChange={(event) =>
                                                handleMultipleChange(
                                                  event,
                                                  event.target.value,
                                                  "equipementArray"
                                                )
                                              }
                                              input={<OutlinedInput />}
                                              renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                  return (
                                                    <Typography
                                                      variant="body1"
                                                      sx={{
                                                        color: "lightgray",
                                                      }}
                                                    >
                                                      E.g: INV-01
                                                    </Typography>
                                                  );
                                                }
                                                return selected.join(", ");
                                              }}
                                              displayEmpty
                                              MenuProps={MenuProps}
                                            >
                                              {EquipmetId.length !== 0 ? (
                                                EquipmetId.map((data) => (
                                                  <MenuItem
                                                    key={data.displayName}
                                                    value={data.displayName}
                                                  >
                                                    <Checkbox
                                                      checked={
                                                        ticketFields.equipementArray?.indexOf(
                                                          data.displayName
                                                        ) > -1
                                                      }
                                                    />
                                                    <ListItemText
                                                      primary={data.displayName}
                                                    />
                                                  </MenuItem>
                                                ))
                                              ) : (
                                                <MenuItem disabled>
                                                  <Typography
                                                    variant="body1"
                                                    sx={{ color: "gray" }}
                                                  >
                                                    No Options
                                                  </Typography>
                                                </MenuItem>
                                              )}
                                            </Select>
                                          </Grid>
                                        </div>
                                      )}

                                      <div
                                        style={{
                                          display: "flex",
                                          marginBottom: "1rem",
                                        }}
                                      >
                                        <Grid xs={6}>
                                          <Typography
                                            style={{
                                              textTransform: "capitalize",
                                              opacity: "0.8",
                                            }}
                                          >
                                            Ticket Category *
                                          </Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                          <Autocomplete
                                            disableClearable
                                            disablePortal
                                            disabled={
                                              !ticketFields.equipmentType
                                            }
                                            // id={`combo-box-demo-${index}`}
                                            size="small"
                                            options={getOptions(
                                              "equipmentCategory"
                                            )}
                                            onChange={(event, value) =>
                                              handleDropdownChange(
                                                event,
                                                value,
                                                "equipmentCategory"
                                              )
                                            }
                                            sx={{ width: "15vw" }}
                                            // value={values[data.name]}
                                            renderInput={(params) => (
                                              <div>
                                                <TextField
                                                  {...params}
                                                  placeholder={
                                                    "E.g: Inverter Down"
                                                  }
                                                />
                                              </div>
                                            )}
                                            classes={{
                                              option: "autocomplete",
                                            }}
                                          />
                                        </Grid>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          marginBottom: "1rem",
                                        }}
                                      >
                                        <Grid xs={6}>
                                          <Typography
                                            style={{
                                              textTransform: "capitalize",
                                              opacity: "0.8",
                                            }}
                                          >
                                            Subject *
                                          </Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                          <TextField
                                            id="outlined-basic"
                                            size="small"
                                            placeholder="Subject"
                                            variant="outlined"
                                            sx={{ width: "15vw" }}
                                            // error={!validEmail}
                                            value={ticketFields.subject}
                                            onChange={(e) => {
                                              handleChange(e);
                                              handleTxtChange(e, "subject");
                                            }}
                                          />
                                          <ErrorMessage
                                            name={"subject"}
                                            component="div"
                                            className="errorStyle"
                                            style={{
                                              color: "red",
                                              marginTop: "1%",
                                              textAlign: "left",
                                              marginLeft: "0%",
                                            }}
                                          />
                                        </Grid>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          marginBottom: "1rem",
                                        }}
                                      >
                                        <Grid xs={6}>
                                          <Typography
                                            style={{
                                              textTransform: "capitalize",
                                              opacity: "0.8",
                                            }}
                                          >
                                            Priority *
                                          </Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                          <Autocomplete
                                            disableClearable
                                            disablePortal
                                            // id={`combo-box-demo-${index}`}
                                            size="small"
                                            options={getOptions("priority")}
                                            onChange={(event, value) =>
                                              handleDropdownChange(
                                                event,
                                                value,
                                                "priority"
                                              )
                                            }
                                            sx={{ width: "15vw" }}
                                            value={ticketFields.priority}
                                            renderInput={(params) => (
                                              <div>
                                                <TextField
                                                  {...params}
                                                  placeholder={"E.g: High"}
                                                />
                                              </div>
                                            )}
                                            classes={{
                                              option: "autocomplete",
                                            }}
                                          />
                                        </Grid>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          marginBottom: "1rem",
                                        }}
                                      >
                                        <Grid xs={6}>
                                          <Typography
                                            style={{
                                              textTransform: "capitalize",
                                              opacity: "0.8",
                                            }}
                                          >
                                            Description *
                                          </Typography>
                                        </Grid>
                                        <Grid xs={2}>
                                          <div>
                                            <TextField
                                              id="outlined-basic"
                                              size="small"
                                              placeholder="Description"
                                              variant="outlined"
                                              sx={{ width: "15vw" }}
                                              // error={!validEmail}
                                              value={ticketFields.description}
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleTxtChange(
                                                  e,
                                                  "description"
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              name={"description"}
                                              component="div"
                                              className="errorStyle"
                                              style={{
                                                color: "red",
                                                marginTop: "1%",
                                                textAlign: "left",
                                                marginLeft: "0%",
                                              }}
                                            />
                                          </div>
                                        </Grid>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div style={{ padding: "30px" }}>
                                  <Stack
                                    direction="row"
                                    spacing={2}
                                    style={{
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Item>
                                      <Button
                                        style={{ textTransform: "capitalize" }}
                                        variant="contained"
                                        disabled={
                                          !(
                                            ticketFields?.siteName &&
                                            ticketFields?.priority &&
                                            ticketFields?.equipmentCategory &&
                                            ticketFields?.description &&
                                            ticketFields?.subject &&
                                            (ticketFields.equipmentType !==
                                              "Maintenance" ||
                                              ticketFields.equipementArray) &&
                                            (ticketFields.equipmentType !==
                                              "Operation" ||
                                              ticketFields.equipmentName)
                                          )
                                        }
                                        onClick={handleSubmit}
                                      >
                                        {toBackend ? "Creating..." : "Create "}
                                      </Button>
                                    </Item>
                                    <Item>
                                      <Button
                                        style={{ textTransform: "capitalize" }}
                                        variant="contained"
                                        disabled={!hasNonEmptyValue}
                                        onClick={() => {
                                          handleReset();
                                          setTicketFields(initialState);
                                        }}
                                      >
                                        Reset
                                      </Button>
                                    </Item>
                                  </Stack>
                                </div>
                              </div>
                            </Box>
                          </Modal>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
                {/* <div style={{ cursor: 'pointer' }}>
                  <Tooltip
                    title="Notifications"
                    userSelect="none"
                    placement="bottom"
                    followCursor
                    PopperProps={toolStyle}
                  >
                    <IconButton>
                      <Badge
                        variant="dot"
                        badgeContent=""
                        color="error"
                        overlap="circular"
                      >
                        <NotificationsNoneOutlinedIcon
                          sx={{
                            color:
                              theme.palette.mode === 'light'
                                ? '#32475C'
                                : '#DBDBEB',
                            fontSize: '24px',
                          }}
                        />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </div> */}

                <div
                  style={{
                    backgroundColor: "transparent",
                    border: "0",
                    display: "flex",
                    justifyContent: " space-evenly",
                    alignItems: "center",
                  }}
                  onClick={handlelogClick}
                >
                  <Typography
                    style={{
                      alignItems: "center",
                      display: "flex",
                      fontFamily: "Inter",
                      marginRight: "10px",
                      marginLeft: "20px",
                      color: theme.palette.mode === "light" ? "black" : "white",
                      fontSize: "12px",
                      lineHeight: "14.52px",
                      fontWeight: "500",
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                  >
                    Hi, {sessionStorage.getItem("token")}
                  </Typography>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "0",
                        display: "flex",
                        justifyContent: " space-evenly",
                        alignItems: "center",
                      }}
                      onClick={handlelogClick}
                    >
                      <div style={{ cursor: "pointer" }}>
                        <Tooltip
                          title="Profile"
                          userSelect="none"
                          placement="bottom"
                          followCursor
                          PopperProps={toolStyle}
                        >
                          <img
                            alt="userIcon"
                            src={
                              theme.palette.mode === "light"
                                ? userIconLight
                                : userIconDark
                            }
                          />
                        </Tooltip>
                      </div>
                    </button>
                  </ClickAwayListener>
                </div>
              </div>
            </div>
            {/* ---------------------------------------- Log out FUnctionality ------------------------------  */}
            <Popper
              id={id}
              open={logopen}
              anchorEl={anchorEl}
              style={{ zIndex: 110 }}
            >
              <div>
                <Paper
                  sx={{ marginRight: "10px", marginTop: "6%" }}
                  elevation={5}
                >
                  <MenuList autoFocusItem={logopen} id="menu-list-grow">
                    <MenuItem
                      sx={{
                        ml: 1,
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "transparent",
                        },
                        overflow: "hidden",
                      }}
                    >
                      <Button
                        variant="text"
                        style={{
                          textTransform: "capitalize",
                          userSelect: "none",
                        }}
                        startIcon={<Person2OutlinedIcon />}
                        onClick={handleMyProfile}
                      >
                        My profile
                      </Button>
                    </MenuItem>
                    <MenuItem
                      sx={{
                        ml: 1,
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "transparent",
                        },
                        overflow: "hidden",
                      }}
                    >
                      <Button
                        variant="text"
                        disabled={canAccessAdmin}
                        style={{
                          textTransform: "capitalize",
                          userSelect: "none",
                        }}
                        startIcon={<AdminPanelSettingsOutlinedIcon />}
                        onClick={handleAdmin}
                      >
                        Admin
                      </Button>
                    </MenuItem>
                    <MenuItem
                      sx={{
                        ml: 1,
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "transparent",
                        },
                        overflow: "hidden",
                      }}
                    >
                      <Button
                        variant="text"
                        onClick={() => handleLogout()}
                        style={{ textTransform: "capitalize", color: "red" }}
                        startIcon={<LogoutIcon />}
                      >
                        Log out
                      </Button>
                    </MenuItem>
                  </MenuList>
                </Paper>
              </div>
            </Popper>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        variant="permanent"
        open={open}
        style={{ backgroundColor: "#f6f8fc" }}
        sx={{
          borderColor: "transparent",
          ...(!imageset
            ? {}
            : {
                ".MuiDrawer-paper, .MuiDrawer-root": {
                  borderRight: "transparent !important",
                  backgroundColor:
                    theme.palette.mode === "light" ? "#f6f8fc " : "black",
                },
              }),
        }}
      >
        <DrawerHeader
          style={{
            backgroundColor:
              theme.palette.mode === "light" ? "#F6F8FC" : "black",
            userSelect: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            style={{ alignItems: "center" }}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <img
              alt={`eira logo?${new Date().getTime()}`}
              src={
                isLogoPresent
                  ? `${LogoImage}?${new Date().getTime()}`
                  : lightThemeIcon
              }
              className="image"
              style={{ width: "auto", marginRight: "40px" }}
            />
          </IconButton>
        </DrawerHeader>
        <List
          id="SideBar"
          sx={{
            width: "100%",
            maxWidth: 360,
            backgroundColor:
              theme.palette.mode === "light" ? "#F6F8FC" : "black",
            height: "100vh",
            overflowX: "hidden",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "0.2em",
              display: "none",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "20px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
          component="nav"
        >
          <MyListItem
            route=""
            page="menu"
            pageName="siteList"
            label="Dashboard"
            currentSection="menu"
            icon={<DashboardOutlinedIcon />}
          />
          {/* <MyListItem
            route="dashboard"
            page="dashboard"
            pageName="siteList"
            label="Dashboard-preview"
            currentSection="menu"
            icon={<PreviewOutlinedIcon />}
          /> */}
          <MyListItem
            route="sitelist"
            page="sitelist"
            pageName="siteList"
            label="Site List"
            currentSection="menu"
            icon={<SolarPowerRoundedIcon />}
          />
          <MyListMain
            page="Monitor"
            pageNames={[
              // 'viewTicket',
              "report",
              "customizedAlert",
              "equipmentHistory",
              "equipmentList",
              "analytics",
              "ticketConfiguration",
              "viewAnalytics",
            ]}
            label="Monitoring"
            currentSection="menu"
            icon={<MonitorHeartOutlinedIcon />}
          >
            <MyListItem
              route="ticket"
              page="ticket"
              label="Ticket"
              pageName="ticketConfiguration"
              configLabel="Configuration"
              currentSection="menu"
              icon={<LocalActivityOutlinedIcon />}
            />
            <MyListItem
              route="analytics"
              page="analytics"
              pageName="analytics"
              label="Analytic"
              configLabel="Dashboard"
              currentSection="menu"
              icon={<QueryStatsOutlinedIcon />}
            />
            <MyListItem
              route="view-analyst"
              page="view-analyst"
              pageName="viewAnalytics"
              label="Analytics"
              currentSection="menu"
              icon={<MotionPhotosAutoOutlinedIcon />}
            />
            <MyListItem
              route="report"
              page="report"
              label="Report"
              pageName="report"
              currentSection="menu"
              icon={<AssessmentOutlinedIcon />}
            />
            <MyListItem
              route="customise-alerts"
              page="customise-alerts"
              label="Customise Alert"
              pageName="customizedAlert"
              currentSection="menu"
              icon={<CrisisAlertIcon />}
            />
            {/* <MyListItem
              route="equipmentreplace"
              page="equipmentreplace"
              label="Equipment History"
              pageName="equipmentHistory"
              currentSection="menu"
              icon={<ManageHistoryOutlinedIcon />}
            /> */}
            {/* <MyListItem
              route="view"
              page="view"
              pageName="viewTicket"
              label="View Ticket"
              icon={<ConfirmationNumberOutlinedIcon />}
            /> */}
            {/* <MyListItem
              route="equipmentlist"
              page="equipmentlist"
              label="Equipment List"
              pageName="equipmentList"
              currentSection="menu"
              icon={<ConstructionIcon />}
            /> */}
          </MyListMain>
          <MyListMain
            page="Admin"
            label="Administration"
            pageNames={[
              "customerConfiguration",
              "siteConfiguration",
              "equipmentConfiguration",
              "configurationEquipment",
              "Configuration",
              "companylist",
            ]}
            currentSection="menu"
            icon={<AdminPanelSettingsOutlinedIcon />}
          >
            <MyListItem
              route="companylist"
              page="companylist"
              pageName="companyConfiguration"
              label="Company"
              configLabel="Configuration"
              currentSection="menu"
              icon={<AddBusinessOutlinedIcon />}
            />
            <MyListItem
              route="Customerlist"
              page="Customerlist"
              pageName="customerConfiguration"
              label="Customer"
              configLabel="Configuration"
              currentSection="menu"
              icon={<HandshakeIcon />}
            />
            <MyListItem
              route="siteconfiguration"
              page="siteconfiguration"
              label="Site Configuration"
              pageName="siteConfiguration"
              currentSection="menu"
              icon={<AddHomeWorkIcon />}
            />
            <MyListItem
              route="equipmentconfiguration"
              page="equipmentconfiguration"
              label="Equipment"
              configLabel="Details"
              pageName="equipmentConfiguration"
              currentSection="menu"
              icon={<HomeRepairServiceIcon />}
            />
            <MyListItem
              route="Configuration"
              page="Configuration"
              label="Equipment"
              configLabel="Configuration"
              pageName="equipmentConfiguration"
              currentSection="menu"
              icon={<HandymanOutlinedIcon />}
            />
            {/* <MyListItem
              route="multiSite"
              page="multiSite"
              label="Users Site Map"
              pageName="userSiteMap"
              currentSection="menu"
              icon={<FactorySharpIcon />}
            /> */}
          </MyListMain>
          <MyListMain
            page="Config"
            label="Configuration"
            pageNames={[
              "standardParameter",
              "errorConfiguration",
              "expecteddata",
              "sectorConfiguration",
              "industryConfiguration",
              "equipmentTypeConfiguration",
              "downTime",
              "dailyGeneration",
              "subscription",
              "formula ",
              "equipmentCategory",
              "statusConfiguration",
              "ftpConfiguration",
            ]}
            currentSection="menu"
            icon={<BuildOutlinedIcon />}
          >
            <MyListItem
              route="Standardparameter"
              page="Standardparameter"
              label="Standard Parameter"
              pageName="standardParameter"
              currentSection="menu"
              icon={<TuneIcon />}
            />
            <MyListItem
              route="error"
              page="error"
              label="Error Configuration"
              pageName="errorConfiguration"
              currentSection="menu"
              icon={<ErrorOutlineOutlinedIcon />}
            />
            <MyListItem
              route="expecteddata"
              page="expecteddata"
              label="Expected Data"
              pageName="errorConfiguration"
              currentSection="menu"
              icon={<DataThresholdingOutlinedIcon />}
            />
            <MyListItem
              route="currency"
              page="currency"
              label="Expected Data"
              configLabel="Currency"
              pageName="errorConfiguration"
              currentSection="menu"
              icon={<CurrencyExchangeIcon />}
            />
            <MyListItem
              route="sector"
              page="sector"
              label="Sector"
              pageName="sectorConfiguration"
              currentSection="menu"
              icon={<CheckCircleOutlineIcon />}
            />
            <MyListItem
              route="statusConfiguration"
              page="statusConfiguration"
              label="Status"
              pageName="statusConfiguration"
              currentSection="menu"
              icon={<TrendingUpSharpIcon />}
            />
            <MyListItem
              route="industrytype"
              page="industrytype"
              label="Industry Type"
              pageName="industryConfiguration"
              currentSection="menu"
              icon={<FactorySharpIcon />}
            />
            <MyListItem
              route="equipmentCategory"
              page="equipmentCategory"
              label="Equipment Category"
              pageName="equipmentCategory"
              currentSection="menu"
              icon={<CategoryIcon />}
            />
            <MyListItem
              route="formula"
              page="formula"
              label="Formula"
              pageName="formula"
              currentSection="menu"
              icon={<TextFormatSharpIcon />}
            />
            <MyListItem
              route="equipmenttype"
              page="equipmenttype"
              label="Equipment Type"
              pageName="equipmentTypeConfiguration"
              configLabel="Configuration"
              currentSection="menu"
              icon={<ConstructionIcon />}
            />
            <MyListItem
              route="downtime"
              page="dgr"
              label="Down Time"
              pageName="downTime"
              currentSection="menu"
              icon={<AccessTimeOutlinedIcon />}
            />

            <MyListItem
              route="dailygenerationValue"
              page="dailygenerationValue"
              label="Daily Generation"
              configLabel="Value"
              pageName="downTime"
              currentSection="menu"
              icon={<TbFileReport />}
            />
            <MyListItem
              route="subscription"
              page="subscription"
              label="Subscription"
              pageName="subscription"
              currentSection="menu"
              icon={<PaidOutlinedIcon />}
            />
            <MyListItem
              route="ftpConfiguration"
              page="ftpConfiguration"
              label="FTP Configuration"
              pageName="ftpConfiguration"
              currentSection="menu"
              icon={<DriveFileMoveOutlinedIcon />}
            />
            {/* <MyListItem
              route="document"
              page="document"
              label="Document"
              // pageName="dailyGeneration"
              pageName="equipmentTypeConfiguration"
              // configLabel="Report"
              currentSection="menu"
              icon={<TextSnippetOutlinedIcon />}
            /> */}
            {/* <MyListItem
              route="valueScreen"
              page="valueScreen"
              label="Enter Value"
              // pageName="dailyGeneration"
              pageName="equipmentTypeConfiguration"
              currentSection="menu"
              icon={<ConstructionIcon />}
            /> */}
          </MyListMain>
          <MyListItem
            route="datalogger"
            page="datalogger"
            label="Data Logger"
            pageName="dataLoggerConfiguration"
            currentSection="menu"
            configLabel="Configuration"
            icon={<FileUploadOutlinedIcon />}
          />
          <MyListItem
            route="sop_file"
            page="sop_file"
            label="SOP"
            pageName="SOP"
            currentSection="menu"
            icon={<AccountTreeIcon />}
          />
          <MyListItem
            route="admin/userconf"
            page="userconf"
            label="User Configuration"
            pageName="userConfiguration"
            currentSection="admin"
            icon={<GroupAddRoundedIcon />}
          />
          <MyListItem
            route="admin/userrole"
            page="userrole"
            label="User Role"
            pageName="userRoleConfiguration"
            currentSection="admin"
            icon={<ManageAccountsIcon />}
          />
          <MyListItem
            route="admin/userroletable"
            page="userroletable"
            label="Activity Control"
            pageName="activityName"
            currentSection="admin"
            icon={<GamepadOutlinedIcon />}
          />
          <MyListItem
            route="admin/multiSite"
            page="multiSite"
            label="Users Site Map"
            pageName="userSiteMap"
            currentSection="admin"
            icon={<FactorySharpIcon />}
          />
          {/* <MyListItem
            route="admin/taskmanager"
            page="taskmanager"
            label="Task Manager"
            pageName="userConfiguration"
            currentSection="admin"
            icon={<ScheduleIcon />}
          /> */}
          <MyListItem
            route="profile/profilePage"
            page="profilePage"
            label="User Profile"
            pageName="userConfiguration"
            currentSection="profile"
            icon={<ManageAccountsOutlinedIcon />}
          />
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {showPrompt.showPrompt && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            style={{ marginTop: 50, marginRight: 60 }}
            open={true}
            autoHideDuration={10000}
            onClose={showPrompt.handleClose}
            key={"top  right"}
          >
            <Alert
              onClose={showPrompt.handleClose}
              severity="warning"
              sx={{ width: "100%" }}
            >
              You have been inactive for too long Session expires in 10 seconds
            </Alert>
          </Snackbar>
        )}
        <Outlet />
      </Box>
    </Box>
  );
}
export default function ToggleColorMode({ showPrompt }) {
  const storedTheme = sessionStorage.getItem("theme");
  const [mode, setMode] = useState(storedTheme || "light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
      mode,
    }),
    [mode]
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  sessionStorage.setItem("theme", mode);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <MenuBar showPrompt={showPrompt} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
