import React, { useState, useEffect } from "react";

import FadeLoader from "react-spinners/FadeLoader";
import { downloadExcelTemplate } from "../../Template/ExcelTemplates/ExcelTemplate";
import { errorConfigField } from "../util/TextField";
import * as XLSX from "xlsx";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { SCBAccordian } from "./SCBAccordian";
// ========Modal================
import { Paper, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

/*------------api call-----------------*/
import {
  EnergymeterTables,
  ScbParams,
  ScbTables,
  SensorTables,
  fetchEquipmentlistTable,
} from "../../Api/SiteListApi";

import TableTemplate from "../../Template/TableTemplate";
import Download from "../../Template/Excel/Download";
import CustomSnackbar from "../util/components/CustomSnackbar";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ErrorConfigSchema } from "../util/ValidateSchema";
import { Statusdropdown } from "../../Api/CommonApi";
import errorMsg from "../util/errorMessage.json";
import {
  fetchEachEquipHistory,
  fetchEventTable,
} from "../../Api/EquipmenReplaceApi";
import { decryptPaddedQuery, queryEncrypt } from "../util/security/Cipher";
import {
  getFormatDate,
  removeQuestionMark,
} from "../util/common/CommanFunction";
// import { Getdropdowndata } from "../../Api/UserConfigurationAPI";

export default function EquipmentFilterTable({ errorConfiguration }) {
  /*------------state-----------------*/
  const [equipmentTable, setEquipmentTable] = useState([]);
  console.log(equipmentTable);
  
  const [activeFlag, setactiveFlag] = useState("");
  const [statusDrop, setStatusDrop] = useState([]);
  const [editcall, seteditcall] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setloading] = useState(false);
  const [categoryvalue, setCategoryvalue] = useState([]);
  const [category, setCategory] = useState("");
  const [equipmetvalue, setEquipmevalue] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalvalue, setModalvalue] = useState(true);
  const [specificYeild, setSpecificYeild] = useState(0);
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [siteTab, setSiteTab] = React.useState("");
  const [tableToShow, setTableToShow] = useState("equipment");
  const [stdField, setstdField] = useState({
    customerNaming: "",
    serialNo: "",
    todayEnergy: "",
    totalEnergy: "",
    performanceRatio: "",
    lastUpdate: "",
    status: "",
    equipmentid: "",
    customernaming: "",
    timestamp: "",
    equipmentcode: "",
    primaryserialnumber: "",
  });

  const headcells = [
    {
      label: "Equipment Name",
      id: "displayName",
      view: true,
      default: true,
    },
    {
      label: "Serial No",
      id: "serialNo",
      view: true,
    },
    {
      label: "Today Energy (kWh)",
      id: "todayEnergy",
      view: true,
      default: true,
    },
    {
      label: "Total Energy(KWh)",
      id: "totalEnergy",
      view: true,
    },
    {
      label: "Specific Yield",
      id: "specificYield",
      view: true,
    },
    {
      label: "PR (%)",
      id: "performanceRatio",
      view: true,
    },
    {
      label: "Active Power (kW)",
      id: "activePower",
      view: true,
    },
    {
      label: "LastUpdated",
      id: "energyLastUpdate",
      view: true,
    },
    {
      label: "Status",
      id: "derivedStatusName",
      view: true,
      default: true,
    },
  ];

  const headcellsEnergyMeter = [
    {
      label: "Equipment Name",
      id: "displayName",
      view: true,
      default: true,
    },
    {
      label: "Serial No",
      id: "serialNo",
      view: true,
    },
    {
      label: "Active Power (kWh)",
      id: "activePower",
      view: true,
    },
    {
      label: "Reactive Power (kVAr)",
      id: "reactivePower",
      view: true,
    },
    {
      label: "Import Energy (kWh)",
      id: "importEnergy",
      view: true,
    },
    {
      label: "Export Energy (kWh)",
      id: "exportEnergy",
      view: true,
    },
    {
      label: "Power Factor",
      id: "powerFactor",
      view: true,
    },

    {
      label: "Last Updated",
      id: "lastUpdated",
      view: true,
      default: true,
    },
  ];
  const headCellSCB = [
    {
      label: "Equipment Name",
      id: "displayName",
      view: true,
      default: true,
    },
    {
      label: "Serial No",
      id: "serialNo",
      view: true,
      default: true,
    },
   
    {
      label: "Components",
      id: "components",
      view: true,
      default: true,
    },
    {
      label: "Last Uploaded",
      id: "lastUpdated",
      view: true,
      default: true,
    },
    // {
    //   label: "Status",
    //   id: "status",
    //   view: true,
    //   default: true,
    // },
  ];
  const headcellsSensor = [
    {
      label: "Equipment Name",
      id: "displayName",
      view: true,
      default: true,
    },
    {
      label: "Serial No",
      id: "serialNo",
      view: true,
    },
    {
      label: "Ambient Temperature (°C)",
      id: "ambientTemperature",
      view: true,
    },
    {
      label: "Module Temperature (°C)",
      id: "moduleTemperature",
      view: true,
    },
    {
      label: "Irradiation (Wh/m2)",
      id: "irradiation",
      view: true,
    },
    {
      label: "Irradiance (W/m2)",
      id: "irradiance",
      view: true,
    },
    {
      label: "Wind Speed (°)",
      id: "windSpeed",
      view: true,
    },
    {
      label: "Wind Direction (m/s)",
      id: "windDirection",
      view: true,
    },
    {
      label: "Last Updated",
      id: "lastUpdated",
      view: true,
      default: true,
    },
  ];
  const value = [
    {
      customernaming: "SVS SCB -1",
      primaryserialnumber: "SCB",
      equipmentcode: "SE00308EQ001",
    },
  ];
  const [selectedOption, setSelectedOption] = useState("Inverter");
  const handleDropdown = async (val) => {
    console.log(val, "val");
    try {
      switch (val) {
        case "Inverter":
          setSelectedOption("Inverter");
          setTableToShow("equipment");
          fetchData();
          break;
        case "Energy Meter":
          await fetchEnergymeterTableData();
          setSelectedOption("Energy Meter");
          setTableToShow("Energy Meter");
          break;
        case "SCB":
          await fetchSCBTableVal();
          setSelectedOption("SCB");
          setTableToShow("SCB");
          break;
        case "Sensor":
          await fetchSensorTableData();
          setSelectedOption("Sensor");
          setTableToShow("Sensor");
          break;
        default:
          fetchData();
          break;
      }
    } catch (error) {
      console.error(error.message);
      setTableToShow(val);
    }
  };

  const [textField, setTextField] = useState([]);
  const [SiteTypeIdFilter, setSiteTypeIdFilter] = useState([]);
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  // console.log(customerId,"customerId");
  useEffect(() => {
    let data = errorConfigField();
    setTextField(data);

    let capcity = tableValues?.invertersCapacity;
    let todayEnergy = tableValues?.todayEnergy;
    let strCapacity = replaceCapacity(capcity);
    let strtodayenergy = replacetodayEnergy(todayEnergy);
    // console.log(strCapacity, 'strtodayenergy');
    setSpecificYeild((strtodayenergy / strCapacity).toFixed(2));
  }, []);

  function replacetodayEnergy(todayEnergy) {
    if (todayEnergy?.includes("MWh")) {
      const numericValue = parseFloat(todayEnergy.replace("MWh", "").trim());
      return numericValue * 1000;
    } else {
      return todayEnergy?.replace("kWh", "");
    }
  }
  function replaceCapacity(capcity) {
    if (capcity?.includes("MWh")) {
      const numericValue = parseFloat(capcity.replace("MW", "").trim());
      return numericValue * 1000;
    } else {
      return capcity?.replace("kW", "");
    }
  }
  const navigate = useNavigate();
  // const redirectEquipment = async (equipId) => {
  //   console.log(equipId, 'redirectEquipments')
  //   try {
  //     const response = await fetchEachEquipHistory(129)
  //     navigate('/menu/EquipmentOverview', { state: { response: response } })
  //     console.log(response);
  //   } catch (error) {
  //     console.error(error);
  //   };
  // }
  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchStatus();
    fetchData();
    // fetchSiteEquips();
  }, []);

  /*-------------------------Table get call --------------------------------------------*/

  // const [searchParams] = useSearchParams();
  let location = useLocation();
  let tempSite = location.search;
  let rm_QM = removeQuestionMark(tempSite);
  let decryptedParams = decryptPaddedQuery(rm_QM);
  let queryParam = new URLSearchParams(`?${decryptedParams}`);

  const params = {};
  for (const [key, value] of queryParam.entries()) {
    params[key] = value;
  }
  let siteId;
  if (Object.keys(params).length > 0) {
    siteId = params.id;
  }
  // const [siteEquips, setSiteEquips] = useState([]);
  console.log(siteId, "let siteId = params.key");
  // const fetchSiteEquips = async () => {
  //   try {
  //     setloading(true);
  //     const data = await fetchEventTable(siteId);
  //     if (data)
  //       if (Array.isArray(data)) {
  //         setSiteEquips(data);
  //         setloading(false);
  //       } else if (typeof data === 'object' && data !== null) {
  //         setSiteEquips([data]);
  //         setloading(false);
  //       }
  //   } catch (e) {
  //     console.error(e);
  //     setloading(false);
  //   }
  // };
  // console.log(siteEquips, 'siteEquips');
  const fetchData = async () => {
    try {
      setloading(true);
      const result = await fetchEquipmentlistTable(
        `${siteId}/${params.irradiation}`
      );
      const data = result.map((item) => {
        return { ...item, activePower: item.activePower / 1000 };
      });
      if (Array.isArray(data)) {
        setEquipmentTable(data);
        setloading(false);
      } else if (typeof data === "object" && data !== null) {
        setEquipmentTable([data]);
        setloading(false);
      }
      console.log(data);
    } catch (e) {
      console.error(e);
      setloading(false);
    }
  };

  /*--------------EnergyMeterTable API----------------*/
  const fetchEnergymeterTableData = async () => {
    try {
      setloading(true);
      const result = await EnergymeterTables(siteId);
      const data = result.map((item) => {
        return { ...item, activePower: item.activePower / 1000 };
      });
      setEquipmentTable(data);
      setloading(false);
      console.log(data, "energymeter table test");
    } catch (error) {
      console.error("Error fetching EnergyMeter table data:", error);
      setloading(false);
    }
  };
  /*-------------------- SCB Table ----------------*/
  const fetchSCBTableVal = async () => {
    try {
      setloading(true);
      // const response = await ScbTables(siteId);
       const response = await ScbTables(siteId);
      setEquipmentTable(response);
      setloading(false);
    } catch (error) {
      console.error(error);
      setloading(false);
    }
  };

  /*--------------SensorTable API----------------*/
  const fetchSensorTableData = async () => {
    setloading(true);
    try {
      const result = await SensorTables(siteId);
      const data = result.map((item) => {
        return {
          ...item,
          moduleTemperature: Math.round(item.moduleTemperature).toFixed(2),
        };
      });
      setEquipmentTable(data);
      console.log(data, "sensor table test");
      setloading(false);
    } catch (error) {
      console.error("Error fetching Sensor table data:", error);
      setloading(false);
    }
  };

  const fetchStatus = async () => {
    setloading(true);
    try {
      const data = await Statusdropdown();
      if (Array.isArray(data)) {
        setStatusDrop(data);
        setloading(false);
      } else if (typeof data === "object" && data !== null) {
        setStatusDrop([data]);
        setloading(false);
      }
    } catch (error) {
      console.error("Error fetching status dropdown:", error);
      setloading(false);
    }
  };

  const handleFileUpload = (e) => {
    setOpen(true);
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setModalvalue(false);
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        setSelectedFileData(jsonData);
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleactiveFlag = (data, event) => {
    setactiveFlag(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      status: data.target.innerHTML,
    }));
  };

  /*---------------------------------- New Error Call -----------------------*/
  const handleCrate = () => {
    setOpen(true);
    seteditcall("");
  };
  const handleClose = () => {
    setSelectedFileData(null);
    setstdField({
      description: "",
      createdBy: "",
      approvedBy: "",
      messageType: "",
      errorMessage: "",
      errorType: "",
      priority: "",
      lastUpdatedBy: "",
      equipmentTypeId: "",
      categoryId: "",
    });
    seteditcall("");
    setCategory("");
    setactiveFlag("");
    setModalvalue(true);
    setEdit(false);
    setOpen(false);
  };
  // const groupedByEquipmentId = siteEquips[0]?.eventList?.reduce((acc, curr) => {
  //   const equipmentId = curr?.equipmentId;
  //   if (!acc[equipmentId]) {
  //     acc[equipmentId] = [];
  //   }
  //   acc[equipmentId].push(curr);
  //   return acc;
  // }, {});
  // console.log(groupedByEquipmentId, 'groupedByEquipmentId');
  const handleEquipment = async (data) => {
    let passData = { id: data.equipmentId, name: data.displayName };
    console.log(data, "redirectEquipments");
    let passParam = new URLSearchParams(passData).toString();
    let encryptedParams = queryEncrypt(passParam);
    let url = `/menu/EquipmentOverview?${encryptedParams}`;
    // let passedData = [];
    // if (groupedByEquipmentId) {
    //   const equipmentIds = Object?.keys(groupedByEquipmentId);
    //   const randomIndex = Math.floor(Math.random() * equipmentIds.length);
    //   const randomEquipmentId = equipmentIds[randomIndex];
    //   passedData = groupedByEquipmentId[randomEquipmentId];
    //   console.log(passedData, 'siteEquips?.eventList');
    // }
    navigate(
      url
      // , { state: { siteEquip: passedData } }
    );
    // navigate('/menu/EquipmentOverview', { state: { siteEquip: passedData } })
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "80%",
    height: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 5,
  };
  const styleTable = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 5,
  };

  const status = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ];

  const handleDownloadTable = async (val) => {
    const status = await Download(val, selectedOption + " Details");
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };

  const tableValues = equipmentTable
    ?.map((row) => {
      let newRow = { ...row };
      newRow.energyLastUpdate = row.energyLastUpdate
        ? getFormatDate(row.energyLastUpdate)
        : null;
      newRow.lastUpdated = row.lastUpdated
        ? getFormatDate(row.lastUpdated)
        : null;
      return newRow;
    })
    .filter((row) => {
      if (siteTab !== "") {
        return Number(row.status) === Number(siteTab);
      } else {
        return row;
      }
    })
    .reverse();

  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case "all":
        setSiteTab("");
        break;
      case "active":
        setSiteTab(1);
        break;
      case "Inactive":
        setSiteTab(0);
        break;
      default:
        setSiteTab("");
    }
  };

  useEffect(() => {
    const SiteTypeIdFilter = categoryvalue?.filter((data) => {
      return data.equipmentCategory === category;
    });
    setSiteTypeIdFilter(SiteTypeIdFilter);
  }, [category, categoryvalue, stdField.status]);

  const filteredCatOption = categoryvalue.map(
    (option) => option.equipmentCategory
  );

  const filteredTypeOptions = equipmetvalue
    .filter((option) => SiteTypeIdFilter[0]?.categoryId === option.categoryId)
    .map((option) => option.equipmentType);

  let Table;
  let headVal;
  if (selectedOption === "Energy Meter") {
    console.log("energy table");
    headVal = headcellsEnergyMeter;
  } else if (selectedOption === "SCB") {
    headVal = headCellSCB;
  } else if (selectedOption === "Sensor") {
    headVal = headcellsSensor;
  } else {
    headVal = headcells;
  }
  console.log(tableValues, "tableValues");

  function extractAndConvert(data, key) {
    console.log(data);
    
    let result = {};

    data.forEach((obj) => {
      if (obj.equipmentId !== null && typeof obj.equipmentId !== 'undefined') {
        result[obj.equipmentId] = obj[key] || {};
      }
    });

    return result;
  }

  function fetchDataAndUpdate(obj, setResult) {
    console.log(obj);
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const data = obj[key];
        const isDown = Object.values(data).some((value) => value === 0);
        data.down = isDown;
        setResult((prevState) => ({
          ...prevState,
          [key]: data,
        }));
      }
    }
  }

  function processTableValues(tableValues, setResult) {
    console.log(tableValues);
    
    const data = extractAndConvert(tableValues, 'scbStringValue');
    fetchDataAndUpdate(data, setResult);
  }

  // Example of usage with React state
  const [apiResults, setApiResults] = useState({});
  console.log(apiResults);
  // useEffect(() => {
  // ;
  //   processTableValues(tableValues, setApiResults);
  // }, [tableValues]);

  const paths = [
    { label: "Home", path: "/menu/sitelist" },
    { label: "Site List", path: -1 },
    { label: "Equipment Details", path: "equipmentdetails" },
  ];
  
  Table = (
    <TableTemplate
      key={selectedOption}
      PageName={"Equipment Detail"}
      SearchLabel={"Search Equipment Here... "}
      header={headVal}
      rowsValue={tableValues}
      userRole={errorConfiguration[0]}
      handleChange={handleChangeTab}
      handleDownloadExcel={(val) => {
        handleDownloadTable(val);
      }}
      handleCategory={(val) => handleDropdown(val)}
      handleEquipmentPopupOpen={(row) => handleEquipment(row)}
      CategoryValue={selectedOption}
      tableToShow={tableToShow}
      tableValues={tableValues}
      scbAPIResult={tableValues}
      paths={paths}
      rawData={tableValues}
      // replaceRedirect={redirectEquipment}
    />
  );

  console.log(equipmentTable, "equipmentTable");
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          {/* ---------------pass props value----------- */}
          <div style={{ marginTop: "-0.5%" }}>
            <CustomSnackbar
              open={snack.open}
              onClose={handleSnackClose}
              autoHideDuration={5000}
              severity={snack.severity}
              message={snack.message}
            />
            {Table}
          </div>
          {/*------------------------- Modal Download Popup ---------------*/}
        </div>
      )}
    </div>
  );
}