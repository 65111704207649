import axios from 'axios';

const isOEM = sessionStorage.getItem('userTypeId') == 1;

/*---------------------- POST currency Save ---------------------*/
export const postCurrencydataSave = async (obj) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_CURRENCY_SAVE}`, obj
      );
  
      return response.data;
    } catch (error) {
      console.error("Error posting data:", error.message);
      throw error;
    }
  };

/*---------------------- GET Table FindAll ----------------------*/

export const fetchCurrencyData = async (Id, year) => {

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_CURRENCY_TABLEGET}?siteId=${Id}&currentYear=${year}`
      );
      return response.data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  };

/*---------------------- PUT currency Update ---------------------*/
export const putCurrencyUpdate = async (data, id) => {
    try {
      console.log(id, "apicall");
      const response = await axios.put(
        `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_CURRENCY_UPDATE}/${id}`,data
      );
      return response.data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error;
    }
  };  
