import axios from "axios";

const isOEM = sessionStorage.getItem("userTypeId") == 1;

/*--------------------- GET Site Name dropdown list -------------------*/

export const Equipmentsitedropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${isOEM
        ? process.env.REACT_APP_SITE_DROP_DOWN_BYCOMPANY
        : process.env.REACT_APP_SITE_DROP_DOWN
      }/${isOEM
        ? sessionStorage.getItem("companyId")
        : sessionStorage.getItem("customerId")
      }`
    );
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

/*---------------------- POST ExpectedData Save ---------------------*/
export const postExpecteddataSave = async (obj) => {
  try {
    const response = await axios.post(
      // `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EXPECTEDDATA_SAVE}`,
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_EXPECTEDDATA_SAVE}`, obj
      // `${process.env.REACT_APP_EXPECTEDDATA_SAVE}`, obj
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error.message);
    throw error;
  }
};

/*---------------------- PUT ExpectedData Update ---------------------*/
export const putExpecteddateUpdate = async (data, id) => {
  try {
    console.log(id, "apicall");
    const response = await axios.put(
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_EXPECTEDDATA_UPDATE}/${id}`,data
      // `${process.env.REACT_APP_EXPECTEDDATA_UPDATE}/${id}`, data
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

/*---------------------- GET Table FindAll ----------------------*/

export const fetchExpectedData = async (Id, year) => {

  try {
    const response = await axios.get(
      // `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EXPECTEDDATA_GET}/${Id}`
      `${process.env.REACT_APP_CHART_API_PATH}${process.env.REACT_APP_EXPECTEDDATA_GET}?siteId=${Id}&currentYear=${year}`
      // `${process.env.REACT_APP_EXPECTEDDATA_GET}?siteId=${Id}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

// export const fetchExpectedData = async (Id) => {

//   try {
//     const response = await axios.get(
//       // `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EXPECTEDDATA_GET}/${Id}`
//       `${process.env.REACT_APP_API_EXPEXTEDDATA}${process.env.REACT_APP_EXPECTEDDATA_GET}/${Id}`
//     );
//     return response.data;
//   } catch (error) {
//     console.error('Error posting data:', error);
//     throw error;
//   }
// };

export const postExpectedexceldata = async (obj) => {
  try {
    const resolvedData = await Promise.all(obj);
    console.log(resolvedData, "ghj");
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EXPECTEDDATA_EXCEL_TABLE}`,
      resolvedData
    );
    return response;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};
