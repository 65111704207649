import React from "react";
import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";

const ExcelDataModal = ({
  open,
  handleClose,
  handleSave,
  siteId,
  siteName,
  data,
  equipmentvalue,
  fieldSets,
  Param,
}) => {
  // Function to find equipment ID based on display name
  const findEquipmentId = (equipmentName) => {
    const equipment = equipmentvalue?.filter(
      (item) => item.displayName === equipmentName
    );
    return equipment ? equipment.equipmentId : null;
  };

  const prepareSubmitPayload = () => {
    const payload = fieldSets?.map((row) => ({
      siteId: siteId,
      timestamp: new Date(row.date).toISOString() || null,
      equipmentId: row.equipmentId,
      todayEnergy: row.todayEnergy || null,
      activePower: row.activePower || null,
      exportTotalEnergy: row.exportTotalEnergy || null,
      importTotalEnergy: row.importTotalEnergy || null,
      irradiation: row.irradiation,
    }));
    console.log(fieldSets, "fieldswet");
    console.log(payload, "payload");

    return payload;
  };

  // Modified handleSave function
  const onSave = () => {
    const payload = prepareSubmitPayload();
    console.log(payload, "payload");

    handleSave(payload);
    handleClose(); // Close the modal after saving
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="excel-data-modal"
      aria-describedby="modal-to-display-excel-data"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          maxHeight: "80vh",
          overflowY: "auto",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              style={{
                borderRadius: "25px",
                textTransform: "capitalize",
                margin: "0 20px 0 0",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={onSave}
              variant="contained"
              style={{
                borderRadius: "25px",
                textTransform: "capitalize",
                margin: "0 20px 0 0",
              }}
            >
              Submit
            </Button>
          </Box>
        </div>

        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          gutterBottom
        >
          {`Uploaded Excel Data for ${Param}`}
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="excel data table">
            <TableHead>
              <TableRow>
                <TableCell>Site Name</TableCell>
                <TableCell style={{ width: "100px" }}>Date</TableCell>
                {data.length > 0 &&
                  Object.keys(data[0])
                    .filter(
                      (key) =>
                        key !== "Date" &&
                        key !== "equipmentId" &&
                        key !== "SiteId"
                    )
                    .map((key) => <TableCell key={key}>{key}</TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{siteName}</TableCell>
                  <TableCell>{row.Date}</TableCell>
                  {Object.keys(row)
                    .filter((key) => key !== "Date" && key !== "equipmentId")
                    .map((key) => (
                      <TableCell key={key}>{row[key]}</TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default ExcelDataModal;
