import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Box,
  IconButton,
  Divider,
  Stack,
  Typography,
  Badge,
  Menu,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import Apexchart from "../../Components/AnalyticsDashboard/ApexexChart";
import "../Style.css";
import {
  ActualVsExpected,
  fetchDailyGenerationEira,
  fetchSpecificYieldEira,
  fetchPvDgGridEira,
  GetSpecificYieldVsIrradiationEqp,
  DeemedGenrator,
  fetchParameterComparisonEira,
  GetPRData,
} from "../../../Api/DataAnalystApi";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  CheckForLight,
  roundedUp,
  sortedDataByMonth,
  transformDataForSpecificYield,
} from "../../util/common/CommanFunction";
import { FadeLoader } from "react-spinners";
import CombinedChart from "../../Charts/CombainedChart";
import {
  fetchEquipDropdownBySite,
  fetchParamsDropdownByLogger,
  fetchSiteDropdownByUser,
} from "../../../Api/CommonApi";
import BarChart from "../../Charts/BarChart";
import AreaChart from "../../Charts/AreaChart";
import { removeAfterTime } from "../../util/common/CommanFunction";
import { ca, da } from "date-fns/locale";

const timer = [
  { label: "5", value: "5" },
  { label: "10", value: "10" },
  { label: "15", value: "15" },
  { label: "30", value: "30" },
  { label: "45", value: "45" },
  { label: "60", value: "60" },
];

const optionTime = [
  { label: "Daily", value: "Daily" },
  { label: "Last 7 days", value: "Last 7 days" },
  { label: "Weekly", value: "Weekly" },
  { label: "This Month", value: "This Month" },
  { label: "Monthly", value: "Monthly" }, // label: 'Last Month', value: 'Last Month' },
  { label: "Yearly", value: "Yearly" },
];

export const ContactAnalytics = ({ name, siteName, siteDetails, refresh }) => {
  const [site, setSite] = useState(siteName);
  const [siteIdName, setSiteId] = useState([]);
  const [customView, setCustomView] = useState();
  const [multiChartData, setMultichartData] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartName, setChartName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [timerOptions, setTimerOptions] = useState(timer);
  const [timerValue, setTimerValue] = useState(5);
  const [anchorTimerEl, setAnchorTimerEl] = useState(null);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [range, setRange] = useState("Last 7 days");
  const [menuOpen, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setSite(siteName);
    setChartName("Daily Generation");
    setSelectedOption("Daily Generation");
    getSiteCall();

    fetchApplyChange(siteDetails);
  }, [siteName]);

  useEffect(() => {
    if (refresh) {
      fetchApplyChange(siteDetails);
    }
  }, [refresh]);

  const handleClickMenuItem = (event) => {
    setAnchorTimerEl(event.currentTarget);
  };
  const handleCloseTimer = () => {
    setAnchorTimerEl(null);
  };
  const handleMenuItemClick = async (value) => {
    setTimerValue(value);
    ApplyChange({ time: value });
  };

  const handleClickMenu = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleMenuItem = (option) => {
    setTimerValue(5);
    let startDate, endDate;

    switch (option) {
      case "Daily":
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Last 7 days":
        startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Weekly":
        startDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
        endDate = moment().endOf("isoWeek").format("YYYY-MM-DD");
        break;
      case "This Month":
        startDate = moment().startOf("month").format("YYYY-MM-DD");
        endDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Monthly":
        startDate = moment().startOf("year").format("YYYY-MM-DD");
        endDate = moment().startOf("today").format("YYYY-MM-DD");
        break;
      case "Yearly":
        startDate = moment()
          .startOf("year")
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        endDate = moment().endOf("today").format("YYYY-MM-DD");
        break;
      case "custom":
        // For custom range, you might set these dates through a date picker or user input
        break;
      default:
        startDate = null;
        endDate = null;
    }

    setFromDate(startDate);
    setToDate(endDate);
    setRange(option);
    handleCloseMenu();
    ApplyChange({ fromDate: startDate, toDate: endDate, range: option });
  };

  const fetchApplyChange = async (siteDetails) => {
    setRange("Daily");
    let date = new Date();
    let today = moment(date).format("YYYY-MM-DD");
    setToDate(today);
    setFromDate(today);
    let meter = [];
    const equipDetails = await fetchEquipDropdownBySite(siteDetails.siteId);
    let filteredObjects = equipDetails?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    const dataFrequency = siteDetails.siteFrequency ?? 5;
    meter = equipmentIds;
    const dataModel = {
      fromDate: today,
      graphType: "Daily Generation",
      siteId: siteDetails.siteId,
      toDate: today,
      range: "daily",
      equipmentIds: meter,
      timeperiod: "Today",
      intervalMins: dataFrequency,
      energyGenBasedOn: siteDetails.energyGenBasedOn,
      energyFlag: 0,
    };
    console.log(dataModel, "dataModel");
    setCustomView(dataModel);
    setLoading(true);
    console.log("CustomView", dataModel);
    let responseData = await fetchDailyGenerationEira(dataModel);

    setLoading(false);
    setTableData(responseData);
    if (dataModel?.range === "daily") {
      let seriesData = [];
      const irradiationSeries = {
        name: "irradiation",
        data: [],
      };
      const todayEnergySeries = {
        name: "todayenergy",
        data: [],
      };
      responseData.forEach((dataPoint) => {
        const timeStamp = new Date(dataPoint.timeStamp).getTime();
        irradiationSeries.data.push([timeStamp, dataPoint.irradiation]);
        todayEnergySeries.data.push([timeStamp, dataPoint.todayEnergy]);
      });
      seriesData.push(irradiationSeries);
      seriesData.push(todayEnergySeries);
      setMultichartData(seriesData);
    }
  };

  const ApplyChange = async (value) => {
    const siteName = value.siteName ? value.siteName : site;

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteName;
    });

    let meter = [];
    const equipm = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);

    let filteredObjects = equipm?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    meter = equipmentIds;
    const rangeValue = value.range ? value.range : range;
    const dataFrequency = value.time ? value.time : timerValue;
    const data = {
      fromDate: value.fromDate ? value.fromDate : fromDate,
      graphType: value.chartName ? value.chartName : chartName,
      siteId: siteIdFilter[0].siteId,
      toDate: value.toDate ? value.toDate : toDate,
      range:
        rangeValue == "Yearly"
          ? "yearlyCum"
          : rangeValue == "Monthly"
          ? "yearly"
          : rangeValue == "Daily"
          ? "daily"
          : "custom",
      equipmentIds: meter,
      intervalMins: dataFrequency,
      timeperiod: "Today",
      energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
      energyFlag: 0,
    };
    console.log("data", data, value);

    setCustomView(data);
    setLoading(true);
    const responseData = await fetchDailyGenerationEira(data);
    setLoading(false);
    setTableData(responseData);
    let seriesData = [];
    const irradiationSeries = {
      name: "irradiation",
      data: [],
    };
    const todayEnergySeries = {
      name: "todayenergy",
      data: [],
    };
    responseData.forEach((dataPoint) => {
      const timeStamp = new Date(dataPoint.timeStamp).getTime();
      irradiationSeries.data.push([timeStamp, dataPoint.irradiation]);
      todayEnergySeries.data.push([timeStamp, dataPoint.todayEnergy]);
    });
    seriesData.push(irradiationSeries);
    seriesData.push(todayEnergySeries);
    setMultichartData(seriesData);
    setTableData(responseData);
    handleCloseTimer();
    handleCloseMenu();
  };

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      const equipList = await fetchEquipDropdownBySite(data[0]?.siteId);
      setSiteId([...new Set(data)]);
      setEquipments(equipList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeDate = (direction) => {
    let newToDate, newFromDate;
    const currentToDate = moment(toDate);
    const currentFromDate = moment(fromDate);
    if (
      currentFromDate.isSame(moment().startOf("day")) &&
      direction === "right"
    ) {
      return;
    }
    const dateDifference = range === "Daily" ? 1 : 7;
    // Calculate the date range difference
    if (range.toLocaleLowerCase().includes("month")) {
      if (direction === "right") {
        newFromDate = currentFromDate.clone().add(1, "month").startOf("month");
        newToDate = currentToDate.clone().add(1, "month").endOf("month");
      } else if (direction === "left") {
        newFromDate = currentFromDate
          .clone()
          .subtract(1, "month")
          .startOf("month");
        newToDate = currentToDate.clone().subtract(1, "month").endOf("month");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    } else if (range.toLocaleLowerCase().includes("year")) {
      if (direction === "right") {
        newFromDate = currentFromDate.clone().add(1, "year").startOf("year");
        newToDate = currentToDate.clone().add(1, "year").endOf("year");
      } else if (direction === "left") {
        newFromDate = currentFromDate
          .clone()
          .subtract(1, "year")
          .startOf("year");
        newToDate = currentToDate.clone().subtract(1, "year").endOf("year");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    } else {
      const dateDiff = dateDifference;

      if (direction === "left") {
        newToDate = currentToDate.subtract(dateDiff, "days");
        newFromDate = currentFromDate.subtract(dateDiff, "days");
      } else if (direction === "right") {
        newToDate = currentToDate.add(dateDiff, "days");
        newFromDate = currentFromDate.add(dateDiff, "days");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    }

    // const today = moment().startOf("day");

    // if (newToDate.isAfter(today)) {
    //   // If the new "to" date is in the future, reset it to today
    //   newToDate = today;
    // }

    const formattedToDate = newToDate.format("YYYY-MM-DD");
    const formattedFromDate = newFromDate.format("YYYY-MM-DD");

    setToDate(formattedToDate);
    setFromDate(formattedFromDate);
    ApplyChange({
      toDate: formattedToDate,
      fromDate: formattedFromDate,
    });
  };

  const NodataAvailable = false;

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  return NodataAvailable ? (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Site has been configured yet
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Card
      sx={{ width: "100%", height: "100%", paddingBottom: 0 }}
      elevation={0}
      key={name}
    >
      <CardContent sx={{ height: "100%", paddingBottom: "0 !important" }}>
        <Grid>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "0.95rem" }}>
                Daily Generation
              </Typography>
            </div>
            <div>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                width="18vw"
                justifyContent="space-between"
                p="0"
                style={{
                  border: CheckForLight()
                    ? "1px solid #c4c4c4"
                    : "1px solid #494949",
                }}
              >
                <IconButton onClick={() => handleChangeDate("left")}>
                  <ChevronLeftIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  style={{
                    cursor: "pointer",
                    flex: "1",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    mt="1px"
                    ml="2.5px"
                    style={{
                      color: CheckForLight() ? "black" : "white",
                      fontSize: "0.95rem",
                    }}
                  >
                    {range === "Daily"
                      ? moment(toDate).format("DD/MM/YYYY")
                      : range === "Monthly" || range === "Yearly"
                      ? moment(toDate).format("YYYY")
                      : ` ${moment(fromDate).format("DD/MM")}-${moment(
                          toDate
                        ).format("DD/MM")}`}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  disabled={moment(toDate).isSame(moment().startOf("day"))}
                  onClick={() => handleChangeDate("right")}
                >
                  <ChevronRightIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  onClick={(event) => handleClickMenuItem(event)}
                  disabled={chartName !== "Daily Generation"}
                  sx={{
                    width: "40px",
                  }}
                >
                  <Badge
                    badgeContent={timerValue}
                    color="default"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <TimerOutlinedIcon
                      sx={{
                        color: CheckForLight() ? "black" : "white",
                        opacity: chartName !== "Daily Generation" ? 0.5 : 1,
                        pointerEvents:
                          chartName !== "Daily Generation" ? "none" : "auto",
                        fontSize: "20px",
                      }}
                    />
                  </Badge>
                </IconButton>
                <Menu
                  anchorEl={anchorTimerEl}
                  open={Boolean(anchorTimerEl)}
                  onClose={handleCloseTimer}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {timerOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={() => handleMenuItemClick(option.value, "Timer")}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
                <Divider orientation="vertical" flexItem />
                <IconButton onClick={(event) => handleClickMenu(event, "Time")}>
                  <KeyboardArrowDownIcon
                    sx={{
                      color: CheckForLight() ? "black" : "white",
                    }}
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {optionTime.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={(event) => handleMenuItem(option.value, "Time")}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          </div>
        </Grid>
        {loading ? (
          <Card
            sx={{
              width: "100%",
              height: "43vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "43vh",
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </CardContent>
          </Card>
        ) : tableData.length === 0 || multiChartData.length === 0 ? (
          fallBack
        ) : range == "Daily" ? (
          <Apexchart
            chartName={"Daily Generation"}
            tableData={tableData}
            multiChartData={multiChartData}
            key="1"
            name="chartData"
            widgetName={` `}
            customName={`${siteName}-${chartName}`}
            isDashboard={true}
          />
        ) : (
          <BarChart
            tableData={tableData}
            chartName={chartName}
            customView={customView}
            widgetName={` `}
            customName={`${siteName}-${chartName}`}
            fallBack={fallBack}
            isDashboard={true}
          />
        )}
      </CardContent>
    </Card>
  );
};

export const ContactAnalytics3 = ({ name, siteName, siteDetails, refresh }) => {
  const [site, setSite] = useState(siteName);
  const [siteIdName, setSiteId] = useState([]);
  const [barchartdata, setBarchartData] = useState([]);
  const [siteCall, setSiteCall] = useState(false);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [customView, setCustomView] = useState();
  const [multiChartData, setMultichartData] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartName, setChartName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [range, setRange] = useState("Last 7 days");
  const [menuOpen, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [equpAndParams, setEqupAndParams] = useState([]);
  const [equpParamMap, setEqupParamMapValue] = useState();

  useEffect(() => {
    setSite(siteName);
    setChartName("Specific Yield");
    setSelectedOption("Specific Yield");
    getSiteCall();

    fetchApplyChange(siteDetails);
  }, [siteName]);

  useEffect(() => {
    if (refresh) {
      fetchApplyChange(siteDetails);
    }
  }, [refresh]);

  const handleClickMenuItem = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleMenuItemClick = (option) => {
    let startDate, endDate;

    switch (option) {
      case "Daily":
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Last 7 days":
        startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Weekly":
        startDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
        endDate = moment().endOf("isoWeek").format("YYYY-MM-DD");
        break;
      case "This Month":
        startDate = moment().startOf("month").format("YYYY-MM-DD");
        endDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Monthly":
        startDate = moment().startOf("year").format("YYYY-MM-DD");
        endDate = moment().startOf("today").format("YYYY-MM-DD");
        break;
      case "Yearly":
        startDate = moment()
          .startOf("year")
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        endDate = moment().endOf("today").format("YYYY-MM-DD");
        break;
      case "custom":
        // For custom range, you might set these dates through a date picker or user input
        break;
      default:
        startDate = null;
        endDate = null;
    }

    setFromDate(startDate);
    setToDate(endDate);
    setRange(option);
    handleCloseMenu();
    ApplyChange({ fromDate: startDate, toDate: endDate, range: option });
  };

  function StringCurrentEqupMapTransform(equipmentData, inputEqu) {
    // Find the equipment object that matches the inputEqu
    const matchedEquipment = equipmentData.find((equ) => equ.equ === inputEqu);

    // If a match is found, return the desired object structure
    if (matchedEquipment) {
      return {
        [inputEqu]: matchedEquipment.params,
      };
    } else {
      // Return an empty object if no match is found
      return {};
    }
  }

  async function createEquipmentParamsArray(siteId) {
    const id = siteId;
    try {
      // Fetch the equipment dropdown data for the site
      const equip = await fetchEquipDropdownBySite(id);

      // Create a map to store equipment based on dataLoggerId
      const dataLoggerMap = equip.reduce((map, equipment) => {
        const { dataLoggerId } = equipment;
        if (!map[dataLoggerId]) {
          map[dataLoggerId] = [];
        }
        map[dataLoggerId].push(equipment);
        return map;
      }, {});

      const resultArray = [];

      // Iterate through the dataLoggerMap and fetch params for each dataLoggerId
      for (const [dataLoggerId, equipments] of Object.entries(dataLoggerMap)) {
        // Fetch parameters for the current dataLoggerId
        const params = await fetchParamsDropdownByLogger({
          dataloggerId: [Number(dataLoggerId)],
        });

        // For each equipment, create an object with `equ` and `params` keys
        equipments.forEach((equipment) => {
          resultArray.push({
            equ: equipment.displayName,
            params: params
              .filter((param) => param.stdParameterName !== "Dummy")
              .map((param) => `${param.stdParameterName}(${param.stdUom})`),
            category: equipment.equipmentCategory,
          });
        });
      }

      console.log("resultArray", resultArray);

      // Update the state with the resulting array
      setEqupAndParams(resultArray);
      return resultArray;
    } catch (e) {
      console.error(e);
      setEqupAndParams([]);
    }
  }

  const fetchApplyChange = async (siteDetails) => {
    let date = new Date();
    let today = moment(date).format("YYYY-MM-DD");
    setToDate(today);
    setFromDate(today);
    setRange("Daily");
    let meter = [];
    const equipDetails = await fetchEquipDropdownBySite(siteDetails.siteId);
    await createEquipmentParamsArray(siteDetails.siteId);

    const equipArray = equipDetails
      .sort((a_name, b_name) => {
        const a = a_name.displayName;
        const b = b_name.displayName;
        if (a?.includes("-") && b?.includes("-")) {
          return +a.split("-").pop() - +b.split("-").pop();
        } else if (a?.includes("_") && b?.includes("_")) {
          return +a.split("_").pop() - +b.split("_").pop();
        }
        return a.localeCompare(b);
      })
      .filter((data) => {
        return data.equipmentCategory.toLocaleLowerCase().includes("inverter");
      })
      .map((datav) => {
        return datav.displayName;
      });
    const equipLoggMap = StringCurrentEqupMapTransform(
      equpAndParams,
      equipArray
    );
    console.log({
      equipLoggMap,
      equipArray,
    });

    setEqupParamMapValue(equipLoggMap);

    let filteredObjects = equipDetails?.filter((obj) =>
      equipArray.includes(obj.displayName)
    );

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    const dataFrequency = siteDetails.siteFrequency ?? 5;

    meter = equipmentIds;

    const dataModel = {
      fromDate: today,
      graphType: "Specific Yield",
      siteId: siteDetails.siteId,
      toDate: today,
      parameters: ["ActivePower"],
      range: "custom",
      equipmentIds: meter,
      timeperiod: "Today",
      intervalMins: dataFrequency,
      energyGenBasedOn: siteDetails.energyGenBasedOn,
      energyFlag: 0,
    };
    setCustomView(dataModel);
    setLoading(true);
    try {
      let responseData = [];
      responseData = await fetchParameterComparisonEira(dataModel);
      setChartName("Parameter Comparision");
      setTableData(responseData);
      // console.log(equipmentParameter);

      const dataFields = ["ActivePower"];
      const seriesData = [];
      // console.log(equipments);

      dataFields.forEach(async (field) => {
        responseData.forEach(async (data) => {
          // console.log(field);

          let equipmentName = equipDetails?.filter((dataValue) => {
            return (
              dataValue.equipmentId ===
              Number(data.equipmentId ? data.equipmentId : data.EquipmentId)
            );
          });
          // console.log(equipmentName);

          const timeStampData = data.timeStamp
            ? data.timeStamp
            : data.TimeStamp;

          const timeStamp = new Date(timeStampData).getTime();

          // console.log(timeStamp);

          const value = data[field];
          const seriesName = `${equipmentName[0]?.displayName} - ${field}`;
          // console.log(value);

          const existingSeries = seriesData.find(
            (series) => series.name === seriesName
          );
          if (existingSeries) {
            existingSeries.data.push([timeStamp, value]);
          } else {
            const newSeries = {
              name: seriesName,
              data: [[timeStamp, value]],
            };
            seriesData.push(newSeries);
            // console.log(seriesData);

            let filteredArray = seriesData.filter((obj) => {
              return !obj.data.every(([_, value]) => isNaN(value));
            });

            setMultichartData(filteredArray);
            return filteredArray;
          }
        });
      });
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const ApplyChange = async (value) => {
    const name = value.chartName ? value.chartName : chartName;
    const siteName = value.siteName ? value.siteName : site;
    console.log(site);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteName;
    });
    console.log(siteIdFilter);

    let meter = [];
    const equipDetails = await fetchEquipDropdownBySite(siteDetails.siteId);
    await createEquipmentParamsArray(siteDetails.siteId);

    const equipArray = equipDetails
      .sort((a_name, b_name) => {
        const a = a_name.displayName;
        const b = b_name.displayName;
        if (a?.includes("-") && b?.includes("-")) {
          return +a.split("-").pop() - +b.split("-").pop();
        } else if (a?.includes("_") && b?.includes("_")) {
          return +a.split("_").pop() - +b.split("_").pop();
        }
        return a.localeCompare(b);
      })
      .filter((data) => {
        return data.equipmentCategory.toLocaleLowerCase().includes("inverter");
      })
      .map((datav) => {
        return datav.displayName;
      });
    const equipLoggMap = StringCurrentEqupMapTransform(
      equpAndParams,
      equipArray
    );
    console.log({
      equipLoggMap,
      equipArray,
    });

    setEqupParamMapValue(equipLoggMap);

    let filteredObjects = equipDetails?.filter((obj) =>
      equipArray.includes(obj.displayName)
    );

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    meter = equipmentIds;

    const rangeValue = value.range ? value.range : range;

    const dataFrequency = siteIdName[0].siteFrequency ?? 5;

    const data = {
      fromDate: value.fromDate ? value.fromDate : fromDate,
      graphType: value.chartName ? value.chartName : chartName,
      siteId: siteIdFilter[0].siteId,
      toDate: value.toDate ? value.toDate : toDate,
      parameters: ["ActivePower"],
      range:
        rangeValue == "Yearly"
          ? "yearlyCum"
          : rangeValue == "Monthly"
          ? "yearly"
          : rangeValue == "Daily"
          ? "custom"
          : "custom",
      equipmentIds: meter,
      intervalMins: dataFrequency,
      timeperiod: "Today",
      energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
      energyFlag: 0,
    };
    setCustomView(data);
    setLoading(true);
    try {
      let responseData = [];
      responseData = await fetchParameterComparisonEira(data);
      setChartName("Parameter Comparision");
      setTableData(responseData);
      // console.log(equipmentParameter);

      const dataFields = ["ActivePower"];
      const seriesData = [];
      // console.log(equipments);

      dataFields.forEach(async (field) => {
        responseData.forEach(async (data) => {
          // console.log(field);

          let equipmentName = equipDetails?.filter((dataValue) => {
            return (
              dataValue.equipmentId ===
              Number(data.equipmentId ? data.equipmentId : data.EquipmentId)
            );
          });
          // console.log(equipmentName);

          const timeStampData = data.timeStamp
            ? data.timeStamp
            : data.TimeStamp;

          const timeStamp = new Date(timeStampData).getTime();

          // console.log(timeStamp);

          const value = data[field];
          const seriesName = `${equipmentName[0]?.displayName} - ${field}`;
          // console.log(value);

          const existingSeries = seriesData.find(
            (series) => series.name === seriesName
          );
          if (existingSeries) {
            existingSeries.data.push([timeStamp, value]);
          } else {
            const newSeries = {
              name: seriesName,
              data: [[timeStamp, value]],
            };
            seriesData.push(newSeries);
            // console.log(seriesData);

            let filteredArray = seriesData.filter((obj) => {
              return !obj.data.every(([_, value]) => isNaN(value));
            });

            setMultichartData(filteredArray);
            return filteredArray;
          }
        });
      });
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      const equipList = await fetchEquipDropdownBySite(data[0]?.siteId);
      setSiteId([...new Set(data)]);
      setEquipments(equipList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeDate = (direction) => {
    let newToDate, newFromDate;
    const currentToDate = moment(toDate);
    const currentFromDate = moment(fromDate);
    if (
      currentFromDate.isSame(moment().startOf("day")) &&
      direction === "right"
    ) {
      return;
    }
    const dateDifference = range === "Daily" ? 1 : 7;
    // Calculate the date range difference
    if (range.toLocaleLowerCase().includes("month")) {
      if (direction === "right") {
        newFromDate = currentFromDate.clone().add(1, "month").startOf("month");
        newToDate = currentToDate.clone().add(1, "month").endOf("month");
      } else if (direction === "left") {
        newFromDate = currentFromDate
          .clone()
          .subtract(1, "month")
          .startOf("month");
        newToDate = currentToDate.clone().subtract(1, "month").endOf("month");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    } else if (range.toLocaleLowerCase().includes("year")) {
      if (direction === "right") {
        newFromDate = currentFromDate.clone().add(1, "year").startOf("year");
        newToDate = currentToDate.clone().add(1, "year").endOf("year");
      } else if (direction === "left") {
        newFromDate = currentFromDate
          .clone()
          .subtract(1, "year")
          .startOf("year");
        newToDate = currentToDate.clone().subtract(1, "year").endOf("year");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    } else {
      const dateDiff = dateDifference;

      if (direction === "left") {
        newToDate = currentToDate.subtract(dateDiff, "days");
        newFromDate = currentFromDate.subtract(dateDiff, "days");
      } else if (direction === "right") {
        newToDate = currentToDate.add(dateDiff, "days");
        newFromDate = currentFromDate.add(dateDiff, "days");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    }

    // const today = moment().startOf("day");

    // if (newToDate.isAfter(today)) {
    //   // If the new "to" date is in the future, reset it to today
    //   newToDate = today;
    // }

    const formattedToDate = newToDate.format("YYYY-MM-DD");
    const formattedFromDate = newFromDate.format("YYYY-MM-DD");

    setToDate(formattedToDate);
    setFromDate(formattedFromDate);
    ApplyChange({
      toDate: formattedToDate,
      fromDate: formattedFromDate,
    });
  };

  const NodataAvailable = false;

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  return NodataAvailable ? (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Site has been configured yet
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Card
      sx={{ width: "100%", height: "100%", paddingBottom: 0 }}
      elevation={0}
      key={name}
    >
      <CardContent sx={{ height: "100%", paddingBottom: "0 !important" }}>
        <Grid>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "0.95rem", fontWeight: 400 }}>
                Active Power
              </Typography>
            </div>
            <div>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                width="16vw"
                justifyContent="space-between"
                p="0"
                style={{
                  border: CheckForLight()
                    ? "1px solid #c4c4c4"
                    : "1px solid #494949",
                }}
              >
                <IconButton onClick={() => handleChangeDate("left")}>
                  <ChevronLeftIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  style={{
                    cursor: "pointer",
                    flex: "1",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    mt="1px"
                    ml="2.5px"
                    style={{
                      color: CheckForLight() ? "black" : "white",
                      fontSize: "0.95rem",
                    }}
                  >
                    {range === "Daily"
                      ? moment(toDate).format("DD/MM/YYYY")
                      : range === "Monthly" || range === "Yearly"
                      ? moment(toDate).format("YYYY")
                      : ` ${moment(fromDate).format("DD/MM")}-${moment(
                          toDate
                        ).format("DD/MM")}`}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  disabled={moment(toDate).isSame(moment().startOf("day"))}
                  onClick={() => handleChangeDate("right")}
                >
                  <ChevronRightIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  onClick={(event) => handleClickMenuItem(event, "Time")}
                >
                  <KeyboardArrowDownIcon
                    sx={{
                      color: CheckForLight() ? "black" : "white",
                    }}
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {optionTime
                    .filter((option) => option.value !== "Yearly")
                    .map((option) => (
                      <MenuItem
                        key={option.value}
                        onClick={(event) =>
                          handleMenuItemClick(option.value, "Time")
                        }
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                </Menu>
              </Box>
            </div>
          </div>
        </Grid>
        {loading ? (
          <Card
            sx={{
              width: "100%",
              height: "43vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "43vh",
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </CardContent>
          </Card>
        ) : tableData.length === 0 ? (
          fallBack
        ) : (
          <Apexchart
            chartName={chartName}
            tableData={tableData}
            timeValue={range}
            multiChartData={multiChartData}
            key="1"
            name="chartData"
            widgetName={``}
            fallBack={fallBack}
            isDashboard={true}
          />
        )}
      </CardContent>
    </Card>
  );
};

export const ContactAnalytics4 = ({ name, siteName, siteDetails, refresh }) => {
  const [site, setSite] = useState(siteName);
  const [siteIdName, setSiteId] = useState([]);
  const [barchartdata, setBarchartData] = useState([]);
  const [siteCall, setSiteCall] = useState(false);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [customView, setCustomView] = useState();
  const [multiChartData, setMultichartData] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartName, setChartName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [range, setRange] = useState("Last 7 days");
  const [menuOpen, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setSite(siteName);
    setChartName("Actual Vs Expected");
    setSelectedOption("Actual Vs Expected");
    getSiteCall();

    fetchApplyChange(siteDetails);
  }, [siteName]);

  useEffect(() => {
    if (refresh) {
      fetchApplyChange(siteDetails);
    }
  }, [refresh]);

  const handleClickMenuItem = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleMenuItemClick = (option) => {
    let startDate, endDate, range;

    switch (option) {
      case "Daily":
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Last 7 days":
        startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Weekly":
        startDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
        endDate = moment().endOf("isoWeek").format("YYYY-MM-DD");
        break;
      case "This Month":
        startDate = moment().startOf("month").format("YYYY-MM-DD");
        endDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Monthly":
        startDate = moment().startOf("year").format("YYYY-MM-DD");
        endDate = moment().startOf("today").format("YYYY-MM-DD");
        break;
      case "Yearly":
        startDate = moment()
          .startOf("year")
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        endDate = moment().endOf("today").format("YYYY-MM-DD");
        break;
      case "custom":
        // For custom range, you might set these dates through a date picker or user input
        break;
      default:
        startDate = null;
        endDate = null;
    }

    setFromDate(startDate);
    setToDate(endDate);
    setRange(option);
    handleCloseMenu();
    ApplyChange({ fromDate: startDate, toDate: endDate, range: option });
  };

  const fetchApplyChange = async (siteDetails) => {
    let date = new Date();
    let today = moment(date).format("YYYY-MM-DD");
    let weeklyDate = moment(date).subtract(7, "days").format("YYYY-MM-DD");
    setToDate(today);
    setFromDate(weeklyDate);
    setRange("Last 7 days");
    let meter = [];
    const equipDetails = await fetchEquipDropdownBySite(siteDetails.siteId);
    let filteredObjects = equipDetails?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    const dataFrequency = siteDetails.siteFrequency ?? 5;
    meter = equipmentIds;
    const dataModel = {
      fromDate: weeklyDate,
      graphType: "Daily Generation",
      siteId: siteDetails.siteId,
      toDate: today,
      range: "custom",
      equipmentIds: meter,
      timeperiod: "Today",
      intervalMins: dataFrequency,
      energyGenBasedOn: siteDetails.energyGenBasedOn,
      energyFlag: 0,
    };
    const EquipmentForSpecificYield =
      transformDataForSpecificYield(equipDetails);
    dataModel.equipmentIdAndCapacity = EquipmentForSpecificYield;
    console.log(dataModel, "dataModel");
    setCustomView(dataModel);
    setLoading(true);
    console.log("CustomView", dataModel);
    let responseData = await ActualVsExpected(dataModel);

    setLoading(false);
    setTableData(responseData);
  };

  const ApplyChange = async (value) => {
    const name = value.chartName ? value.chartName : chartName;
    const siteName = value.siteName ? value.siteName : site;
    console.log(site);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteName;
    });
    console.log(siteIdFilter);

    let meter = [];
    const equipm = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);

    let filteredObjects = equipm?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    meter = equipmentIds;
    const rangeValue = value.range ? value.range : range;
    const dataFrequency = siteIdName[0].siteFrequency ?? 5;
    const data = {
      fromDate: value.fromDate ? value.fromDate : fromDate,
      graphType: value.chartName ? value.chartName : chartName,
      siteId: siteIdFilter[0].siteId,
      toDate: value.toDate ? value.toDate : toDate,
      range:
        rangeValue == "Yearly"
          ? "yearlyCum"
          : rangeValue == "Monthly"
          ? "yearly"
          : rangeValue == "Daily"
          ? "daily"
          : "custom",
      equipmentIds: meter,
      intervalMins: dataFrequency,
      timeperiod: "Today",
      energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
      energyFlag: 0,
    };
    if (name === "Specific Yield" || name === "Specific Yield - Heatmap") {
      const EquipmentForSpecificYield = transformDataForSpecificYield(equipm);
      data.equipmentIdAndCapacity = EquipmentForSpecificYield;
    }
    setCustomView(data);
    setLoading(true);
    let responseData = await ActualVsExpected(data);
    responseData = sortedDataByMonth(responseData);
    console.log(responseData, "responseData");

    setLoading(false);
    setTableData(responseData);
  };

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      const equipList = await fetchEquipDropdownBySite(data[0]?.siteId);
      setSiteId([...new Set(data)]);
      setEquipments(equipList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeDate = (direction) => {
    let newToDate, newFromDate;
    const currentToDate = moment(toDate);
    const currentFromDate = moment(fromDate);
    if (
      currentFromDate.isSame(moment().startOf("day")) &&
      direction === "right"
    ) {
      return;
    }
    const dateDifference = range === "Daily" ? 1 : 7;
    // Calculate the date range difference
    if (range.toLocaleLowerCase().includes("month")) {
      if (direction === "right") {
        newFromDate = currentFromDate.clone().add(1, "month").startOf("month");
        newToDate = currentToDate.clone().add(1, "month").endOf("month");
      } else if (direction === "left") {
        newFromDate = currentFromDate
          .clone()
          .subtract(1, "month")
          .startOf("month");
        newToDate = currentToDate.clone().subtract(1, "month").endOf("month");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    } else if (range.toLocaleLowerCase().includes("year")) {
      if (direction === "right") {
        newFromDate = currentFromDate.clone().add(1, "year").startOf("year");
        newToDate = currentToDate.clone().add(1, "year").endOf("year");
      } else if (direction === "left") {
        newFromDate = currentFromDate
          .clone()
          .subtract(1, "year")
          .startOf("year");
        newToDate = currentToDate.clone().subtract(1, "year").endOf("year");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    } else {
      const dateDiff = dateDifference;

      if (direction === "left") {
        newToDate = currentToDate.subtract(dateDiff, "days");
        newFromDate = currentFromDate.subtract(dateDiff, "days");
      } else if (direction === "right") {
        newToDate = currentToDate.add(dateDiff, "days");
        newFromDate = currentFromDate.add(dateDiff, "days");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    }

    // const today = moment().startOf("day");

    // if (newToDate.isAfter(today)) {
    //   // If the new "to" date is in the future, reset it to today
    //   newToDate = today;
    // }

    const formattedToDate = newToDate.format("YYYY-MM-DD");
    const formattedFromDate = newFromDate.format("YYYY-MM-DD");

    setToDate(formattedToDate);
    setFromDate(formattedFromDate);
    ApplyChange({
      toDate: formattedToDate,
      fromDate: formattedFromDate,
    });
  };

  const NodataAvailable = false;

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  return NodataAvailable ? (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Site has been configured yet
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Card
      sx={{ width: "100%", height: "100%", paddingBottom: 0 }}
      elevation={0}
      key={name}
    >
      <CardContent sx={{ height: "100%", paddingBottom: "0 !important" }}>
        <Grid>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "0.95rem" }}>
                Actual Vs Expected
              </Typography>
            </div>
            <div>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                width="16vw"
                justifyContent="space-between"
                p="0"
                style={{
                  border: CheckForLight()
                    ? "1px solid #c4c4c4"
                    : "1px solid #494949",
                }}
              >
                <IconButton onClick={() => handleChangeDate("left")}>
                  <ChevronLeftIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  style={{
                    cursor: "pointer",
                    flex: "1",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    mt="1px"
                    ml="2.5px"
                    style={{
                      color: CheckForLight() ? "black" : "white",
                      fontSize: "0.95rem",
                    }}
                  >
                    {range === "Daily"
                      ? moment(toDate).format("DD/MM/YYYY")
                      : range === "Monthly" || range === "Yearly"
                      ? moment(toDate).format("YYYY")
                      : ` ${moment(fromDate).format("DD/MM")}-${moment(
                          toDate
                        ).format("DD/MM")}`}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  disabled={moment(toDate).isSame(moment().startOf("day"))}
                  onClick={() => handleChangeDate("right")}
                >
                  <ChevronRightIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  onClick={(event) => handleClickMenuItem(event, "Time")}
                >
                  <KeyboardArrowDownIcon
                    sx={{
                      color: CheckForLight() ? "black" : "white",
                    }}
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {optionTime.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={(event) =>
                        handleMenuItemClick(option.value, "Time")
                      }
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          </div>
        </Grid>
        {loading ? (
          <Card
            sx={{
              width: "100%",
              height: "43vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "43vh",
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </CardContent>
          </Card>
        ) : tableData.length === 0 ? (
          fallBack
        ) : (
          <CombinedChart
            tableData={tableData}
            customView={customView}
            WidgetName={` `}
            chartName={`${chartName}`}
            customName={`${siteName}-${chartName}`}
            isDashboard={true}
          />
        )}
      </CardContent>
    </Card>
  );
};

//  Deemed Generation
export const ContactAnalytics5 = ({ name, siteName, siteDetails, refresh }) => {
  const [site, setSite] = useState(siteName);
  const [siteIdName, setSiteId] = useState([]);
  const [customView, setCustomView] = useState();
  const [multiChartData, setMultichartData] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartName, setChartName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [timerOptions, setTimerOptions] = useState(timer);
  const [timerValue, setTimerValue] = useState(5);
  const [anchorTimerEl, setAnchorTimerEl] = useState(null);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [range, setRange] = useState("Last 7 days");
  const [menuOpen, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setSite(siteName);
    setChartName("Deemed Generation");
    setSelectedOption("Deemed Generation");
    getSiteCall();

    fetchApplyChange(siteDetails);
  }, [siteName]);

  useEffect(() => {
    if (refresh) {
      fetchApplyChange(siteDetails);
    }
  }, [refresh]);

  const handleClickMenuItem = (event) => {
    setAnchorTimerEl(event.currentTarget);
  };
  const handleCloseTimer = () => {
    setAnchorTimerEl(null);
  };
  const handleMenuItemClick = async (value) => {
    setTimerValue(value);
    ApplyChange({ time: value });
  };

  const handleClickMenu = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleMenuItem = (option) => {
    setTimerValue(5);
    let startDate, endDate;

    switch (option) {
      case "Daily":
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Last 7 days":
        startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Weekly":
        startDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
        endDate = moment().endOf("isoWeek").format("YYYY-MM-DD");
        break;
      case "This Month":
        startDate = moment().startOf("month").format("YYYY-MM-DD");
        endDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Monthly":
        startDate = moment().startOf("year").format("YYYY-MM-DD");
        endDate = moment().startOf("today").format("YYYY-MM-DD");
        break;
      case "Yearly":
        startDate = moment()
          .startOf("year")
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        endDate = moment().endOf("today").format("YYYY-MM-DD");
        break;
      case "custom":
        // For custom range, you might set these dates through a date picker or user input
        break;
      default:
        startDate = null;
        endDate = null;
    }

    setFromDate(startDate);
    setToDate(endDate);
    setRange(option);
    handleCloseMenu();
    ApplyChange({ fromDate: startDate, toDate: endDate, range: option });
  };

  function convertDailyDeemedData(data) {
    const formattedData = [
      { name: "Today Energy", data: [] },
      { name: "Irradiation", data: [] },
      { name: "Deemed Generation", data: [] },
      { name: "Set Point", data: [] },
      { name: "Potential Generation", data: [] },
    ];
    if (data.length === 0) return formattedData;
    data.forEach((point) => {
      const timestamp = new Date(point.timeStamp).getTime();

      formattedData[0].data.push([timestamp, point.todayEnergy]);
      formattedData[1].data.push([timestamp, point.irradiation]);
      formattedData[2].data.push([timestamp, point.deemedGeneration]);
      formattedData[3].data.push([timestamp, point.setPoint]);
      formattedData[4].data.push([timestamp, point.potentialGeneration]);
    });

    return formattedData;
  }

  function checkArray(arr) {
    // Function to check if all values in an object are null
    function allValuesNull(obj) {
      return Object.values(obj).every((value) => value === null);
    }

    // Check each object in the array
    for (let obj of arr) {
      if (!allValuesNull(obj)) {
        return arr; // Return the original array if any object has non-null values
      }
    }

    return []; // Return an empty array if all objects have all null values
  }

  const fetchApplyChange = async (siteDetails) => {
    setRange("Daily");
    let date = new Date();
    let today = moment(date).format("YYYY-MM-DD");
    setToDate(today);
    setFromDate(today);
    let meter = [];
    const equipDetails = await fetchEquipDropdownBySite(siteDetails.siteId);
    let filteredObjects = equipDetails?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    const dataFrequency = siteDetails.siteFrequency ?? 5;
    meter = equipmentIds;
    const dataModel = {
      fromDate: today,
      graphType: "Deemed Generation",
      siteId: siteDetails.siteId,
      toDate: today,
      range: "daily",
      equipmentIds: meter,
      capacity: siteDetails.installationCapacity,
      timeperiod: "Today",
      intervalMins: dataFrequency,
      energyGenBasedOn: siteDetails.energyGenBasedOn,
      energyFlag: 0,
    };
    console.log(dataModel, "dataModel");
    setCustomView(dataModel);
    setLoading(true);
    console.log("CustomView", dataModel);
    let responseData = await DeemedGenrator(dataModel);
    responseData = checkArray(responseData);
    setChartName("Deemed Generation");
    const result = convertDailyDeemedData(responseData);

    setLoading(false);
    setTableData(responseData);
    setMultichartData(result);
  };

  const ApplyChange = async (value) => {
    const siteName = value.siteName ? value.siteName : site;
    console.log(site);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteName;
    });
    console.log(siteIdFilter);

    let meter = [];
    const equipm = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);

    let filteredObjects = equipm?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    meter = equipmentIds;
    const rangeValue = value.range ? value.range : range;
    const dataFrequency = value.time ? value.time : timerValue;
    const data = {
      fromDate: value.fromDate ? value.fromDate : fromDate,
      graphType: value.chartName ? value.chartName : chartName,
      siteId: siteIdFilter[0].siteId,
      toDate: value.toDate ? value.toDate : toDate,
      capacity: siteIdFilter[0].installationCapacity,
      range:
        rangeValue == "Yearly"
          ? "yearlyCum"
          : rangeValue == "Monthly"
          ? "yearly"
          : rangeValue == "Daily"
          ? "daily"
          : "custom",
      equipmentIds: meter,
      intervalMins: dataFrequency,
      timeperiod: "Today",
      energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
      energyFlag: 0,
    };
    setCustomView(data);
    setLoading(true);
    if (fromDate === toDate) {
      try {
        let responseData = await DeemedGenrator(data);
        responseData = checkArray(responseData);
        setChartName("Deemed Generation");
        const result = convertDailyDeemedData(responseData);

        setLoading(false);
        setTableData(responseData);
        setMultichartData(result);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    } else {
      try {
        let responseData = await DeemedGenrator(data);
        responseData = checkArray(responseData);
        setLoading(false);
        setTableData(responseData);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    handleCloseTimer();
    handleCloseMenu();
  };

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      const equipList = await fetchEquipDropdownBySite(data[0]?.siteId);
      setSiteId([...new Set(data)]);
      setEquipments(equipList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeDate = (direction) => {
    let newToDate, newFromDate;
    const currentToDate = moment(toDate);
    const currentFromDate = moment(fromDate);
    if (
      currentFromDate.isSame(moment().startOf("day")) &&
      direction === "right"
    ) {
      return;
    }
    const dateDifference = range === "Daily" ? 1 : 7;
    // Calculate the date range difference
    if (range.toLocaleLowerCase().includes("month")) {
      if (direction === "right") {
        newFromDate = currentFromDate.clone().add(1, "month").startOf("month");
        newToDate = currentToDate.clone().add(1, "month").endOf("month");
      } else if (direction === "left") {
        newFromDate = currentFromDate
          .clone()
          .subtract(1, "month")
          .startOf("month");
        newToDate = currentToDate.clone().subtract(1, "month").endOf("month");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    } else if (range.toLocaleLowerCase().includes("year")) {
      if (direction === "right") {
        newFromDate = currentFromDate.clone().add(1, "year").startOf("year");
        newToDate = currentToDate.clone().add(1, "year").endOf("year");
      } else if (direction === "left") {
        newFromDate = currentFromDate
          .clone()
          .subtract(1, "year")
          .startOf("year");
        newToDate = currentToDate.clone().subtract(1, "year").endOf("year");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    } else {
      const dateDiff = dateDifference;

      if (direction === "left") {
        newToDate = currentToDate.subtract(dateDiff, "days");
        newFromDate = currentFromDate.subtract(dateDiff, "days");
      } else if (direction === "right") {
        newToDate = currentToDate.add(dateDiff, "days");
        newFromDate = currentFromDate.add(dateDiff, "days");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    }

    // const today = moment().startOf("day");

    // if (newToDate.isAfter(today)) {
    //   // If the new "to" date is in the future, reset it to today
    //   newToDate = today;
    // }

    const formattedToDate = newToDate.format("YYYY-MM-DD");
    const formattedFromDate = newFromDate.format("YYYY-MM-DD");

    setToDate(formattedToDate);
    setFromDate(formattedFromDate);
    ApplyChange({
      toDate: formattedToDate,
      fromDate: formattedFromDate,
    });
  };

  const NodataAvailable = false;

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  return NodataAvailable ? (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Site has been configured yet
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Card
      sx={{ width: "100%", height: "100%", paddingBottom: 0 }}
      elevation={0}
      key={name}
    >
      <CardContent sx={{ height: "100%", paddingBottom: "0 !important" }}>
        <Grid>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "0.95rem" }}>
                Deemed Generation
              </Typography>
            </div>
            <div>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                width="18vw"
                justifyContent="space-between"
                p="0"
                style={{
                  border: CheckForLight()
                    ? "1px solid #c4c4c4"
                    : "1px solid #494949",
                }}
              >
                <IconButton onClick={() => handleChangeDate("left")}>
                  <ChevronLeftIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  style={{
                    cursor: "pointer",
                    flex: "1",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    mt="1px"
                    ml="2.5px"
                    style={{
                      color: CheckForLight() ? "black" : "white",
                      fontSize: "0.95rem",
                    }}
                  >
                    {range === "Daily"
                      ? moment(toDate).format("DD/MM/YYYY")
                      : range === "Monthly" || range === "Yearly"
                      ? moment(toDate).format("YYYY")
                      : ` ${moment(fromDate).format("DD/MM")}-${moment(
                          toDate
                        ).format("DD/MM")}`}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  disabled={moment(toDate).isSame(moment().startOf("day"))}
                  onClick={() => handleChangeDate("right")}
                >
                  <ChevronRightIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  onClick={(event) => handleClickMenuItem(event)}
                  disabled={chartName !== "Daily Generation"}
                  sx={{
                    width: "40px",
                  }}
                >
                  <Badge
                    badgeContent={timerValue}
                    color="default"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <TimerOutlinedIcon
                      sx={{
                        color: CheckForLight() ? "black" : "white",
                        opacity: chartName !== "Daily Generation" ? 0.5 : 1,
                        pointerEvents:
                          chartName !== "Daily Generation" ? "none" : "auto",
                        fontSize: "20px",
                      }}
                    />
                  </Badge>
                </IconButton>
                <Menu
                  anchorEl={anchorTimerEl}
                  open={Boolean(anchorTimerEl)}
                  onClose={handleCloseTimer}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {timerOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={() => handleMenuItemClick(option.value, "Timer")}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
                <Divider orientation="vertical" flexItem />
                <IconButton onClick={(event) => handleClickMenu(event, "Time")}>
                  <KeyboardArrowDownIcon
                    sx={{
                      color: CheckForLight() ? "black" : "white",
                    }}
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {optionTime.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={(event) => handleMenuItem(option.value, "Time")}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          </div>
        </Grid>
        {loading ? (
          <Card
            sx={{
              width: "100%",
              height: "43vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "43vh",
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </CardContent>
          </Card>
        ) : tableData.length === 0 || multiChartData.length === 0 ? (
          fallBack
        ) : range == "Daily" ? (
          <Apexchart
            chartName={"Daily Generation"}
            tableData={tableData}
            multiChartData={multiChartData}
            key="1"
            name="chartData"
            widgetName={` `}
            customName={`${siteName}-${chartName}`}
            isDashboard={true}
          />
        ) : (
          <BarChart
            tableData={tableData}
            chartName={chartName}
            customView={customView}
            widgetName={` `}
            fallBack={fallBack}
            customName={`${siteName}-${chartName}`}
            isDashboard={true}
          />
        )}
      </CardContent>
    </Card>
  );
};

//  system interactivity
export const ContactAnalytics6 = ({ name, siteName, siteDetails, refresh }) => {
  const [site, setSite] = useState(siteName);
  const [siteIdName, setSiteId] = useState([]);
  const [customView, setCustomView] = useState();
  const [multiChartData, setMultichartData] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartName, setChartName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [timerOptions, setTimerOptions] = useState(timer);
  const [timerValue, setTimerValue] = useState(5);
  const [anchorTimerEl, setAnchorTimerEl] = useState(null);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [range, setRange] = useState("Last 7 days");
  const [menuOpen, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setSite(siteName);
    setChartName("DG PV Grid Management");
    setSelectedOption("DG PV Grid Management");
    getSiteCall();

    fetchApplyChange(siteDetails);
  }, [siteName]);

  useEffect(() => {
    if (refresh) {
      fetchApplyChange(siteDetails);
    }
  }, [refresh]);

  const handleClickMenuItem = (event) => {
    setAnchorTimerEl(event.currentTarget);
  };
  const handleCloseTimer = () => {
    setAnchorTimerEl(null);
  };
  const handleMenuItemClick = async (value) => {
    setTimerValue(value);
    ApplyChange({ time: value });
  };

  const handleClickMenu = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleMenuItem = (option) => {
    setTimerValue(5);
    let startDate, endDate;

    switch (option) {
      case "Daily":
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Last 7 days":
        startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Weekly":
        startDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
        endDate = moment().endOf("isoWeek").format("YYYY-MM-DD");
        break;
      case "This Month":
        startDate = moment().startOf("month").format("YYYY-MM-DD");
        endDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Monthly":
        startDate = moment().startOf("year").format("YYYY-MM-DD");
        endDate = moment().startOf("today").format("YYYY-MM-DD");
        break;
      case "Yearly":
        startDate = moment()
          .startOf("year")
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        endDate = moment().endOf("today").format("YYYY-MM-DD");
        break;
      case "custom":
        // For custom range, you might set these dates through a date picker or user input
        break;
      default:
        startDate = null;
        endDate = null;
    }

    setFromDate(startDate);
    setToDate(endDate);
    setRange(option);
    handleCloseMenu();
    ApplyChange({ fromDate: startDate, toDate: endDate, range: option });
  };

  const fetchApplyChange = async (siteDetails) => {
    setRange("Daily");
    let date = new Date();
    let today = moment(date).format("YYYY-MM-DD");
    setToDate(today);
    setFromDate(today);
    let meter = [];
    const equipDetails = await fetchEquipDropdownBySite(siteDetails.siteId);
    let filteredObjects = equipDetails?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    const dataFrequency = siteDetails.siteFrequency ?? 5;
    meter = equipmentIds;
    const dataModel = {
      fromDate: today,
      graphType: "DG PV Grid Management",
      // equipmentIds: equipmentIds,
      siteId: siteDetails.siteId,
      toDate: today,
      range: "daily",
      // equipmentIds: meter,
      timeperiod: "Today",
      intervalMins: dataFrequency,
      energyGenBasedOn: siteDetails.energyGenBasedOn,
      energyFlag: 0,
    };
    console.log(dataModel, "dataModel");
    setCustomView(dataModel);
    setLoading(true);
    console.log("CustomView", dataModel);
    let responseData = await fetchPvDgGridEira(dataModel);
    console.log(responseData, "--------------------------responseData");

    setLoading(false);
    let result;
    result = {
      dgMeter: responseData.dgMeter
        ? removeAfterTime([...responseData.dgMeter], "19:00:00")
        : [],
      gridMeter: responseData.gridMeter
        ? removeAfterTime([...responseData.gridMeter], "19:00:00")
        : [],
      solarMeter: responseData.solarMeter
        ? removeAfterTime([...responseData.solarMeter], "19:00:00")
        : [],
    };
    // //console.log(result, 'responseData');
    setLoading(false);
    // //console.log(responseData, "responseData");
    console.log(result, "--------------------result");

    setTableData(result);
    setChartName(dataModel?.graphType);
  };

  const ApplyChange = async (value) => {
    const siteName = value.siteName ? value.siteName : site;
    console.log(site);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteName;
    });
    console.log(siteIdFilter);

    let meter = [];
    const equipm = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);

    let filteredObjects = equipm?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    meter = equipmentIds;
    const rangeValue = value.range ? value.range : range;
    const dataFrequency = value.time ? value.time : timerValue;
    const data = {
      fromDate: value.fromDate ? value.fromDate : fromDate,
      graphType: value.chartName ? value.chartName : chartName,
      siteId: siteIdFilter[0].siteId,
      toDate: value.toDate ? value.toDate : toDate,
      range:
        rangeValue == "Yearly"
          ? "yearlyCum"
          : rangeValue == "Monthly"
          ? "yearly"
          : rangeValue == "Daily"
          ? "daily"
          : "custom",
      // equipmentIds: meter,
      intervalMins: dataFrequency,
      timeperiod: "Today",
      energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
      energyFlag: 0,
    };
    setCustomView(data);
    setLoading(true);
    // const responseData = await fetchDailyGenerationEira(data);
    const responseData = await fetchPvDgGridEira(data);
    setLoading(false);
    console.log(responseData, "--------------------------responseData");

    let result;
    result = {
      dgMeter: responseData.dgMeter,
      gridMeter: responseData.gridMeter,
      solarMeter: responseData.solarMeter,
    };
    // console.log(result, 'responseData');
    setLoading(false);
    // //console.log(responseData, "responseData");
    console.log(result, "--------------------result");

    setTableData(result);
    setChartName(data?.graphType);
    handleCloseTimer();
    handleCloseMenu();
  };

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      const equipList = await fetchEquipDropdownBySite(data[0]?.siteId);
      setSiteId([...new Set(data)]);
      setEquipments(equipList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeDate = (direction) => {
    let newToDate, newFromDate;
    const currentToDate = moment(toDate);
    const currentFromDate = moment(fromDate);
    if (
      currentFromDate.isSame(moment().startOf("day")) &&
      direction === "right"
    ) {
      return;
    }
    const dateDifference = range === "Daily" ? 1 : 7;
    // Calculate the date range difference
    if (range.toLocaleLowerCase().includes("month")) {
      if (direction === "right") {
        newFromDate = currentFromDate.clone().add(1, "month").startOf("month");
        newToDate = currentToDate.clone().add(1, "month").endOf("month");
      } else if (direction === "left") {
        newFromDate = currentFromDate
          .clone()
          .subtract(1, "month")
          .startOf("month");
        newToDate = currentToDate.clone().subtract(1, "month").endOf("month");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    } else if (range.toLocaleLowerCase().includes("year")) {
      if (direction === "right") {
        newFromDate = currentFromDate.clone().add(1, "year").startOf("year");
        newToDate = currentToDate.clone().add(1, "year").endOf("year");
      } else if (direction === "left") {
        newFromDate = currentFromDate
          .clone()
          .subtract(1, "year")
          .startOf("year");
        newToDate = currentToDate.clone().subtract(1, "year").endOf("year");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    } else {
      const dateDiff = dateDifference;

      if (direction === "left") {
        newToDate = currentToDate.subtract(dateDiff, "days");
        newFromDate = currentFromDate.subtract(dateDiff, "days");
      } else if (direction === "right") {
        newToDate = currentToDate.add(dateDiff, "days");
        newFromDate = currentFromDate.add(dateDiff, "days");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    }

    // const today = moment().startOf("day");

    // if (newToDate.isAfter(today)) {
    //   // If the new "to" date is in the future, reset it to today
    //   newToDate = today;
    // }

    const formattedToDate = newToDate.format("YYYY-MM-DD");
    const formattedFromDate = newFromDate.format("YYYY-MM-DD");

    setToDate(formattedToDate);
    setFromDate(formattedFromDate);
    ApplyChange({
      toDate: formattedToDate,
      fromDate: formattedFromDate,
    });
  };

  const NodataAvailable = false;

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  return NodataAvailable ? (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Site has been configured yet
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Card
      sx={{ width: "100%", height: "100%", paddingBottom: 0 }}
      elevation={0}
      key={name}
    >
      <CardContent sx={{ height: "100%", paddingBottom: "0 !important" }}>
        <Grid>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "0.95rem" }}>
                DG PV Grid Management
              </Typography>
            </div>
            <div>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                width="18vw"
                justifyContent="space-between"
                p="0"
                style={{
                  border: CheckForLight()
                    ? "1px solid #c4c4c4"
                    : "1px solid #494949",
                }}
              >
                <IconButton onClick={() => handleChangeDate("left")}>
                  <ChevronLeftIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  style={{
                    cursor: "pointer",
                    flex: "1",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    mt="1px"
                    ml="2.5px"
                    style={{
                      color: CheckForLight() ? "black" : "white",
                      fontSize: "0.95rem",
                    }}
                  >
                    {range === "Daily"
                      ? moment(toDate).format("DD/MM/YYYY")
                      : range === "Monthly" || range === "Yearly"
                      ? moment(toDate).format("YYYY")
                      : ` ${moment(fromDate).format("DD/MM")}-${moment(
                          toDate
                        ).format("DD/MM")}`}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  disabled={moment(toDate).isSame(moment().startOf("day"))}
                  onClick={() => handleChangeDate("right")}
                >
                  <ChevronRightIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  onClick={(event) => handleClickMenuItem(event)}
                  disabled={chartName !== "Daily Generation"}
                  sx={{
                    width: "40px",
                  }}
                >
                  <Badge
                    badgeContent={timerValue}
                    color="default"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <TimerOutlinedIcon
                      sx={{
                        color: CheckForLight() ? "black" : "white",
                        opacity: chartName !== "Daily Generation" ? 0.5 : 1,
                        pointerEvents:
                          chartName !== "Daily Generation" ? "none" : "auto",
                        fontSize: "20px",
                      }}
                    />
                  </Badge>
                </IconButton>
                <Menu
                  anchorEl={anchorTimerEl}
                  open={Boolean(anchorTimerEl)}
                  onClose={handleCloseTimer}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {timerOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={() => handleMenuItemClick(option.value, "Timer")}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
                <Divider orientation="vertical" flexItem />
                <IconButton onClick={(event) => handleClickMenu(event, "Time")}>
                  <KeyboardArrowDownIcon
                    sx={{
                      color: CheckForLight() ? "black" : "white",
                    }}
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {optionTime.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={(event) => handleMenuItem(option.value, "Time")}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          </div>
        </Grid>
        {loading ? (
          <Card
            sx={{
              width: "100%",
              height: "43vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "43vh",
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </CardContent>
          </Card>
        ) : tableData.length === 0 ? (
          fallBack
        ) : range == "Daily" ? (
          <AreaChart
            tableData={tableData}
            WidgetName={``}
            chartName={`${chartName}`}
            fallBack={fallBack}
            customName={`${siteName}-system interactivity`}
            isDashboard={true}
          />
        ) : (
          <BarChart
            tableData={tableData}
            chartName={chartName}
            customView={customView}
            widgetName={` `}
            fallBack={fallBack}
            isDashboard={true}
          />
        )}
      </CardContent>
    </Card>
  );
};

//  PR chart
export const ContactAnalytics7 = ({ name, siteName, siteDetails, refresh }) => {
  const [site, setSite] = useState(siteName);
  const [siteIdName, setSiteId] = useState([]);
  const [customView, setCustomView] = useState();
  const [multiChartData, setMultichartData] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartName, setChartName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [timerOptions, setTimerOptions] = useState(timer);
  const [timerValue, setTimerValue] = useState(5);
  const [anchorTimerEl, setAnchorTimerEl] = useState(null);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [range, setRange] = useState("Last 7 days");
  const [menuOpen, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setSite(siteName);
    setChartName("Performance Ratio");
    setSelectedOption("Performance Ratio");
    getSiteCall();

    fetchApplyChange(siteDetails);
  }, [siteName]);

  useEffect(() => {
    if (refresh) {
      fetchApplyChange(siteDetails);
    }
  }, [refresh]);

  const handleClickMenuItem = (event) => {
    setAnchorTimerEl(event.currentTarget);
  };
  const handleCloseTimer = () => {
    setAnchorTimerEl(null);
  };
  const handleMenuItemClick = async (value) => {
    setTimerValue(value);
    ApplyChange({ time: value });
  };

  const handleClickMenu = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleMenuItem = (option) => {
    setTimerValue(5);
    let startDate, endDate;

    switch (option) {
      case "Daily":
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Last 7 days":
        startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Weekly":
        startDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
        endDate = moment().endOf("isoWeek").format("YYYY-MM-DD");
        break;
      case "This Month":
        startDate = moment().startOf("month").format("YYYY-MM-DD");
        endDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Monthly":
        startDate = moment().startOf("year").format("YYYY-MM-DD");
        endDate = moment().startOf("today").format("YYYY-MM-DD");
        break;
      case "Yearly":
        startDate = moment()
          .startOf("year")
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        endDate = moment().endOf("today").format("YYYY-MM-DD");
        break;
      case "custom":
        // For custom range, you might set these dates through a date picker or user input
        break;
      default:
        startDate = null;
        endDate = null;
    }

    setFromDate(startDate);
    setToDate(endDate);
    setRange(option);
    handleCloseMenu();
    ApplyChange({ fromDate: startDate, toDate: endDate, range: option });
  };
  const fetchApplyChange = async (siteDetails) => {
    setRange("Daily");
    let date = new Date();
    let today = moment(date).format("YYYY-MM-DD");
    setToDate(today);
    setFromDate(today);
    let meter = [];
    const equipDetails = await fetchEquipDropdownBySite(siteDetails.siteId);
    let filteredObjects = equipDetails?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );

    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });
    const dataFrequency = siteDetails.siteFrequency ?? 5;
    meter = equipmentIds;
    const dataModel = {
      fromDate: today,
      graphType: "Performance Ratio",
      siteId: siteDetails.siteId,
      toDate: today,
      range: "daily",
      equipmentIds: meter,
      capacity: siteDetails.installationCapacity,
      timeperiod: "Today",
      intervalMins: dataFrequency,
      energyGenBasedOn: siteDetails.energyGenBasedOn,
      energyFlag: 0,
    };
    console.log(dataModel, "dataModel");
    setCustomView(dataModel);
    setLoading(true);
    console.log("CustomView", dataModel);
    delete dataModel.range;
    setLoading(true);
    try {
      let responseData = await GetPRData(dataModel);
      setTableData(responseData);
      setLoading(false);
      setChartName(dataModel?.graphType);
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  const ApplyChange = async (value) => {
    const siteName = value.siteName ? value.siteName : site;
    console.log(site);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === siteName;
    });
    console.log(siteIdFilter);

    let meter = [];
    const equipm = await fetchEquipDropdownBySite(siteIdFilter[0]?.siteId);

    let filteredObjects = equipm?.filter((obj) =>
      ["Weather Station", "Energy Meter"].includes(obj.equipmentCategory)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentId;
    });

    meter = equipmentIds;
    const rangeValue = value.range ? value.range : range;
    const dataFrequency = value.time ? value.time : timerValue;
    const data = {
      fromDate: value.fromDate ? value.fromDate : fromDate,
      graphType: value.chartName ? value.chartName : chartName,
      siteId: siteIdFilter[0].siteId,
      toDate: value.toDate ? value.toDate : toDate,
      capacity: siteIdFilter[0].installationCapacity,
      range:
        rangeValue == "Yearly"
          ? "yearlyCum"
          : rangeValue == "Monthly"
          ? "yearly"
          : rangeValue == "Daily"
          ? "daily"
          : "custom",
      equipmentIds: meter,
      intervalMins: dataFrequency,
      timeperiod: "Today",
      energyGenBasedOn: siteIdFilter[0].energyGenBasedOn,
      energyFlag: 0,
    };
    setCustomView(data);
    setLoading(true);
    delete data.range;
    setLoading(true);
    try {
      let responseData = await GetPRData(data);
      setTableData(responseData);
      setLoading(false);
      setChartName(data?.graphType);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
    handleCloseTimer();
    handleCloseMenu();
  };

  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem("id");
      let data = await fetchSiteDropdownByUser(id);
      const equipList = await fetchEquipDropdownBySite(data[0]?.siteId);
      setSiteId([...new Set(data)]);
      setEquipments(equipList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeDate = (direction) => {
    let newToDate, newFromDate;
    const currentToDate = moment(toDate);
    const currentFromDate = moment(fromDate);
    if (
      currentFromDate.isSame(moment().startOf("day")) &&
      direction === "right"
    ) {
      return;
    }
    const dateDifference = range === "Daily" ? 1 : 7;
    // Calculate the date range difference
    if (range.toLocaleLowerCase().includes("month")) {
      if (direction === "right") {
        newFromDate = currentFromDate.clone().add(1, "month").startOf("month");
        newToDate = currentToDate.clone().add(1, "month").endOf("month");
      } else if (direction === "left") {
        newFromDate = currentFromDate
          .clone()
          .subtract(1, "month")
          .startOf("month");
        newToDate = currentToDate.clone().subtract(1, "month").endOf("month");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    } else if (range.toLocaleLowerCase().includes("year")) {
      if (direction === "right") {
        newFromDate = currentFromDate.clone().add(1, "year").startOf("year");
        newToDate = currentToDate.clone().add(1, "year").endOf("year");
      } else if (direction === "left") {
        newFromDate = currentFromDate
          .clone()
          .subtract(1, "year")
          .startOf("year");
        newToDate = currentToDate.clone().subtract(1, "year").endOf("year");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    } else {
      const dateDiff = dateDifference;

      if (direction === "left") {
        newToDate = currentToDate.subtract(dateDiff, "days");
        newFromDate = currentFromDate.subtract(dateDiff, "days");
      } else if (direction === "right") {
        newToDate = currentToDate.add(dateDiff, "days");
        newFromDate = currentFromDate.add(dateDiff, "days");
      } else {
        return; // Do nothing if direction is not 'left' or 'right'
      }
    }

    // const today = moment().startOf("day");

    // if (newToDate.isAfter(today)) {
    //   // If the new "to" date is in the future, reset it to today
    //   newToDate = today;
    // }

    const formattedToDate = newToDate.format("YYYY-MM-DD");
    const formattedFromDate = newFromDate.format("YYYY-MM-DD");

    setToDate(formattedToDate);
    setFromDate(formattedFromDate);
    ApplyChange({
      toDate: formattedToDate,
      fromDate: formattedFromDate,
    });
  };

  const NodataAvailable = false;

  const fallBack = (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Data Found For Selected Time range
        </Typography>
      </CardContent>
    </Card>
  );

  return NodataAvailable ? (
    <Card
      sx={{
        width: "100%",
        height: "43vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "43vh",
        }}
      >
        <Typography
          style={{
            fontSize: "1.5rem",
            textTransform: "capitalize",
            lineHeight: "14.52px !important",
            color: " rgb(113, 118, 111)",
          }}
        >
          No Site has been configured yet
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Card
      sx={{ width: "100%", height: "100%", paddingBottom: 0 }}
      elevation={0}
      key={name}
    >
      <CardContent sx={{ height: "100%", paddingBottom: "0 !important" }}>
        <Grid>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography style={{ fontSize: "0.95rem" }}>
                Performance Ratio
              </Typography>
            </div>
            <div>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                width="18vw"
                justifyContent="space-between"
                p="0"
                style={{
                  border: CheckForLight()
                    ? "1px solid #c4c4c4"
                    : "1px solid #494949",
                }}
              >
                <IconButton onClick={() => handleChangeDate("left")}>
                  <ChevronLeftIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <Stack
                  direction="row"
                  style={{
                    cursor: "pointer",
                    flex: "1",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    mt="1px"
                    ml="2.5px"
                    style={{
                      color: CheckForLight() ? "black" : "white",
                      fontSize: "0.95rem",
                    }}
                  >
                    {range === "Daily"
                      ? moment(toDate).format("DD/MM/YYYY")
                      : range === "Monthly" || range === "Yearly"
                      ? moment(toDate).format("YYYY")
                      : ` ${moment(fromDate).format("DD/MM")}-${moment(
                          toDate
                        ).format("DD/MM")}`}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
                <IconButton
                  disabled={moment(toDate).isSame(moment().startOf("day"))}
                  onClick={() => handleChangeDate("right")}
                >
                  <ChevronRightIcon />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <IconButton onClick={(event) => handleClickMenu(event, "Time")}>
                  <KeyboardArrowDownIcon
                    sx={{
                      color: CheckForLight() ? "black" : "white",
                    }}
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {optionTime.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={(event) => handleMenuItem(option.value, "Time")}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          </div>
        </Grid>
        {loading ? (
          <Card
            sx={{
              width: "100%",
              height: "43vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "43vh",
              }}
            >
              <FadeLoader color="#000440" loading={true} />
            </CardContent>
          </Card>
        ) : tableData.length === 0 ? (
          fallBack
        ) : (
          <BarChart
            tableData={tableData}
            chartName={chartName}
            customView={customView}
            widgetName={` `}
            fallBack={fallBack}
            customName={`${siteName}-${chartName}`}
            isDashboard={true}
          />
        )}
      </CardContent>
    </Card>
  );
};
