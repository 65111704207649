import axios from 'axios';

const isOEM = sessionStorage.getItem('userTypeId') == 1;

export const fetcherrorTable = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ERROR_TABLE}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const fetcherrorTableById = async (Id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${
        isOEM
          ? process.env.REACT_APP_ERROR_BYCOMPANY
          : process.env.REACT_APP_ERROR_BYID
      }/${
     Id
      }`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const Equipmentcategorydropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_CATEGORY}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const Equipmenttypedropdown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_TYPE}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const putErrortype = async (data, id) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ERROR_UPDATE}/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const postErrortype = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ERROR_SAVE}`,
      obj
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
export const postErrorexceldata = async (obj) => {
  try {
    const resolvedData = await Promise.all(obj);
    console.log(resolvedData, "ghj");
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_ERROR_EXCEL_TABLE}`,
      resolvedData
    );
    return response;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};

/*------------------------- Site Name list---------------------------*/
// export const fetchErrorTypeDetails = async (id) => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_API_PATH}${
//         process.env.REACT_APP_SITELIST_BYUSER
//       }/${sessionStorage.getItem('id')}`
//     );
//     console.log(response, 'resssss');
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error
//   }
// };
export const fetchErrorTypeDetails = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_EQUIPMENT_TYPE}`
    );
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
