import { current } from "@reduxjs/toolkit";

//Montly Logic
export function getMonthFlag(date) {
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const month = months[date.getMonth()]; // Get month in short form
  const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
  return `${month}-${year}`;
}

export function convertMonthFlag(flag) {
  const monthsMap = {
    JAN: "January",
    FEB: "February",
    MAR: "March",
    APR: "April",
    MAY: "May",
    JUN: "June",
    JUL: "July",
    AUG: "August",
    SEP: "September",
    OCT: "October",
    NOV: "November",
    DEC: "December",
  };

  const parts = flag.split("-");
  const month = monthsMap[parts[0]]; // Get full month name
  const year = `20${parts[1]}`; // Add '20' prefix to the year
  return `${month} ${year}`;
}

//Weekly Logic
export function getWeekOfMonth(date) {
  function getOrdinalSuffix(number) {
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder = number % 100;
    return (
      suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0]
    );
  }
  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const dayOfWeek = startOfMonth.getDay();
  const weekOfMonth = Math.ceil((date.getDate() + dayOfWeek) / 7);

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const month = months[date.getMonth()]; // Get month in short form

  return `${weekOfMonth}${getOrdinalSuffix(weekOfMonth)} ${month}`;
}

export function convertWeekFlagToDateRange(flag, year) {
  const monthsMap = {
    JAN: 0,
    FEB: 1,
    MAR: 2,
    APR: 3,
    MAY: 4,
    JUN: 5,
    JUL: 6,
    AUG: 7,
    SEP: 8,
    OCT: 9,
    NOV: 10,
    DEC: 11,
  };

  const [weekString, monthAbbr] = flag.split(" ");
  const week = parseInt(weekString);
  const month = monthsMap[monthAbbr.toUpperCase()];

  const startOfMonth = new Date(year, month, 1);
  const dayOfWeek = startOfMonth.getDay();
  const startDate = new Date(year, month, (week - 1) * 7 - dayOfWeek + 1);
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  const today = new Date(); // Get the current date
  if (endDate > today) {
    endDate.setTime(today.getTime()); // Limit end date to today if it's in the future
  }

  const formatOptions = { day: "numeric", month: "short" };
  const formattedStart = startDate.toLocaleDateString("en-US", formatOptions);
  const formattedEnd = endDate.toLocaleDateString("en-US", formatOptions);

  return `${formattedStart} - ${formattedEnd}`;
}

//Last 7 days Logic
export function getLast7DaysFlag(date) {
  const endDate = new Date(date);
  const startDate = new Date(date);
  startDate.setDate(date.getDate() - 6);

  const formatOptions = { day: "numeric" };
  const endDay = endDate.toLocaleDateString("en-US", formatOptions);
  const startDay = startDate.toLocaleDateString("en-US", formatOptions);

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const startMonth = months[startDate.getMonth()];
  const endMonth = months[endDate.getMonth()];

  if (startMonth === endMonth) {
    return `${startDay} ${startMonth} - ${endDay} ${startMonth}`;
  } else if (startDate < endDate) {
    return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
  }
}

export function convertLast7DaysFlagToDateRange(flag, year) {
  console.log(flag.split(" "), year);

  const monthsMap = {
    JAN: 0,
    FEB: 1,
    MAR: 2,
    APR: 3,
    MAY: 4,
    JUN: 5,
    JUL: 6,
    AUG: 7,
    SEP: 8,
    OCT: 9,
    NOV: 10,
    DEC: 11,
  };

  const [startDay, startMonthVerb, endDay, endMonthVerb] = flag
    .split(" ")
    .filter((part) => part !== "-");

  const startMonth = monthsMap[startMonthVerb.toUpperCase()];
  const endMonth = monthsMap[endMonthVerb.toUpperCase()];

  // Create Date objects for start and end dates
  const startDate = new Date(year, startMonth, startDay);
  let endDate = new Date(year, endMonth, endDay);
  console.log(startDate, endDate);

  const formatOptions = { day: "numeric", month: "short" };
  const formattedStart = startDate.toLocaleDateString("en-US", formatOptions);
  const formattedEnd = endDate.toLocaleDateString("en-US", formatOptions);

  return `${formattedStart} - ${formattedEnd}`;
}

//yearly
export function getYearFlag(date) {
  const year = date.getFullYear().toString(); // Get the full year as a string
  return year; // Return the year as the flag
}

export function setYearlyRange(Yref) {
  const year = parseInt(Yref, 10); // Parse the year from Yref
  const currentDate = new Date(); // Get the current date

  // Set "from date" as the start of the year (January 1st)
  const from = new Date(year, 0, 1); // Month 0 is January

  // Set "end date" as December 31st of that year
  const endOfYear = new Date(year, 11, 31); // Month 11 is December

  // If current date is before the end of the year, set "to date" as current date
  const to = currentDate < endOfYear ? currentDate : endOfYear;

  // Return the fromDate and toDate as an object
  return {
    from,
    to,
  };
}

//Cumulative yearly Logic
export function getCumYearFlag(date) {
  const toYear = date.getFullYear().toString();
  // const fromYear = date.getFullYear() - 1;
  const fromYear = date.getFullYear();
  return `${fromYear.toString()}-${toYear}`;
}

export function setCumYearlyRange(Yref) {
  const [fromYear, toYear] = Yref.split("-");
  const currentDate = new Date();
  const parsedFromYear = parseInt(fromYear, 10);
  const parsedToYear = parseInt(toYear, 10);
  const fromDate = new Date(parsedFromYear, 0, 1);
  let toDate = new Date(parsedToYear, 11, 31);
  if (currentDate < toDate) {
    toDate = currentDate;
  }

  return {
    fromC: fromDate,
    toC: toDate,
  };
}

//Adjust Date Range Logic
export function adjustDateRange(range, dateFlag, direction) {
  const today = new Date();

  // Helper function to format date as "dd MMM"
  const formatDate = (date) =>
    date.toLocaleDateString("en-US", { day: "numeric", month: "short" });

  // Helper function to parse "dd MMM" format into a Date object
  const parseDate = (day, monthAbbr) => {
    const monthMap = {
      JAN: 0,
      FEB: 1,
      MAR: 2,
      APR: 3,
      MAY: 4,
      JUN: 5,
      JUL: 6,
      AUG: 7,
      SEP: 8,
      OCT: 9,
      NOV: 10,
      DEC: 11,
    };
    const month = monthMap[monthAbbr.toUpperCase()];
    return new Date(today.getFullYear(), month, parseInt(day));
  };

  // Helper function to adjust the date range based on the direction

  //Weekly Logic
  const adjustWeeklyFlag = (flag, direction) => {
    const [weekDay, monthAbbr] = flag.split(" ");
    const week = parseInt(weekDay);
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const currentMonthIndex = months.indexOf(monthAbbr);
    const currentYear = today.getFullYear();
    if (direction === "left") {
      let newWeek = week - 1;
      let newMonthIndex = currentMonthIndex;

      if (newWeek === 0) {
        newMonthIndex = currentMonthIndex === 0 ? 11 : currentMonthIndex - 1;
        const daysInPrevMonth = new Date(
          currentYear,
          newMonthIndex + 1,
          0
        ).getDate();
        newWeek = Math.ceil(daysInPrevMonth / 7);
      }

      const newMonthAbbr = months[newMonthIndex];
      return `${newWeek} ${newMonthAbbr}`;
    } else if (direction === "right") {
      let newWeek = week + 1;
      let newMonthIndex = currentMonthIndex;

      const daysInCurrentMonth = new Date(
        currentYear,
        currentMonthIndex + 1,
        0
      ).getDate();
      const maxWeeksInCurrentMonth = Math.ceil(daysInCurrentMonth / 7);

      if (newWeek > maxWeeksInCurrentMonth) {
        newWeek = 1;
        newMonthIndex = currentMonthIndex === 11 ? 0 : currentMonthIndex + 1;
      }

      const newMonthAbbr = months[newMonthIndex];
      return `${newWeek} ${newMonthAbbr}`;
    }
  };

  //Monthly Logic
  const adjustMonthlyFlag = (flag, direction) => {
    const [monthAbbr, year] = flag.split("-");
    const monthMap = {
      JAN: 0,
      FEB: 1,
      MAR: 2,
      APR: 3,
      MAY: 4,
      JUN: 5,
      JUL: 6,
      AUG: 7,
      SEP: 8,
      OCT: 9,
      NOV: 10,
      DEC: 11,
    };
    const IndexToMonth = {
      0: "JAN",
      1: "FEB",
      2: "MAR",
      3: "APR",
      4: "MAY",
      5: "JUN",
      6: "JUL",
      7: "AUG",
      8: "SEP",
      9: "OCT",
      10: "NOV",
      11: "DEC",
    };
    const month = monthMap[monthAbbr];

    if (direction === "left") {
      if (month === 11) {
        return `${IndexToMonth[0]}-${parseInt(year) - 1}`;
      } else {
        return `${IndexToMonth[month - 1]}-${year}`;
      }
    } else if (direction === "right") {
      if (month === 0) {
        return `${IndexToMonth[11]}-${parseInt(year) + 1}`;
      } else {
        return `${IndexToMonth[month + 1]}-${year}`;
      }
    }
  };

  //Last 7 Days Logic
  const adjustLast7DaysFlag = (flag, direction) => {
    const [startDayStr, startMonthVerb, endDayStr, endMonthVerb] = flag
      .split(" ")
      .filter((part) => part !== "-");
    console.log(
      "adjustLast7DaysFlag",
      startDayStr,
      startMonthVerb,
      endDayStr,
      endMonthVerb
    );

    const startDay = parseDate(startDayStr, startMonthVerb);
    const endDay = parseDate(endDayStr, endMonthVerb);

    if (direction === "left") {
      startDay.setDate(startDay.getDate() - 7);
      endDay.setDate(endDay.getDate() - 7);
    } else if (direction === "right") {
      startDay.setDate(startDay.getDate() + 7);
      endDay.setDate(endDay.getDate() + 7);
      if (endDay > today) {
        endDay.setTime(today.getTime()); // Ensure end date does not exceed today
      }
    }
    const result = `${formatDate(startDay)} - ${formatDate(endDay)}`;
    console.log(convertDateRange(result));

    return convertDateRange(result);
  };

  //Yearly Logic
  const adjustYearlyFlag = (flag, direction) => {
    let year = +flag;
    if (direction === "left") {
      year = year - 1;
    } else if (direction === "right") {
      year = year + 1;
    }
    return `${year}`;
  };

  //Cummlative year Logic
  const adjustCumulativeYearFlag = (flag, direction) => {
    let [fromYear, toYear] = flag.split("-");
    let fromYearInt = parseInt(fromYear, 10);
    let toYearInt = parseInt(toYear, 10);
    if (direction === "left") {
      fromYearInt = fromYearInt - 1;
      toYearInt = toYearInt - 1;
    } else if (direction === "right") {
      fromYearInt = fromYearInt + 1;
      toYearInt = toYearInt + 1;
    }
    return `${fromYearInt}-${toYearInt}`;
  };

  //Switch Logic
  switch (range) {
    case "Weekly":
      return adjustWeeklyFlag(dateFlag, direction);

    case "This Month":
      return adjustMonthlyFlag(dateFlag, direction);

    case "Last 7 days":
      return adjustLast7DaysFlag(dateFlag, direction);

    case "Monthly":
      return adjustYearlyFlag(dateFlag, direction);

    case "Yearly":
      return adjustCumulativeYearFlag(dateFlag, direction);
    default:
      return null;
  }
}

function convertDateRange(input) {
  // Extract dates and month from the input string
  const [startMonth, startDate, _, endMonth, endDate] = input.split(" ");

  // Return the formatted string
  return `${startDate} ${startMonth} - ${endDate} ${endMonth}`;
}
