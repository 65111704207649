import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  Tooltip,
  Stack,
  Table,
  TableCell,
  CardContent,
  Backdrop,
  Card,
  Fade,
  Modal,
  Autocomplete,
  TextField,
} from "@mui/material";
import {Ftpget} from "../../../Api/FTPAPI"
import { CheckForLight } from "../../util/common/CommanFunction";
import CustomBreadcrumbs from "../../util/components/CustomBread";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function Ftpgetfile() {
  const initialState = {
    hostName: "",
    port: "",
    userName: "",
    passWord: "",
    directory: "",
    localdirectory: "",
  };
  const [stdField, setstdField] = useState(initialState);
  const [editcall, seteditcall] = useState([]);
  const [replace, setReplace] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDatee, setSelectedDatee] = useState(null);
  const paths = [
    { label: "Home", path: "/menu" },
    { label: "FTP Getfile", path: "ftppget" },
  ];
  /*------------------------- handle save --------------------------------------------*/
  const handleSave = async (e) => {
    const addDays = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }
    const fromDate = selectedDate
      ? addDays(selectedDate, 1).toISOString().substring(0, 10)
      : "";
   const  ToDate = selectedDatee
      ? addDays(selectedDatee, 1).toISOString().substring(0, 10)
      : "";
    let data = [
      {
        server: stdField?.hostName,
        port: stdField?.port,
        user: stdField?.userName,
        pass: stdField?.passWord,
        remoteDir: stdField?.directory,
        localDir: stdField?.localdirectory,
        fromDate: fromDate,
        toDate: ToDate,
      },
    ];

    console.log(data);
    try {
        const responseData = await Ftpget(data);
    const url = window.URL.createObjectURL(new Blob([responseData .data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${data.hostName}.Ftp`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
      } catch (error) {
        console.error('Error:', error);
      }
   
  };

  const handleUpdate = async (values) => {};

  const handleTxtChange = (event, name) => {
    setstdField({ ...stdField, [name]: event.target.value });
  };

  const handleDateChange = (date) => {
    const selectedDate = new Date(date);
    selectedDate.setDate(selectedDate.getDate(), +1); // Example modification
    setSelectedDate(selectedDate);
  };

  const handleDatepicChange = (date) => {
    const selectedDatee = new Date(date);
    selectedDatee.setDate(selectedDatee.getDate(), +1); // Example modification
    setSelectedDatee(selectedDatee);
  };
  return (
    <div>
      <div>
        <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Paper
            elevation={0}
            style={{
              width: "100%",
              borderRadius: "2px 2px 0px 0px",
              userSelect: "none",
              height: "5vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid
              container
              width={"100%"}
              direction="row"
              justifyContent="space-between"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                xs={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <CustomBreadcrumbs
                  paths={paths || []}
                  separatorSize="18px"
                  fontSize="14px"
                />
              </Grid>

              <Grid
                item
                xs="auto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                <div>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "25px",
                      textTransform: "capitalize",
                    }}
                    onClick={handleSave}
                    disabled={
                        !(
                            stdField?.hostName &&
                            stdField?.port &&
                            stdField?.userName &&
                            stdField?.passWord &&
                            stdField?.directory &&
                            stdField?.localdirectory&&
                            selectedDatee&&
                            selectedDate


                        
                        )
                      }
                  >
                    Get File
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </div>
      <div style={{ marginTop: "1%" }}>
        <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Paper
            elevation={0}
            style={{
              width: "100%",
              borderRadius: "2px 2px 0px 0px",
              userSelect: "none",
              height: "20vh",
            }}
          >
            <div style={{ padding: "20px" }}>
              <Grid container spacing={2} columns={16}>
                <Grid item xs={4}>
                  <Typography className="modal-typo">Host Name</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <TextField
                      name="assign_remarks"
                      id="outlined-basic"
                      size="small"
                      placeholder="Remark"
                      sx={{ width: "14vw" }}
                      variant="outlined"
                      onChange={(e) => {
                        handleTxtChange(e, "hostName");
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <Typography className="modal-typo">Port</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <TextField
                      name="assign_remarks"
                      id="outlined-basic"
                      size="small"
                      placeholder="Remark"
                      sx={{ width: "14vw" }}
                      variant="outlined"
                      onChange={(e) => {
                        handleTxtChange(e, "port");
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="modal-typo">User Name</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <TextField
                      name="assign_remarks"
                      id="outlined-basic"
                      size="small"
                      placeholder="Remark"
                      sx={{ width: "14vw" }}
                      variant="outlined"
                      onChange={(e) => {
                        handleTxtChange(e, "userName");
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="modal-typo">PassWord</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <TextField
                      name="assign_remarks"
                      id="outlined-basic"
                      size="small"
                      placeholder="Remark"
                      sx={{ width: "14vw" }}
                      variant="outlined"
                      onChange={(e) => {
                        handleTxtChange(e, "passWord");
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="modal-typo">Directory</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <TextField
                      name="assign_remarks"
                      id="outlined-basic"
                      size="small"
                      placeholder="Remark"
                      sx={{ width: "14vw" }}
                      variant="outlined"
                      onChange={(e) => {
                        handleTxtChange(e, "directory");
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="modal-typo">
                    Local Directory
                  </Typography>
                  <div style={{ marginTop: "1%" }}>
                    <TextField
                      name="assign_remarks"
                      id="outlined-basic"
                      size="small"
                      placeholder="Remark"
                      sx={{ width: "14vw" }}
                      variant="outlined"
                      onChange={(e) => {
                        handleTxtChange(e, "localdirectory");
                      }}
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <Typography className="modal-typo">From Date</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        size="small"
                        sx={{
                          width: "14vw",
                        }}
                        onChange={handleDateChange}
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Typography className="modal-typo">To Date</Typography>
                  <div style={{ marginTop: "1%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        size="small"
                        sx={{
                          width: "14vw",
                        }}
                        onChange={handleDatepicChange}
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Box>
      </div>
    </div>
  );
}
