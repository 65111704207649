import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  InputAdornment,
  Modal,
  MenuItem,
  Menu,
  Stack,
  Autocomplete,
  Tooltip,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TableBody,
  Card,
  Fade,
  CardHeader,
  CardContent,
  CircularProgress,
  Divider,
  Table,
} from "@mui/material";
import { useNavigate } from "react-router";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { addDays } from "date-fns";
/*------------api call-----------------*/
import { fetchTicketTable } from "../../Api/TicketApi";
import TableTemplate from "../../Template/TableTemplate";
import Download from "../../Template/Excel/Download";
import { ticketConfigField } from "../util/TextField";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ticketSchema } from "../util/ValidateSchema";
import replaceObjectById from "../util/StateUpdater";
import { CheckForLight } from "../util/common/CommanFunction";
import { useLocation } from "react-router";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import errorMsg from "../util/errorMessage.json";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import CustomSnackbar from "../util/components/CustomSnackbar";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  EquipmentlistDropdown,
  CategorylistDropdown,
  EquipmentTypelistDropdown,
  postticketdata,
  AssignlistDropdown,
  putTicketexcelupdate,
  putTicketupdateEdit,
  fetchSiteDetails,
  fetchSiteTicketTable,
  postticketexceldata,
  TicketUpdate,
  putBulkTicketclose,
  fetchTicketCategory,
  fetchTicketState,
  postTicketFilterTable,
  postTicketbarchart,
  postTicketpiechart,
  fetchDownloadDetails,
  fetchAssignDetail,
} from "../../Api/TicketApi";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Tickteview } from "../util/TextField";

import { FadeLoader } from "react-spinners";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { removeQuestionMark } from "../util/common/CommanFunction";
import { decryptPaddedQuery } from "../util/security/Cipher";
import { he } from "date-fns/locale";
import slotShouldForwardProp from "@mui/material/styles/slotShouldForwardProp";

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

export default function Ticket({ ticketConfiguration }) {
  /*------------state-----------------*/

  /*------------ modal state-----------------*/
  const navigate = useNavigate();
  const [openn, setOpenn] = useState(false);
  const [openfilter, setopenfilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [assignData, setassignData] = useState();
  const [assignDatas, setassignDatas] = useState(false);
  const [fromtime, setFromTime] = useState("00:00");
  const [siteIdName, setSiteId] = useState([]);
  const [state, setstate] = useState([]);
  const [Sitecategory, setSitecategory] = useState([]);
  const [SiteReport, setSiteReport] = useState([]);
  const [editcall, seteditcall] = useState([]);
  const [nextvalue, Setnextvalue] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [text, setTextField] = useState([]);
  const [textt, setTextFieldd] = useState([]);
  const [edit, setEdit] = useState(false);
  const [siteTab, setSiteTab] = useState("");
  const [EquipmetId, setEquipmentId] = useState([]);
  const [categoryvalue, setCategoryvalue] = useState([]);
  const [equipmevalue, setEquipmevalue] = useState([]);
  const [checkbox, Setcheckbox] = useState([]);
  const [toBackend, setToBackend] = useState(false);
  const [value, setvalue] = useState([]);
  const [Assign, setAssignevalue] = useState([]);
  const [ticketstatus, setAssignuser] = useState([]);
  const [opn, setopn] = useState(false);
  const initialSnack = { open: false, severity: "error", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [fieldValue, setFieldValue] = useState();
  const [editvalue, seteditvalue] = useState(false);
  const [Ticketview, setTicketview] = useState(false);
  const [filterdata, setfilterData] = useState();
  const [filterBardata, setfilterBardata] = useState();
  const [filterTable, setfilterTable] = useState([]);
  const [statuscall, setstatuscall] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [indexvalue, setindexvalue] = useState("");
  const [submiterror, setSubmiterror] = useState(false);
  console.log(indexvalue.length);
  console.log(indexvalue);
  const [ope, setope] = useState(false);
  const [opeee, setopeee] = useState(false);
  const [editval, seteditval] = useState(false);
  const [AssignData, setAssignData] = useState();
  const [excelData, setExcelData] = useState(null);
  const [todate, setTodate] = useState();
  const [fromDate, setFromDate] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [down, setdown] = useState(false);
  const [editValue, setEditvalue] = useState(null);
  const [closeticket, setcloseticket] = useState(false);
  const [Valuedata, setValuedata] = useState();
  const location = useLocation();
  const encryptedQuery = removeQuestionMark(location.search);
  const queryParam = decryptPaddedQuery(encryptedQuery);
  const queryParams = new URLSearchParams(`?${queryParam}`);
  const params = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  console.log(fromtime);
  const dayjs = require("dayjs");
  const isFromSiteList = Object.keys(params).length > 0;
  const earliestFromTime = dayjs().startOf("day").hour(5);
  const paths = isFromSiteList
    ? [
        { label: "Home", path: "/menu" },
        { label: "Site List", path: -1 },
        { label: "Ticket Configuration", path: "ticket" },
      ]
    : [
        { label: "Home", path: "/menu" },
        { label: "Ticket Configuration", path: "ticket" },
      ];

  let SiteId = params.id;

  const initialState = {
    stateName: "",
    description: "",
    siteId: "",
    remarks: "",
    equipmentId: "",
    siteName: "",
    equipmentType: "",
    createdBy: "",
    categoryName: "",
    closedTimestamp: "",
    subject: "",
    ticketCategoryId: "",
    startedTimestamp: "",
    ticketTypeId: "",
    statusName: "",
    ticketTypeName: "",
    scheduleOn: "",
    assignedTo: "",
    ticketStateId: "",
    ticketCode: "",
    ticketStatusId: "",
    ticketId: "",
    priority: "",
    categoryName: "",
    ticketstate: "",
    ticketcategory: "",
    todate: "",
    fromdate: "",
    assignedTo: [],
    ticketStatus: "",
  };
  const [ticketFields, setTicketFields] = useState(initialState);
  console.log(ticketFields);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "15ch",
      },
    },
  };
  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    getSiteCall();
    fetchEquipmenttype();
    fetchstateCall();
    fetchCategoryCall();
    setLoading(false);
    fetchCategoryCall();
    getDownload();
    fetchAssignDetails();

    getDataTable(params.id);
  }, [params.id]);
  const handleSave = async (value) => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === ticketFields.siteName;
    });

    let equipmentnameIdFilter = EquipmetId?.filter((data) => {
      return data.displayName === ticketFields.equipmentName;
    });

    let equipmenttypeIdFilter = equipmevalue?.filter((data) => {
      return data.ticketTypeName === ticketFields.equipmentType;
    });

    let categoryIdFilter = categoryvalue?.filter((data) => {
      return data.categoryName === ticketFields?.equipmentCategory;
    });

    let siteIds = [];

    if (ticketFields.equipmentType === "Maintenance") {
      ticketFields.equipementArray.forEach((name) => {
        let equipmentnameIdFilter = EquipmetId.filter((data) => {
          if (data.displayName === name) {
            siteIds.push(data.equipmentId);
          }
        });
      });
    }
    let data = {
      siteId: isFromSiteList ? params.id : siteIdFilter[0]?.siteId,
      equipmentId:
        ticketFields.equipmentType !== "Maintenance"
          ? [equipmentnameIdFilter[0]?.equipmentId]
          : siteIds,
      ticketTypeId: equipmenttypeIdFilter[0]?.ticketTypeId,
      ticketCategory: categoryIdFilter[0]?.categoryId,
      subject: value?.subject,
      priority:
        ticketFields.priority === "Medium"
          ? 2
          : ticketFields.priority === "High"
          ? 3
          : 1,
      description: value?.description,
      createdBy: Number(sessionStorage.getItem("id")),
    };

    try {
      setToBackend(true);
      const responseData = await postticketdata(data);

      if (responseData.status === 200) {
        fetchData();
        setSnack({
          ...snack,
          open: true,
          severity: "success",
          message: responseData.data.statusDescription,
        });
        handleClose();
        setToBackend(false);
        setOpen(false);
        return 1;
      }
    } catch (e) {
      setSnack(errorMsg.failure);
      console.error("Error:", e.response.status);
      setToBackend(false);
      return 0;
    }
  };
  /*------------------- Ticket Update Api--------------*/

  const handleUpdateForm = async (value) => {
    let id = value.ticketId;
    let object = {
      description: value.description,
      priority:
        value.priority === "High"
          ? 3
          : value.priority === "Medium"
          ? 2
          : value.priority === "Low"
          ? 1
          : null,
      subject: value.subject,
    };

    try {
      let responseData = await TicketUpdate(id, object);
      if (responseData.status === 200) {
        setOpen(false);
        fetchData();
        setSnack(errorMsg.success);
      }
    } catch (err) {
      console.error(err.message);
    }
  };
  /*------------------- Ticket Assign Api--------------*/
  const handleUpdate = async (value) => {
    let id = editcall?.ticketId;
    let assignIdFilter = Assign?.filter((data) => {
      return data.userName === assignData;
    });
    let data = {
      assignedTo: assignIdFilter[0]?.userId,
      scheduleOn: selectedDate
        ? selectedDate.toISOString().substring(0, 10) + " " + fromtime
        : "",
      remarks: fieldValue?.remarks,
    };

    try {
      setToBackend(true);
      let responsedata = await putTicketupdateEdit(data, id);
      if (responsedata.status == 200) {
        const updatedTable = replaceObjectById(
          SiteReport,
          "ticketId",
          id,
          responsedata.data
        );
        fetchData();
        setassignData();
        setSelectedDate();
        setFromTime("00:00");
        setSnack(errorMsg.success);
        handleClose();
        setToBackend(false);
        setFieldValue();
        setOpenn(false);
        return 1;
      }
    } catch (error) {
      setSnack(errorMsg.failure);
      console.error("Error:", error.response.status);
      setToBackend(false);
      return 0;
    }
  };
  console.log(ticketFields);

  /*------------------- Ticket Filter Api--------------*/
  const handleFilterTable = async (value) => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === ticketFields.siteName;
    });
    let statusIdFilter = ticketstatus?.filter((data) => {
      return data.statusName === ticketFields.ticketStatus;
    });
    console.log(statusIdFilter);

    const filteredAssign = Assign.filter((assignedTo) =>
      ticketFields.assignedTo.includes(assignedTo.userName)
    );

    const filteredAssignIds = filteredAssign.map(
      (assignedTo) => assignedTo.userId
    );

    console.log(filteredAssignIds);
    console.log(filteredAssign);

    let ticketcategory = Sitecategory?.filter((data) => {
      return data.categoryName === ticketFields.ticketcategory;
    });
    let ticketstate = state?.filter((data) => {
      return data.stateName === ticketFields.ticketstate;
    });

    function addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + 1);
      return result;
    }
    let data = {
      siteId: siteIdFilter[0]?.siteId,
      toDate: todate ? addDays(todate, 1).toISOString().substring(0, 10) : null,

      fromDate: fromDate
        ? addDays(fromDate, 1).toISOString().substring(0, 10)
        : null,
      ticketStateId: ticketstate[0]?.ticketStateId,
      categoryId: ticketcategory[0]?.categoryId,
      ticketStatusId: statusIdFilter[0]?.ticketStatusId,
      assignedTo: filteredAssignIds,
      priority:
        ticketFields.priority === "Medium"
          ? 2
          : ticketFields.priority === "High"
          ? 3
          : ticketFields.priority === "Low"
          ? 1
          : "null",
    };
    try {
      setToBackend(true);
      const responseData = await postTicketFilterTable(data);
      const responsebarchart = await postTicketbarchart(data);
      const responsepiechart = await postTicketpiechart(data);
      const updateAssignedBy = (item) => {
        if (item.assignedBy === null) {
          item.assignedBy = "Assign";
        }
        if (Array.isArray(item.ticketTransactions)) {
          item.ticketTransactions.forEach((transaction) => {
            if (transaction.assignedBy === null) {
              transaction.assignedBy = "assign";
            }
          });
        }
        return item;
      };

      if (Array.isArray(responseData)) {
        const updatedData = responseData.map(updateAssignedBy);
        setfilterTable(updatedData);
      } else if (typeof responseData === "object" && responseData !== null) {
        const updatedData = updateAssignedBy(responseData);
        setfilterTable([updatedData]);
      }
      setfilterData(responsepiechart);
      setfilterBardata(responsebarchart);
      setfilterTable(responseData);
      fetchData();
      setToBackend(false);
      handlefilterClose();
      setTicketview(true);
      setopenfilter(false);
      return 1;
    } catch (e) {
      setSnack(errorMsg.failure);
      setToBackend(false);
      return 0;
    }
  };
  const fetchAssignDetails = async () => {
    try {
      const data = await fetchAssignDetail();
      setAssignuser(data);
    } catch {}
  };
  /*------------------- Ticket Table Api--------------*/
  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchTicketTable();

      const updateAssignedBy = (item) => {
        if (item.assignedBy === null) {
          item.assignedBy = "Assign";
        }
        if (Array.isArray(item.ticketTransactions)) {
          item.ticketTransactions.forEach((transaction) => {
            if (transaction.assignedBy === null) {
              transaction.assignedBy = "assign";
            }
          });
        }
        return item;
      };

      if (Array.isArray(data)) {
        const updatedData = data.map(updateAssignedBy);
        setSiteReport(updatedData);
      } else if (typeof data === "object" && data !== null) {
        const updatedData = updateAssignedBy(data);
        setSiteReport([updatedData]);
      }
      setValuedata();
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (isFromSiteList === true) {
      fetchDataTable();
    } else {
      fetchData();
    }
  }, [SiteId, Ticketview]);
  const fetchDataTable = async () => {
    try {
      const data = await fetchSiteTicketTable(params.id);
      const updateAssignedBy = (item) => {
        if (item.assignedBy === null) {
          item.assignedBy = "Assign";
        }
        if (Array.isArray(item.ticketTransactions)) {
          item.ticketTransactions.forEach((transaction) => {
            if (transaction.assignedBy === null) {
            }
          });
        }
        return item;
      };
      if (Array.isArray(data)) {
        const updatedData = data.map(updateAssignedBy);
        setSiteReport(updatedData);
      } else if (typeof data === "object" && data !== null) {
        const updatedData = updateAssignedBy(data);
        setSiteReport([updatedData]);
      }
      setValuedata();
    } catch (e) {
      console.error(e);
    }
  };
  const getDataTable = async (id) => {
    try {
      const data = await fetchSiteTicketTable(id);
      const updateAssignedBy = (item) => {
        if (item.assignedBy === null) {
          item.assignedBy = "Assign";
        }
        if (Array.isArray(item.ticketTransactions)) {
          item.ticketTransactions.forEach((transaction) => {
            if (transaction.assignedBy === null) {
              transaction.assignedBy = "assign";
            }
          });
        }
        return item;
      };

      if (Array.isArray(data)) {
        const updatedData = data.map(updateAssignedBy);

        setSiteReport(updatedData);
      } else if (typeof data === "object" && data !== null) {
        const updatedData = updateAssignedBy(data);

        setSiteReport([updatedData]);
      }
      setstatuscall(true);
    } catch (e) {
      console.error(e);
    }
  };
  /*------------------- Ticket Dropdown Api--------------*/

  const getSiteCall = async (id) => {
    try {
      let data = await fetchSiteDetails(id);
      setSiteId(data);
    } catch (e) {
      console.error(e);
    }
  };
  const getDownload = async (id) => {
    try {
      let data = await fetchSiteDetails(id);
      setSiteId(data);
    } catch (e) {
      console.error(e);
    }
  };

  const getEquipmentcategory = async (Id) => {
    try {
      let data = await CategorylistDropdown(Id);
      setCategoryvalue(data);
    } catch (e) {
      console.error(e);
    }
  };
  const handleassignvalue = async (value) => {
    console.log(value);
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === value;
    });
    let siteId = siteIdFilter[0]?.siteId;
    try {
      let data = await AssignlistDropdown(siteId);
      setAssignevalue(data);
    } catch (e) {
      console.error(e);
    }
  };
  const handleAssigntype = async () => {
    let Id = editcall?.siteId;
    console.log(Id);

    try {
      let data = await AssignlistDropdown(Id);
      setAssignevalue(data);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchstateCall = async (id) => {
    try {
      let data = await fetchTicketState(id);
      setstate(data);
    } catch (e) {
      console.error(e);
    }
  };
  const fetchEquipmenttype = async () => {
    try {
      let data = await EquipmentTypelistDropdown();
      setEquipmevalue(data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchCategoryCall = async (id) => {
    try {
      let data = await fetchTicketCategory(id);
      setSitecategory(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (ticketFields.siteName !== "") {
      let SitenameID = siteIdName?.filter((data) => {
        return data.siteName === ticketFields?.siteName;
      });

      getEquipmentCall(SitenameID[0]?.siteId);
    }
  }, [ticketFields.siteName]);

  const getEquipmentCall = async (Id) => {
    try {
      let data = await EquipmentlistDropdown(Id);
      setEquipmentId(data);
    } catch (e) {
      console.error(e);
    }
  };

  /*------------------- Ticket Excel  Api--------------*/
  const handleExcelUpload = async () => {
    setToBackend(true);
    try {
      const rowHeaders = excelData[0].map((header) =>
        header.trim().replace(/\s+/g, "")
      );
      const indices = {
        siteNameIndex: rowHeaders.indexOf("SiteName"),
        equipmentNameIndex: rowHeaders.indexOf("EquipmentName"),
        ticketTypeIndex: rowHeaders.indexOf("TicketType"),
        ticketCategoryIndex: rowHeaders.indexOf("TicketCategory"),
        subjectIndex: rowHeaders.indexOf("Subject"),
        priorityIndex: rowHeaders.indexOf("Priority"),
        descriptionIndex: rowHeaders.indexOf("Description"),
        scheduleOnIndex: rowHeaders.indexOf("ScheduleOn"),
        remarkIndex: rowHeaders.indexOf("Remarks"),
        assignToIndex: rowHeaders.indexOf("AssignedTo"),
        TimeSlotToIndex: rowHeaders.indexOf("TimeSlot"),
      };

      const data = excelData.slice(1).map((row) => {
        const trimmedRow = row.map((cell) =>
          typeof cell === "string" ? cell.trim() : cell
        );
        const time = trimmedRow[indices.TimeSlotToIndex] === "0" 
        ? "00:00" 
        : trimmedRow[indices.TimeSlotToIndex]?.toString().padStart(4, "0").replace(/(\d{2})(\d{2})/, "$1:$2");
    
    return {
        siteName: trimmedRow[indices.siteNameIndex],
        createdBy: sessionStorage.getItem("id"),
        equipmentName: trimmedRow[indices.equipmentNameIndex],
        ticketTypeName: trimmedRow[indices.ticketTypeIndex],
        ticketCategoryName: trimmedRow[indices.ticketCategoryIndex],
        subject: trimmedRow[indices.subjectIndex],
        priority:
            trimmedRow[indices.priorityIndex].toString().toLowerCase() === "high"
                ? 3
                : trimmedRow[indices.priorityIndex].toString().toLowerCase() === "medium"
                ? 2
                : 1,
        description: trimmedRow[indices.descriptionIndex],
        firstName: trimmedRow[indices.assignToIndex]?.toString(),
        scheduleOn: trimmedRow[indices.scheduleOnIndex]?.toString() + " " + time,
        remarks: trimmedRow[indices.remarkIndex].toString(),
    };
      });

      const response = await postticketexceldata(data);
      if (response.status === 200) {
        setopeee(false);
        fetchData();
        setSnack(errorMsg.success);
        setToBackend(false);
      }
    } catch (error) {
      console.error(error);
      if (error.response?.status === 400) {
        const errorResponse = error.response.data;
        const indexValuess = Object.keys(errorResponse).map(
          (key) => errorResponse[key].error
        );
        setErrorMessage(indexValuess);
        const indexValues = Object.keys(errorResponse).map(
          (key) => errorResponse[key].index
        );
        setindexvalue(indexValues);
      } else if (error.response?.status === 500) {
        setSnack(errorMsg.failure);
      } else {
        console.error(error);
      }
    }
  };

  /*------------------- Ticket Excel download--------------*/

  const handleDownloadticket = () => {
    setdown(false);

    const ticketData = [
      {
        SiteName: "",
        EquipmentName: "",
        TicketType: "",
        TicketCategory: "",
        Subject: "",
        Description: "",
        Priority: "",
      },
    ];

    const downloadExcel = () => {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(ticketData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Tickets");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const excelData = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      const excelUrl = URL.createObjectURL(excelData);
      const link = document.createElement("a");
      link.href = excelUrl;
      link.download = "Tickets.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(excelUrl);
    };
    downloadExcel();
  };

  const handleFileInputChange = (event) => {
    setdown(false);
    seteditval(true);
    const file = event.target.files[0];
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const headerRow = jsonData[0];
      const requiredHeaders = [
        "SiteName",
        "EquipmentName",
        "TicketType",
        "TicketCategory",
        "Subject",
        "Description",
        "Priority",
        "AssignedTo",
        "ScheduleOn",
        "TimeSlot",
        "Remarks",
      ];
  
      const missingHeaders = requiredHeaders.filter(
        (header) => !headerRow.includes(header)
      );
      if (missingHeaders.length > 0) {
        setopeee(false);
        setSnack((prev) => ({
          open: true,
          severity: "error",
          message: '"Enter the correct format"',
        }));
        return;
      }
  
      const maxColumns = headerRow.length;
      const formattedData = [];
      const errorRows = [];
      const errorMessages = [];
      const validPriorities = ["Medium", "Low", "High"]; // Valid priority values
  
      jsonData.forEach((row, rowIndex) => {
        if (rowIndex !== 0) {
          let scheduleOnValue = row[8];
          if (!isNaN(scheduleOnValue)) {
            scheduleOnValue = new Date(
              (scheduleOnValue - (25567 + 2)) * 86400 * 1000
            );
            const formattedDate = `${scheduleOnValue.getFullYear()}-${(
              scheduleOnValue.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}-${scheduleOnValue
              .getDate()
              .toString()
              .padStart(2, "0")}`;
            row[8] = formattedDate;
          }
  
          // Format TimeSlot (assuming it's in the 10th column, index 9)
          let timeSlotValue = row[9];
          if (timeSlotValue && !isNaN(timeSlotValue)) {
            const hours = String(Math.floor(timeSlotValue * 24)).padStart(2, "0");
            const minutes = String(Math.round((timeSlotValue * 1440) % 60)).padStart(2, "0");
            row[9] = `${hours}:${minutes}`;
          }
        }
  
        while (row.length < maxColumns) {
          row.push("");
        }
        for (let i = 0; i < row.length; i++) {
          if (row[i] === undefined || row[i] === null) {
            row[i] = "";
          }
        }
        const emptyColumns = [];
        const maxLengthColumns = [];
        const priorityErrors = [];
        row.forEach((cell, colIndex) => {
          if (cell === "") {
            emptyColumns.push(headerRow[colIndex]);
          } else {
            const maxLength = 200;
            if (cell.length > maxLength) {
              maxLengthColumns.push(headerRow[colIndex]);
            }
            if (
              headerRow[colIndex] === "Priority" &&
              !validPriorities.includes(cell)
            ) {
              priorityErrors.push(
                `Priority only accepts values: Medium, Low, or High`
              );
            }
          }
        });
  
        if (
          emptyColumns.length > 0 ||
          maxLengthColumns.length > 0 ||
          priorityErrors.length > 0
        ) {
          errorRows.push(rowIndex - 1);
  
          emptyColumns.forEach((col) => {
            console.log(col);
            
            errorMessages.push(` ${col} is empty`);
          });
  
          maxLengthColumns.forEach((col) => {
            errorMessages.push(` ${col} exceeds maximum length`);
          });
  
          priorityErrors.forEach((msg) => {
            errorMessages.push(msg);
          });
        }
        formattedData.push(row);
      });
      console.log(errorRows);
      if (errorRows.length === 0) {
        setSubmiterror(false);
      } else {
        setSubmiterror(true);
      }
      setopeee(true);
      setExcelData(formattedData);
      setindexvalue(errorRows);
      setErrorMessage(errorMessages);
    };
  
    reader.readAsArrayBuffer(file);
  };
  
  console.log(errorMessage);

  function getOptions(propName) {
    let option = [];
    switch (propName) {
      case "siteName":
        return (option = siteIdName?.map((option) => option.siteName));
      case "equipmentCategory":
        return categoryvalue?.map((option) => option.categoryName);
      case "equipmentType":
        return (option = equipmevalue?.map((option) => option.ticketTypeName));
      case "equipmentName":
        return (option = EquipmetId?.map((option) => option.displayName));
      case "priority":
        return (option = Priority?.map((option) => option.label));

      case "ticketcategory":
        return (option = Sitecategory?.map((option) => option.categoryName));
      case "ticketstate":
        return (option = state?.map((option) => option.stateName));
      case "assignedTo":
        return (option = Assign?.map((option) => option.userName));
      case "ticketStatus":
        return (option = ticketstatus?.map((option) => option.statusName));
      default:
    }
    return option;
  }
  console.log(Assign);

  useEffect(() => {
    let data = Tickteview();
    setTextFieldd(data);
  }, []);

  const handleCrate = () => {
    setOpen(true);
    setEdit(false);
    seteditcall("");
    setTicketFields("");
  };
  const handleClosee = () => {
    setOpenn(false);
    setassignData(null);
    setSelectedDate(null);
  };
  const handlefilter = () => {
    setopenfilter(true);
  };
  const handleDateChange = (date) => {
    const selectedDate = new Date(date);
    selectedDate.setDate(selectedDate.getDate() + 1);
    setSelectedDate(selectedDate);
  };

  const handleaddticket = () => {
    setope(true);
  };
  const handleClosing = () => {
    setdown(false);
  };
  const handleTicketdownload = () => {
    // setdown(true);
    // setope(false);
    // seteditvalue(true);
    const ticketData = [
      {
        SiteName: "",
        EquipmentName: "",
        TicketType: "",
        TicketCategory: "",
        Subject: "",
        Description: "",
        Priority: "",
        AssignedTo: "",
        ScheduleOn: "",
        TimeSlot: "",
        Remarks: "",
      },
    ];

    const downloadExcel = () => {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(ticketData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Tickets");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const excelData = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      const excelUrl = URL.createObjectURL(excelData);
      const link = document.createElement("a");
      link.href = excelUrl;
      link.download = "Tickets.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(excelUrl);
    };
    downloadExcel();
  };
  const handleTicketupload = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept =
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
    fileInput.addEventListener("change", handleFileInputChange);
    fileInput.click(handleUploadticket);
  };

  const handlecancel = () => {
    setopeee(false);
    setExcelData("");
    setErrorMessage("");
    setindexvalue("");
  };

  const handleCloseNext = () => {
    Setnextvalue(true);
  };
  const handleCloseBack = () => {
    // Setnextvalue(false);
    setcloseticket(false);
    window.location.reload();
  };

  const handleTicketCheckbox = (val, data) => {
    Setcheckbox((prevIds) => {
      const updatedIds = [...prevIds];
      const foundIndex = updatedIds.findIndex(
        (item) => item.ticketId === data.ticketId
      );
      if (foundIndex !== -1) {
        updatedIds[foundIndex] = {
          ticketId: data.ticketId,
          isChecked: val,
        };
      } else {
        updatedIds.push({ ticketId: data.ticketId, isChecked: val });
      }

      if (val === true) {
      }
      const trueCheckedTickets = updatedIds.filter((item) => item.isChecked);

      const ticketIds = trueCheckedTickets.map((item) => item.ticketId);

      setvalue(ticketIds);

      return updatedIds;
    });
  };

  const handlecloseUpdate = async () => {
    try {
      let data = {
        ticketIds: value,
        remarks: fieldValue?.remarks,
      };
      let responsedata = await putBulkTicketclose(data);
      if (responsedata.status == 200) {
        fetchData();
        setcloseticket(false);
        setSnack(errorMsg.success);
        handleCloseNext();
        setFieldValue();
      }
    } catch (error) {
      setSnack(errorMsg.failure);
    }
  };

  const handelTicketclose = () => {
    setcloseticket(true);
    Setnextvalue(false);
  };
  const handleCloseticket = () => {
    setcloseticket(false);
    Setcheckbox([]);
  };

  const handleClose = () => {
    setOpen(false);
    setope(false);
    setTicketFields(initialState);
  };
  const handlefilterClose = () => {
    setopenfilter(false);
  };

  const modastyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "auto",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
  };

  const Item = styled(Paper)(({ theme }) => ({}));
  const handleTicket = async (data) => {
    navigate("/menu/view_ticket", {
      state: { id: data.data, isFromSiteList: data.isFromSiteList },
    });
  };

  const styledown = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const headCells = [
    {
      label: "Ticket No",
      id: "ticketCode",
      view: true,
      default: true,
    },
    {
      label: "Site Name",
      id: "siteName",
      view: true,
      default: true,
    },
    {
      label: "Ticket Type",
      id: "ticketTypeName",
      view: true,
      default: false,
    },
    {
      label: "Ticket Category",
      id: "categoryName",
      view: true,
      default: false,
    },
    {
      label: "Equipment Name",
      id: "displayName",
      view: true,
      default: true,
    },

    {
      label: "Priority",
      id: "priority",
      view: true,
      default: false,
    },
    {
      label: "Create By",
      id: "userName",
      view: true,
      default: false,
    },

    {
      label: "Assigned To",
      id: "assignedBy",
      view: true,
      default: true,
    },
    {
      label: "Schedule  On",
      id: "scheduleOn",
      view: true,
      default: true,
    },
    {
      label: "State",
      id: "stateName",
      view: true,
      default: true,
    },
    {
      label: "Status",
      id: "statusName",
      view: true,
      default: true,
    },
    {
      label: "Subject",
      id: "subject",
      view: false,
      default: false,
    },
    {
      label: "Action",
      id: "action",
      view: true,
      default: true,
    },
    // {
    //   label: 'Status',
    //   id: 'statusName',
    //   view: true,
    //   default: false,
    // },
  ];

  const chartValueData = SiteReport.filter((row) => {
    if (siteTab !== "") {
      return row.statusName === siteTab;
    } else {
      return true;
    }
  });

  const tableValues =
    Ticketview === true
      ? filterTable
      : SiteReport.filter((row) => {
          if (siteTab !== "") {
            return row.statusName === siteTab;
          } else {
            return true;
          }
        });
  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case "all":
        setSiteTab("");
        break;
      case "ticketTypeName":
        setSiteTab("Created");
        break;
      case "ticketTypeOperation":
        setSiteTab("Assigned");
        break;
      case "ticketTypeParkedCount":
        setSiteTab("Parked");
        break;
      case "ticketTypeOpenCount":
        setSiteTab("Open");
        break;
      default:
        setSiteTab("");
    }
  };

  const Total = isFromSiteList
    ? SiteReport.filter((site) => site.siteName === params.name).length
    : SiteReport.length;
  const ticketTypeNameCount = isFromSiteList
    ? SiteReport.filter(
        (site) => site.statusName === "Created" && site.siteName === params.name
      ).length
    : SiteReport.filter((site) => site.statusName === "Created").length;

  const ticketTypeOperationCount = isFromSiteList
    ? SiteReport.filter(
        (site) =>
          site.statusName === "Assigned" && site.siteName === params.name
      ).length
    : SiteReport.filter((site) => site.statusName === "Assigned").length;

  const tabsData = [
    { name: "All Tickets", value: "all", badgeCount: Total },
    {
      name: "Created ",
      value: "ticketTypeName",
      badgeCount: ticketTypeNameCount,
    },
    {
      name: "Assigned ",
      value: "ticketTypeOperation",
      badgeCount: ticketTypeOperationCount,
    },
  ];

  const handleDownloadTable = (val) => {
    if (val == null || val.length === 0) {
      console.error("The value is null or empty.");
      return;
    }

    Download(val, "Ticket");
  };
  const handleUploadticket = () => {
    seteditval(true);
  };

  useEffect(() => {
    if (ticketFields.ticketTypeName !== "") {
      let NameID = equipmevalue?.filter((data) => {
        return data.ticketTypeName === ticketFields?.ticketTypeName;
      });
      getEquipmentcategory(NameID[0]?.ticketTypeId);
    }
  }, [ticketFields.ticketTypeName]);

  const valueScreen = [
    { label: "inprograss" },
    { label: "Assigned" },
    { label: "Created" },
    { label: "Finished" },
    { label: "UnFinished" },
  ];

  useEffect(() => {
    if (isFromSiteList) {
      setTicketFields(() => {
        return { ...ticketFields, siteName: params.name };
      });
      getEquipmentCall(params.id);
    }
    let data = ticketConfigField();
    setTextField(data);
  }, []);

  const handleTxtChange = (event, name) => {
    setTicketFields({ ...ticketFields, [name]: event.target.value });
  };

  const handledatepicchange = (date, name) => {
    setFromDate(date.toString());
  };
  const handledatepic = (date, name) => {
    setTodate(date.toString());
  };
  useEffect(() => {
    if (ticketFields.equipmentType === "Maintenance") {
      getEquipmentcategory(2);
    } else if (ticketFields.equipmentType === "Operation") {
      getEquipmentcategory(1);
    }
  }, [ticketFields]);
  const handleDropdownChange = (event, value, name) => {
    console.log(value);

    if (name === "siteName") {
      handleassignvalue(value);
    }

    console.log(name === "siteName", value);
    if (name === "equipmentType") {
      setTicketFields({
        ...ticketFields,
        equipmentType: value,
        equipmentName: null,
        equipementArray: null,
      });
    } else {
      setTicketFields({ ...ticketFields, [name]: value });
    }
  };

  const handleMultipleChange = (event, value, name) => {
    setTicketFields({ ...ticketFields, [name]: value });
  };

  function getOptions(propName) {
    let option = [];
    switch (propName) {
      case "siteName":
        return (option = siteIdName?.map((option) => option.siteName));
      case "equipmentCategory":
        return categoryvalue?.map((option) => option.categoryName);
      case "equipmentType":
        return (option = equipmevalue?.map((option) => option.ticketTypeName));
      case "equipmentName":
        return (option = EquipmetId?.map((option) => option.displayName));
      case "priority":
        return (option = Priority?.map((option) => option.label));

      case "ticketcategory":
        return (option = Sitecategory?.map((option) => option.categoryName));
      case "ticketstate":
        return (option = state?.map((option) => option.stateName));
      case "assignedTo":
        return (option = Assign?.map((option) => option.userName));
      case "ticketStatus":
        return (option = ticketstatus?.map((option) => option.statusName));
      default:
    }
    return option;
  }

  const Priority = [
    { label: "High", value: "3" },
    { label: "Medium", value: "2" },
    { label: "Low", value: "1" },
  ];
  const modalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
  };
  const modalstyl = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
  };

  const handleassign = (data, value) => {
    if (value === null) {
      setassignData("");
    } else {
      setassignData(value);
    }
  };
  const handleChangeFromTime = (event) => {
    console.log(event, "event");
    const selectedFromTime = dayjs(event);
    const timeString = new Date(selectedFromTime).toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
    console.log(timeString, "selectedFromTime");
    setFromTime(timeString);
  };
  const handleChangevalue = (e) => {
    setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
  };

  const handleassigned = async (ticketId) => {
    let filteredticketTypeId = Object.keys(initialState).reduce((acc, key) => {
      if (ticketId.hasOwnProperty(key)) {
        acc[key] = ticketId[key];
      }
      return acc;
    }, {});
    seteditcall(ticketId);
    setTicketFields(filteredticketTypeId);
    setassignDatas(true);
    setOpenn(true);
  };

  const extractDataByKey = (key) => {
    let uniqueValues = new Set();

    SiteReport.forEach((object) => {
      if (object.hasOwnProperty(key) && object[key] !== null) {
        uniqueValues.add(object[key]);
      }
    });

    return Array.from(uniqueValues);
  };

  const dropData = {
    siteName: siteIdName?.map((option) => option.siteName),
    ticketCode: extractDataByKey("ticketCode"),
    priority: Priority?.map((option) => option.label),
    ticketTypeName: equipmevalue?.map((option) => option.ticketTypeName),
    displayName: extractDataByKey("displayName"),
    categoryName: extractDataByKey("categoryName"),
    AssignedBy: extractDataByKey("AssignedBy"),
    stateName: extractDataByKey("stateName"),
    statusName: extractDataByKey("statusName"),
    subject: extractDataByKey("subject"),
  };

  const handleSnackClose = () => {
    setSnack(initialSnack);
  };

  const handleEditOpen = (val) => {
    setEditvalue(val);
    setEdit(true);
    setTicketFields({
      ...val,
      priority:
        val.priority === 3 ? "High" : val.priority === 2 ? "Medium" : "Low",
    });
    setOpen(true);
  };
  const handleDownloadticketdata = async (val) => {
    console.log(val?.ticketId);

    let data = await fetchDownloadDetails(val?.ticketId);
  };

  const handlesitevalue = (val) => {
    const siteIdFilter = siteIdName?.filter((data) => data.siteName === val);
    setValuedata(siteIdFilter[0]?.siteId);
    setTicketview(false);
    getDataTable(siteIdFilter[0]?.siteId);
  };
  const hasNonEmptyValue = Object.values(ticketFields).some(
    (value) => value !== ""
  );

  const stylee = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 480,
    bgcolor: "background.paper",
    height: 250,
    borderRadius: "20px",
    padding: "1px",
  };
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <div>
            {/* ---------   -----save andupdate---- popup----------- */}

            <Formik
              key={edit ? "edit" : "add"}
              enableReinitialize={true}
              initialValues={ticketFields}
              validationSchema={ticketSchema}
              onSubmit={async (values, { resetForm }) => {
                let submitValue = values;
                if (edit) {
                  const result = await handleUpdateForm(submitValue);

                  if (result === 1) {
                    resetForm();
                  }
                } else {
                  const result = await handleSave(submitValue);

                  if (result === 1) {
                    resetForm();
                  }
                }
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                handleReset,
                resetForm,
              }) => (
                <Form>
                  <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={modalstyle}>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            // alignItems: 'center',
                            padding: "30px",
                            background: CheckForLight()
                              ? "rgb(246, 248, 252)"
                              : "#4f4f4f",
                            borderTopLeftRadius: "20px",
                            borderTopRightRadius: "20px",
                          }}
                        >
                          <div>
                            <Typography
                              id="transition-modal-title"
                              variant="h6"
                              component="h4"
                              className="modal-head"
                              style={{ marginTop: "2%" }}
                            >
                              {edit ? " Update Ticket " : "Add New Ticket"}
                            </Typography>
                          </div>
                          <div>
                            <Stack spacing={2} direction="row">
                              <CloseIcon
                                onClick={() => handleClose()}
                                style={{ cursor: "pointer" }}
                              />
                            </Stack>
                          </div>
                        </div>
                        <Divider sx={{ borderColor: "#888" }} />
                        <div style={{ padding: "20px", marginLeft: "14%" }}>
                          <Grid
                            container
                            rowSpacing={2}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            style={{ marginTop: "2%" }}
                          >
                            <Grid item xs={8}>
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "1rem",
                                }}
                              >
                                <Grid item xs={6}>
                                  <Typography
                                    style={{
                                      textTransform: "capitalize",
                                      opacity: "0.8",
                                    }}
                                  >
                                    Site Name *
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    // id={`combo-box-demo-${index}`}
                                    size="small"
                                    options={getOptions("siteName")}
                                    onChange={(event, value) =>
                                      handleDropdownChange(
                                        event,
                                        value,
                                        "siteName"
                                      )
                                    }
                                    sx={{ width: "15vw" }}
                                    disabled={edit ? ticketFields.siteName : ""}
                                    value={ticketFields.siteName}
                                    renderInput={(params) => (
                                      <div>
                                        <TextField
                                          {...params}
                                          placeholder={"E.g: IFP"}
                                        />
                                      </div>
                                    )}
                                    classes={{
                                      option: "autocomplete",
                                    }}
                                  />
                                </Grid>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "1rem",
                                }}
                              >
                                <Grid xs={6}>
                                  <Typography
                                    style={{
                                      textTransform: "capitalize",
                                      opacity: "0.8",
                                    }}
                                  >
                                    Ticket Type *
                                  </Typography>
                                </Grid>

                                <Grid xs={2}>
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    size="small"
                                    options={getOptions("equipmentType")}
                                    disabled={edit ? ticketFields.siteName : ""}
                                    onChange={(event, value) =>
                                      handleDropdownChange(
                                        event,
                                        value,
                                        "equipmentType"
                                      )
                                    }
                                    sx={{ width: "15vw" }}
                                    value={ticketFields.equipmentType}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={"E.g: Operation"}
                                      />
                                    )}
                                    classes={{
                                      option: "autocomplete",
                                    }}
                                  />
                                </Grid>
                              </div>

                              {/* Operation & Maintenance */}
                              {ticketFields?.equipmentType === "Operation" && (
                                <div
                                  style={{
                                    display: "flex",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <Grid xs={6}>
                                    <Typography
                                      style={{
                                        textTransform: "capitalize",
                                        opacity: "0.8",
                                      }}
                                    >
                                      Equipment Name *
                                    </Typography>
                                  </Grid>
                                  <Grid xs={2}>
                                    <Autocomplete
                                      size="small"
                                      disableClearable
                                      disabled={
                                        edit ? ticketFields.equipmentName : ""
                                      }
                                      value={ticketFields.equipmentName}
                                      options={getOptions("equipmentName")}
                                      onChange={(event, value) =>
                                        handleDropdownChange(
                                          event,
                                          value,
                                          "equipmentName"
                                        )
                                      }
                                      sx={{ width: "15vw" }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder={"E.g: INV-01"}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </div>
                              )}
                              {ticketFields?.equipmentType ===
                                "Maintenance" && (
                                <div
                                  style={{
                                    display: "flex",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <Grid xs={6}>
                                    <Typography
                                      style={{
                                        textTransform: "capitalize",
                                        opacity: "0.8",
                                      }}
                                    >
                                      Equipment Name *
                                    </Typography>
                                  </Grid>

                                  <Grid xs={2}>
                                    <Select
                                      sx={{ width: "15vw" }}
                                      size="small"
                                      disabled={!ticketFields.siteName}
                                      // labelId="demo-multiple-checkbox-label"
                                      id="demo-multiple-checkbox"
                                      multiple
                                      value={ticketFields.equipementArray || []}
                                      onChange={(event) =>
                                        handleMultipleChange(
                                          event,
                                          event.target.value,
                                          "equipementArray"
                                        )
                                      }
                                      input={<OutlinedInput />}
                                      renderValue={(selected) => {
                                        if (selected.length === 0) {
                                          return (
                                            <Typography
                                              variant="body1"
                                              sx={{
                                                color: "lightgray",
                                              }}
                                            >
                                              E.g: INV-01
                                            </Typography>
                                          );
                                        }
                                        return selected.join(", ");
                                      }}
                                      displayEmpty
                                      MenuProps={MenuProps}
                                    >
                                      {EquipmetId.length !== 0 ? (
                                        EquipmetId.map((data) => (
                                          <MenuItem
                                            key={data.displayName}
                                            value={data.displayName}
                                          >
                                            <Checkbox
                                              checked={
                                                ticketFields.equipementArray?.indexOf(
                                                  data.displayName
                                                ) > -1
                                              }
                                            />
                                            <ListItemText
                                              primary={data.displayName}
                                            />
                                          </MenuItem>
                                        ))
                                      ) : (
                                        <MenuItem disabled>
                                          <Typography
                                            variant="body1"
                                            sx={{ color: "gray" }}
                                          >
                                            No Options
                                          </Typography>
                                        </MenuItem>
                                      )}
                                    </Select>
                                  </Grid>
                                </div>
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "1rem",
                                }}
                              >
                                <Grid xs={6}>
                                  <Typography
                                    style={{
                                      textTransform: "capitalize",
                                      opacity: "0.8",
                                    }}
                                  >
                                    Ticket Category *
                                  </Typography>
                                </Grid>
                                <Grid xs={2}>
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    disabled={!ticketFields.equipmentType}
                                    // id={`combo-box-demo-${index}`}
                                    size="small"
                                    options={getOptions("equipmentCategory")}
                                    onChange={(event, value) =>
                                      handleDropdownChange(
                                        event,
                                        value,
                                        "equipmentCategory"
                                      )
                                    }
                                    sx={{ width: "15vw" }}
                                    // value={values[data.name]}
                                    renderInput={(params) => (
                                      <div>
                                        <TextField
                                          {...params}
                                          placeholder={"E.g: Inverter Down"}
                                        />
                                      </div>
                                    )}
                                    classes={{
                                      option: "autocomplete",
                                    }}
                                  />
                                </Grid>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "1rem",
                                }}
                              >
                                <Grid xs={6}>
                                  <Typography
                                    style={{
                                      textTransform: "capitalize",
                                      opacity: "0.8",
                                    }}
                                  >
                                    Subject *
                                  </Typography>
                                </Grid>
                                <Grid xs={2}>
                                  <TextField
                                    id="outlined-basic"
                                    size="small"
                                    placeholder="Subject"
                                    variant="outlined"
                                    sx={{ width: "15vw" }}
                                    // error={!validEmail}
                                    value={ticketFields.subject}
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleTxtChange(e, "subject");
                                    }}
                                  />
                                  <ErrorMessage
                                    name={"subject"}
                                    component="div"
                                    className="errorStyle"
                                    style={{
                                      color: "red",
                                      marginTop: "1%",
                                      textAlign: "left",
                                      marginLeft: "0%",
                                    }}
                                  />
                                </Grid>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "1rem",
                                }}
                              >
                                <Grid xs={6}>
                                  <Typography
                                    style={{
                                      textTransform: "capitalize",
                                      opacity: "0.8",
                                    }}
                                  >
                                    Priority *
                                  </Typography>
                                </Grid>
                                <Grid xs={2}>
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    // id={`combo-box-demo-${index}`}
                                    size="small"
                                    options={getOptions("priority")}
                                    onChange={(event, value) =>
                                      handleDropdownChange(
                                        event,
                                        value,
                                        "priority"
                                      )
                                    }
                                    sx={{ width: "15vw" }}
                                    value={ticketFields.priority}
                                    renderInput={(params) => (
                                      <div>
                                        <TextField
                                          {...params}
                                          placeholder={"E.g: High"}
                                        />
                                      </div>
                                    )}
                                    classes={{
                                      option: "autocomplete",
                                    }}
                                  />
                                </Grid>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "1rem",
                                }}
                              >
                                <Grid xs={6}>
                                  <Typography
                                    style={{
                                      textTransform: "capitalize",
                                      opacity: "0.8",
                                    }}
                                  >
                                    Description *
                                  </Typography>
                                </Grid>
                                <Grid xs={2}>
                                  <div>
                                    <TextField
                                      id="outlined-basic"
                                      size="small"
                                      placeholder="Description"
                                      variant="outlined"
                                      sx={{ width: "15vw" }}
                                      // error={!validEmail}
                                      value={ticketFields.description}
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleTxtChange(e, "description");
                                      }}
                                    />
                                    <ErrorMessage
                                      name={"description"}
                                      component="div"
                                      className="errorStyle"
                                      style={{
                                        color: "red",
                                        marginTop: "1%",
                                        textAlign: "left",
                                        marginLeft: "0%",
                                      }}
                                    />
                                  </div>
                                </Grid>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div style={{ padding: "20px" }}>
                          <Stack
                            direction="row"
                            spacing={2}
                            style={{
                              justifyContent: "flex-end",
                            }}
                          >
                            <Item>
                              {edit ? (
                                <div>
                                  <Button
                                    style={{ textTransform: "capitalize" }}
                                    variant="contained"
                                    disabled={
                                      !(
                                        ticketFields?.description &&
                                        ticketFields?.subject
                                      )
                                    }
                                    onClick={handleSubmit}
                                  >
                                    Update
                                  </Button>
                                </div>
                              ) : (
                                <div>
                                  <Button
                                    style={{ textTransform: "capitalize" }}
                                    disabled={
                                      !(
                                        ticketFields?.siteName &&
                                        ticketFields?.priority &&
                                        ticketFields?.equipmentCategory &&
                                        ticketFields?.description &&
                                        ticketFields?.subject &&
                                        (ticketFields.equipmentType !==
                                          "Maintenance" ||
                                          ticketFields.equipementArray) &&
                                        (ticketFields.equipmentType !==
                                          "Operation" ||
                                          ticketFields.equipmentName)
                                      )
                                    }
                                    variant="contained"
                                    onClick={handleSubmit}
                                  >
                                    {toBackend ? "Creating..." : "Create "}
                                  </Button>
                                </div>
                              )}
                            </Item>
                            {edit ? (
                              <Item></Item>
                            ) : (
                              <Item>
                                <Button
                                  style={{ textTransform: "capitalize" }}
                                  variant="contained"
                                  disabled={!hasNonEmptyValue}
                                  onClick={() => {
                                    handleReset();
                                    setTicketFields(initialState);
                                  }}
                                >
                                  Reset
                                </Button>
                              </Item>
                            )}
                          </Stack>
                        </div>
                      </div>
                    </Box>
                  </Modal>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={opeee}
                    onClose={handleClose}
                    closeAfterTransition
                  >
                    <Fade in={opeee}>
                      <Box sx={modalstyl} style={{ borderRadius: "20px" }}>
                        <CardHeader
                          sx={{
                            padding: "20px 20px 20px 20px",
                            background: CheckForLight()
                              ? "rgb(246, 248, 252)"
                              : "#4f4f4f",
                            borderRadius: "20px",
                          }}
                          action={
                            <Stack spacing={2} direction="row">
                       
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  startIcon={<SaveIcon />}
                                  // disabled={indexvalue.length !== 1}
                                  onClick={handleExcelUpload}
                                >
                                  Submit
                                </Button>
                             

                              <Button
                                variant="contained"
                               
                                style={{
                                  borderRadius: "25px",
                                  textTransform: "capitalize",
                                }}
                                onClick={handlecancel}
                                startIcon={<CancelIcon />}
                              >
                                Cancel
                              </Button>
                            </Stack>
                          }
                          title={editval ? "Add Ticket" : "Add Assign Ticket"}
                        />

                        <Divider sx={{ borderColor: "#888" }} />

                        <div>
                          {excelData && (
                            <div style={{ marginTop: "2%", padding: "20px" }}>
                              <Card
                                elevation={2}
                                style={{ maxHeight: "500px", overflow: "auto" }}
                              >
                                <Table>
                                  <TableHead style={{ borderBottom: "none" }}>
                                    <TableRow>
                                      {excelData[0].map((cell, index) => (
                                        <TableCell
                                          style={{ borderBottom: "none" }}
                                          key={index}
                                        >
                                          {cell}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {excelData.slice(1).map((row, rowIndex) => (
                                      <Tooltip
                                        key={rowIndex}
                                        title={
                                          indexvalue.includes(rowIndex)
                                            ? errorMessage[
                                                indexvalue.indexOf(rowIndex)
                                              ]
                                            : ""
                                        }
                                        placement="top"
                                      >
                                        <TableRow
                                          style={{
                                            border: indexvalue.includes(
                                              rowIndex
                                            )
                                              ? "2px solid red"
                                              : "none",
                                            backgroundColor:
                                              indexvalue.includes(rowIndex)
                                                ? "#ff070721"
                                                : "none",
                                          }}
                                        >
                                          {row.map((cell, cellIndex) => (
                                            <TableCell
                                              style={{
                                                border: "none",
                                                borderTop: "none",
                                              }}
                                              key={cellIndex}
                                            >
                                              {cell}
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      </Tooltip>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Card>
                            </div>
                          )}
                        </div>
                      </Box>
                    </Fade>
                  </Modal>

                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openfilter}
                    closeAfterTransition
                  >
                    <Fade in={openfilter}>
                      <Box
                        className={"styleModalSmall"}
                        style={{ height: "40vh" }}
                      >
                        <Card
                          sx={{
                            borderRadius: "20px",
                            height: "fit-content",
                          }}
                        >
                          <CardHeader
                            sx={{
                              padding: "20px 20px 20px 20px",
                              background: CheckForLight()
                                ? "rgb(246, 248, 252)"
                                : "#4f4f4f",
                            }}
                            action={
                              <Stack spacing={2} direction="row">
                                <Button
                                  variant="contained"
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  startIcon={
                                    toBackend ? (
                                      <CircularProgress
                                        size={20}
                                        color="inherit"
                                      />
                                    ) : (
                                      <SaveIcon />
                                    )
                                  }
                                  disabled={
                                    !(
                                      ticketFields.siteName &&
                                      fromDate &&
                                      todate
                                    )
                                  }
                                  onClick={toBackend ? null : handleFilterTable}
                                >
                                  {toBackend ? "Viewing..." : "View"}
                                </Button>

                                <Button
                                  variant="contained"
                                  disabled={toBackend}
                                  style={{
                                    borderRadius: "25px",
                                    textTransform: "capitalize",
                                  }}
                                  onClick={() => {
                                    handlefilterClose();
                                    handleReset();
                                  }}
                                  startIcon={<CancelIcon />}
                                >
                                  Cancel
                                </Button>
                              </Stack>
                            }
                            title={"Customize Ticket Filters"}
                          />
                          <Divider sx={{ borderColor: "#888" }} />
                          <div
                            style={{
                              height: "30vh",
                              padding: "20px",
                            }}
                          >
                            <Grid container spacing={2} columns={16}>
                              {textt?.length > 0
                                ? textt?.map((data, index) => (
                                    <Grid item xs={4}>
                                      <div key={index}>
                                        <></>

                                        {data.type === "textField" ? (
                                          <>
                                            <Typography
                                              variant="body1"
                                              className="modal-typo"
                                              gutterBottom
                                            >
                                              {data.label}
                                            </Typography>
                                            <Field
                                              as={TextField}
                                              disabled={toBackend}
                                              id={`outlined-basic-${index}`}
                                              size="small"
                                              variant="outlined"
                                              name={data.name}
                                              inputProps={{
                                                maxLength: data.length,
                                              }}
                                              placeholder={data.placeholder}
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleTxtChange(e);
                                              }}
                                              sx={{ width: "12vw" }}
                                            />
                                            <ErrorMessage
                                              name={data.name}
                                              component="div"
                                              className="error"
                                              style={{
                                                color: "red",
                                                marginTop: "1%",
                                                textAlign: "left",
                                                marginLeft: "0%",
                                              }}
                                            />
                                          </>
                                        ) : data.type === "dropdown" ? (
                                          <>
                                            <Typography
                                              variant="body1"
                                              className="modal-typo"
                                              gutterBottom
                                            >
                                              {data.label}
                                            </Typography>
                                            <Tooltip>
                                              <Field
                                                render={({ field, form }) => (
                                                  <Tooltip>
                                                    <Autocomplete
                                                      options={getOptions(
                                                        data.name
                                                      )}
                                                      size="small"
                                                      id={`combo-box-demo-${index}`}
                                                      onChange={(
                                                        event,
                                                        value
                                                      ) =>
                                                        handleDropdownChange(
                                                          event,
                                                          value,
                                                          data.name
                                                        )
                                                      }
                                                      sx={{ width: "12vw" }}
                                                      ListboxProps={{
                                                        style: {
                                                          maxHeight: "200px", // Set your desired height here
                                                        },
                                                      }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          placeholder={
                                                            data.placeholder
                                                          }
                                                        />
                                                      )}
                                                    />
                                                  </Tooltip>
                                                )}
                                              />
                                            </Tooltip>
                                            <ErrorMessage
                                              name={data.name}
                                              component="div"
                                              className="error"
                                              style={{
                                                color: "red",
                                                marginTop: "1%",
                                                textAlign: "left",
                                                marginLeft: "0%",
                                              }}
                                            />
                                          </>
                                        ) : data.type === "multidropdown" ? (
                                          <>
                                            <Typography
                                              variant="body1"
                                              className="modal-typo"
                                              gutterBottom
                                            >
                                              {data.label}
                                            </Typography>
                                            <Autocomplete
                                              multiple
                                              id="checkboxes-tags-demo"
                                              options={getOptions(data.name)} // Use getOptions directly
                                              onChange={(event, value) => {
                                                handleDropdownChange(
                                                  event,
                                                  value,
                                                  data.name
                                                );

                                                // Check if "Select All" is selected
                                                if (
                                                  value.includes("Select All")
                                                ) {
                                                  const allOptions = getOptions(
                                                    data.name
                                                  ); // No filters applied here
                                                  const newValue =
                                                    value.length ===
                                                    allOptions.length
                                                      ? []
                                                      : allOptions;
                                                  handleDropdownChange(
                                                    event,
                                                    newValue,
                                                    data.name
                                                  );
                                                }
                                              }}
                                              style={{
                                                display: "flex",
                                              }}
                                              disableCloseOnSelect
                                              size="small"
                                              sx={{
                                                width: "12vw",
                                                height: "40px",
                                                marginRight: 8,
                                              }}
                                              defaultValue={
                                                edit && data.name === "siteName"
                                                  ? editcall?.siteName
                                                    ? [editcall.siteName]
                                                    : []
                                                  : edit &&
                                                    data.name ===
                                                      "parameterName"
                                                  ? Array.isArray(
                                                      editcall?.parameterName
                                                    )
                                                    ? editcall.parameterName
                                                    : editcall?.parameterName
                                                    ? [editcall.parameterName]
                                                    : []
                                                  : []
                                              }
                                              renderOption={(
                                                props,
                                                option,
                                                { selected }
                                              ) => {
                                                const { key, ...optionProps } =
                                                  props;
                                                return (
                                                  <li
                                                    key={key}
                                                    {...optionProps}
                                                  >
                                                    <Checkbox
                                                      icon={icon}
                                                      checkedIcon={checkedIcon}
                                                      checked={selected}
                                                    />
                                                    {option}
                                                  </li>
                                                );
                                              }}
                                              renderInput={(params) => {
                                                console.log(params.inputProps);

                                                return (
                                                  <TextField
                                                    {...params}
                                                    size="small"
                                                    placeholder={
                                                      params.inputProps
                                                        .value === 0
                                                        ? data.name
                                                        : ""
                                                    }
                                                    InputProps={{
                                                      ...params.InputProps,
                                                      style: { height: "100%" },
                                                    }}
                                                    onChange={(event) => {
                                                      console.log(
                                                        "Input value on change:",
                                                        event.target.value
                                                      );
                                                      if (
                                                        params.InputProps
                                                          .onChange
                                                      ) {
                                                        params.InputProps.onChange(
                                                          event
                                                        );
                                                      }
                                                    }}
                                                  />
                                                );
                                              }}
                                              renderTags={(value) => {
                                                if (value.length === 0)
                                                  return null;
                                                const truncatedValue =
                                                  value[0].length > 15
                                                    ? value[0].substring(
                                                        0,
                                                        15
                                                      ) + "..."
                                                    : value[0];
                                                return (
                                                  <div
                                                    style={{
                                                      maxWidth: "12vw",
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      whiteSpace: "nowrap",
                                                      padding: "5px",
                                                      marginRight: 5,
                                                    }}
                                                  >
                                                    {truncatedValue}{" "}
                                                    {value.length > 1
                                                      ? `+${value.length - 1}`
                                                      : ""}
                                                  </div>
                                                );
                                              }}
                                              noOptionsText="No options"
                                            />

                                            <ErrorMessage
                                              name={data.name}
                                              component="div"
                                              className="error"
                                              style={{
                                                color: "red",
                                                marginTop: "1%",
                                                textAlign: "left",
                                                marginLeft: "0%",
                                              }}
                                            />
                                          </>
                                        ) : data.type === "date" ? (
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <Typography
                                              variant="body1"
                                              className="modal-typo"
                                              gutterBottom
                                            >
                                              {data.label}
                                            </Typography>
                                            <DatePicker
                                              size="small"
                                              sx={{
                                                width: "12vw",
                                              }}
                                              name={data.name}
                                              onChange={(e) => {
                                                handledatepicchange(
                                                  e,
                                                  data.name
                                                );
                                              }}
                                              slotProps={{
                                                textField: {
                                                  size: "small",
                                                },
                                              }}
                                            />
                                          </LocalizationProvider>
                                        ) : data.type === "datee" ? (
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <Typography
                                              variant="body1"
                                              className="modal-typo"
                                              gutterBottom
                                            >
                                              {data.label}
                                            </Typography>

                                            <DatePicker
                                              size="small"
                                              sx={{
                                                width: "12vw",
                                              }}
                                              shouldDisableDate={(date) =>
                                                dayjs(date).isBefore(
                                                  dayjs(fromDate),
                                                  "day"
                                                )
                                              }
                                              name={data.name}
                                              onChange={(e) => {
                                                handledatepic(e, data.name);
                                              }}
                                              slotProps={{
                                                textField: {
                                                  size: "small",
                                                },
                                              }}
                                            />
                                          </LocalizationProvider>
                                        ) : null}
                                      </div>
                                    </Grid>
                                  ))
                                : null}
                            </Grid>
                          </div>
                        </Card>

                        <div>
                          <Grid container spacing={2} columns={32}></Grid>
                        </div>
                      </Box>
                    </Fade>
                  </Modal>
                </Form>
              )}
            </Formik>
          </div>
          <div>
            <Modal
              open={openn}
              onClose={handleClosee}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modastyle}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "20px",
                    backgroundColor: "rgb(246, 248, 252)",
                    borderRadius: "20px",
                  }}
                >
                  <div>
                    <Typography
                      id="transition-modal-title"
                      variant="h6"
                      component="h4"
                      className="modal-head"
                      style={{ marginTop: "2%" }}
                    >
                      Assign Ticket
                    </Typography>
                  </div>
                  <div>
                    <Stack spacing={2} direction="row">
                      <CloseIcon
                        onClick={() => handleClosee()}
                        style={{ cursor: "pointer" }}
                      />
                    </Stack>
                  </div>
                </div>
                <Divider sx={{ borderColor: "#888" }} />
                <div style={{ padding: "20px" }}>
                  <div>
                    <Grid
                      container
                      rowSpacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      style={{ marginTop: "4%" }}
                    >
                      <Grid item xs={5}>
                        <Typography>Date</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            size="small"
                            disablePast
                            placeholder="Remark"
                            sx={{ width: "14vw" }}
                            onChange={handleDateChange}
                            slotProps={{ textField: { size: "small" } }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Assign To</Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Autocomplete
                          name="status"
                          disablePortal
                          id="combo-box-demo"
                          placeholder="Name"
                          options={Assign.map((options) => options.userName)}
                          onChange={(data, event) => handleassign(data, event)}
                          onFocus={handleAssigntype} // Call handleAssigntype when Autocomplete is focused
                          size="small"
                          sx={{ width: "14vw" }}
                          renderInput={(params) => (
                            <TextField {...params} placeholder={"Jane"} />
                          )}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Time Slot</Typography>
                      </Grid>

                      <Grid item xs={7}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["TimePicker"]}>
                            <div style={{ overflow: "hidden" }}>
                              <TimePicker
                                ampm={false}
                                onChange={(event) => {
                                  handleChangeFromTime(event);
                                }}
                                minTime={dayjs().startOf("day").hour(5)}
                                // maxTime={latestToTime}
                                maxTime={dayjs().startOf("day").hour(19)}
                                slotProps={{
                                  textField: {
                                    size: "small",
                                    InputProps: {
                                      style: {
                                        overflow: "hidden",
                                        width: "14vw",
                                      },
                                    },
                                  },
                                }}
                              />
                            </div>
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Remarks</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          name="remarks"
                          placeholder="Remark"
                          variant="outlined"
                          sx={{ width: "14vw" }}
                          onChange={(event) => handleChangevalue(event)}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <div style={{ marginTop: "10%" }}>
                      <Stack
                        direction="row"
                        spacing={2}
                        style={{ justifyContent: "flex-end" }}
                      >
                        <Item></Item>

                        <Item>
                          <Button
                            style={{ textTransform: "capitalize" }}
                            variant="contained"
                            disabled={
                              !(
                                selectedDate &&
                                assignData &&
                                fieldValue?.remarks
                              )
                            }
                            onClick={handleUpdate}
                          >
                            {toBackend ? "Assigning..." : "Assign"}
                          </Button>
                        </Item>
                      </Stack>
                    </div>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>

          <div>
            <Modal
              open={closeticket}
              onClose={handleCloseticket}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={stylee}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    // alignItems: 'center',
                    padding: "20px",
                    backgroundColor: "rgb(246, 248, 252)",
                    borderRadius: "20px",
                  }}
                >
                  <div>
                    <Typography
                      id="transition-modal-title"
                      variant="h6"
                      component="h4"
                      className="modal-head"
                      style={{ marginTop: "2%" }}
                    >
                      Bulk Ticket Closure
                    </Typography>
                  </div>
                  <div>
                    <Stack spacing={2} direction="row">
                      <CloseIcon
                        onClick={() => handleCloseticket()}
                        style={{ cursor: "pointer" }}
                      />
                    </Stack>
                  </div>
                </div>
                <Divider sx={{ borderColor: "#888" }} />
                <div style={{ padding: "40px 30px 30px " }}>
                  <div>
                    {nextvalue ? (
                      <div>
                        <Grid container spacing={2}>
                          <Grid item>
                            <Typography style={{ padding: "7px" }}>
                              {" "}
                              All selected ticket will be close{" "}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    ) : (
                      <div>
                        <Grid container spacing={2}>
                          <Grid item>Remark:</Grid>
                          <Grid item xs={3}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              name="remarks"
                              placeholder="Remark"
                              variant="outlined"
                              textAlign="center"
                              sx={{ width: "15vw" }}
                              onChange={(event) => handleChangevalue(event)}
                            />
                          </Grid>
                        </Grid>{" "}
                      </div>
                    )}
                  </div>

                  <div>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ justifyContent: "flex-end" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Button
                            style={{
                              textTransform: "capitalize",
                              marginTop: "4vh",
                            }}
                            variant="contained"
                            onClick={handleCloseBack}
                          >
                            Cancel
                          </Button>
                        </div>
                        <div>
                          <Stack spacing={4} direction="row">
                            <Button
                              style={{
                                textTransform: "capitalize",
                                marginTop: "4vh",
                                marginLeft: "7%",
                              }}
                              variant="contained"
                              disabled={fieldValue?.remarks === undefined}
                              // disabled={fieldValue?.remarks.length > 0}
                              onClick={handlecloseUpdate}
                            >
                              Submit
                            </Button>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
          <CustomSnackbar
            open={snack.open}
            onClose={handleSnackClose}
            autoHideDuration={5000}
            severity={snack.severity}
            message={snack.message}
          />

          {/* ---------------pass props value----------- */}
          <div style={{ marginTop: "-0.5%" }}>
            <TableTemplate
              PageName={"Ticket Overview"}
              addButton={"Ticket"}
              SearchLabel={"Search Ticket  Here... "}
              chartValue={"Ticket Overview"}
              header={headCells}
              uploadButton={true}
              rowsValue={Total > 0 ? tableValues : []}
              tabsData={tabsData}
              rawData={SiteReport}
              handlecheckboxclose={() => handelTicketclose()}
              userRole={ticketConfiguration[0]}
              handleChange={handleChangeTab}
              handleAddPopupOpen={(val) => handleCrate(val)}
              handlefilterPopupOpen={(val) => handlefilter(val)}
              handleAssignedPopupOpen={(val) => handleassigned(val)}
              handleEditPopupOpen={(val) => handleEditOpen(val)}
              handleTicketPopupOpen={(val) => handleTicket(val)}
              handleticketdownloadPopupOpen={(val) =>
                handleDownloadticketdata(val)
              }
              handleuploadPopupOpen={(val) => handleaddticket(val)}
              handleDownloadTemplate={() => handleTicketdownload()}
              handleUploadExcelpopup={() => handleTicketupload()}
              handleTicketCheckvalue={(val, id) =>
                handleTicketCheckbox(val, id)
              }
              handleclosepopupopen={(val) => handelTicketclose(val)}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              handleSitedropdownvalue={(val) => handlesitevalue(val)}
              isassigned={true}
              dropData={dropData}
              paths={paths}
              siteNameList={siteIdName}
              sitevalue={Valuedata}
              filterData={filterdata}
              filterstatus={Ticketview}
              barchart={filterBardata}
              handlecheckclosee={handleCloseBack}
              statuscall={statuscall}
              fallbackText={`No ${
                siteTab === "" ? "" : siteTab
              } Ticket has been created yet.`}
            />
          </div>
        </div>
      )}
    </div>
  );
}
