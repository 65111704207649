import { CheckForLight } from "./CommanFunction";

export const CustomChartIcon = ({ sx }) => {
  const defaultColor = CheckForLight() ? "black" : "white";
  return (
    <svg
      fill={sx.color || defaultColor}
      height={sx.height}
      width={sx.width}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 490 490"
      xmlSpace="preserve"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <g>
            <polygon points="75.9,7.4 3.5,7.4 3.5,490 486.5,490 486.5,418 75.9,418 "></polygon>
            <polygon points="110.9,378.4 176.2,378.4 176.2,183.6 110.9,246.6 "></polygon>
            <polygon points="279.6,378.4 279.6,217 214.3,174.6 214.3,378.4 "></polygon>
            <polygon points="383,378.4 383,150.1 317.7,206.9 317.7,378.4 "></polygon>
            <polygon points="443.7,97.2 421.1,116.7 421.1,378.4 486.5,378.4 486.5,134.2 "></polygon>
            <path d="M443.3,0h-51.7c-6.2,0-11.7,4.2-13,10.2c-1.7,8.1,4.7,15.4,12.6,15.4h19.4L293.6,128l-89.4-58c-5.1-3.1-11.7-2.7-15.9,1.6 l-85.7,83.2c-5,4.9-5.2,12.9-0.4,18l0,0c4.9,5.2,13.1,5.4,18.2,0.5l78.4-76l89,57.5c4.7,3.1,11.3,2.7,15.6-1.2L430.5,42.8v21.8 c0,6.2,4.2,11.7,10.2,13c8.1,1.7,15.4-4.7,15.4-12.6V12.9C456.1,5.8,450.4,0,443.3,0z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
