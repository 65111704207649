import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  TextField,
  InputAdornment,
  Modal,
  Fade,
  Stack,
  Autocomplete,
  TableContainer,
  Backdrop,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
  Tooltip,
  Checkbox,
  CardHeader,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import FadeLoader from "react-spinners/FadeLoader";
import { downloadExcelTemplate } from "../../Template/ExcelTemplates/ExcelTemplate";
import { errorConfigField } from "../util/TextField";
import * as XLSX from "xlsx";
import * as yup from "yup";
// ========Modal================

import { styled } from "@mui/material/styles";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

/*------------api call-----------------*/
import {
  fetcherrorTable,
  postErrortype,
  putErrortype,
  Equipmenttypedropdown,
  Equipmentcategorydropdown,
  fetcherrorTableById,
  postErrorexceldata,
  fetchErrorTypeDetails,
} from "../../Api/ErrorApi";
import TableTemplate from "../../Template/TableTemplate";
import Download from "../../Template/Excel/Download";
import CustomSnackbar from "../util/components/CustomSnackbar";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ErrorConfigSchema } from "../util/ValidateSchema";
import { Statusdropdown, categoryByCustomerId } from "../../Api/CommonApi";
import replaceObjectById from "../util/StateUpdater";
// import { Getdropdowndata } from "../../Api/UserConfigurationAPI";
import errorMsg from "../util/errorMessage.json";
import { GetCustomerdropdowndata } from "../../Api/UserConfigurationAPI";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { number } from "yup";
import { CheckForLight } from "../util/common/CommanFunction";
export default function Errorhome({ errorConfiguration }) {
  /*------------state-----------------*/
  const [siteError, setSiteError] = useState([]);
  const [activeFlag, setactiveFlag] = useState("");
  const [statusDrop, setStatusDrop] = useState([]);
  const [editcall, seteditcall] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [excelOpen, setExcelOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setloading] = useState(true);
  const [categoryvalue, setCategoryvalue] = useState([]);
  const [category, setCategory] = useState(null);
  const [equipmetvalue, setEquipmevalue] = useState([]);
  const [equipment, setEquipment] = useState("");
  const [priorityvalue, Setpriorityvalue] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalvalue, setModalvalue] = useState(true);
  const [toBackend, setToBackend] = useState(false);
  const [selectedFileData, setSelectedFileData] = useState(null);
  const [siteTab, setSiteTab] = React.useState("");
  const initalState = {
    description: "",
    equipmentCategory: "",
    createdBy: "",
    approvedBy: "",
    messageType: "",
    errorMessage: "",
    errorType: "",
    priority: "",
    status: "",
    lastUpdatedBy: "",
    equipmentTypeId: "",
    customerName: [],
    categoryId: "",
    equipmentType: "",
    errorCode: "",
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [stdField, setstdField] = useState(initalState);
  console.log(stdField);
  const [customerId, setCustomerId] = useState([]);
  const [textField, setTextField] = useState([]);
  const [SiteTypeIdFilter, setSiteTypeIdFilter] = useState([]);
  const initialSnack = { open: false, severity: "", message: "" };
  const [snack, setSnack] = useState(initialSnack);
  const [errorVal, setErrorVal] = useState(null);
  const [indexVal, setIndexVal] = useState(null);
  const [errorIndex, setErrorIndex] = useState(null);
  console.log(errorIndex);

  const [equipmTypeList, setEquipmTypeList] = useState([]);
  const [Valuedata, setValuedata] = useState(null);
  console.log(Valuedata);
  const handleSnackClose = () => {
    setSnack(initialSnack);
  };
  console.log(indexVal, "indexVal");
  const handleChangeTxt = (e) => {
    setstdField({ ...stdField, [e.target.name]: e.target.value });
  };
  const handleDropdownChange = (event, value, name) => {
    setstdField({ ...stdField, [name]: value });
  };
  useEffect(() => {
    let data = errorConfigField();
    setTextField(data);
  }, []);

  const priorityOptions = [
    { label: "High", value: 3 },
    { label: "Medium", value: 2 },
    { label: "Low", value: 1 },
  ];
  const mapPriority = (priority) => {
    if (typeof priority === "string") {
      priority = priority.toLowerCase();
    }

    switch (priority) {
      case "low":
        return 1;
      case "medium":
        return 2;
      case "high":
        return 3;
      case 1:
        return "Low";
      case 2:
        return "Medium";
      case 3:
        return "High";
      default:
        return 0;
    }
  };

  const mapStatus = (Status) => {
    switch (Status.toLowerCase()) {
      case "Active":
        return 1;
      case "inactive":
        return 0;
      default:
        return 0;
    }
  };
  console.log(categoryvalue, "categoryvaluecategoryvalue");
  const isOEM = sessionStorage.getItem("userTypeId") == 1;
  const getSelectedIds = (
    selectedValues,
    sourceArray,
    targetPropertyName,
    resultPropertName
  ) => {
    return selectedValues.map((selectedValue) => {
      const selectedItem = sourceArray.find(
        (item) => item[targetPropertyName] === selectedValue
      );
      return selectedItem ? selectedItem[resultPropertName] : null;
    });
  };

  /*------------------------- Api --------------------------------------------*/
  useEffect(() => {
    fetchData();
    fetchEquipmenttype();
    getCustomerList();
    // getcompanylist();
    // fetchEquipmentcategory();
    fetchTypeList();
    fetchStatus();
  }, []);
  /*------------------------- handle Update --------------------------------------------*/
  const handleUpdate = async (values) => {
    console.log(values);

    let id = editcall?.errorId;

    let EquipmentTypeIdFilter = equipmetvalue?.filter((data) => {
      return data.equipmentType === equipment;
    });

    let CategoryTypeIdFilter = categoryvalue?.filter((data) => {
      return data.equipmentCategory === category;
    });

    let StatusFilter = statusDrop?.filter((data) => {
      return data.label === activeFlag;
    });

    let data = isOEM
      ? {
          approvedBy: values.approvedBy,
          categoryId:
            edit && category === ""
              ? editcall.categoryId
              : CategoryTypeIdFilter[0]?.categoryId,
          lastUpdatedBy: sessionStorage.getItem("id"),
          equipmentTypeId:
            edit && equipment === ""
              ? editcall.equipmentTypeId
              : EquipmentTypeIdFilter[0]?.equipmentTypeId,
          description: values?.description,
          errorMessage: values?.errorMessage,
          messageType: values?.messageType,
          errorCode: stdField?.errorCode,
          priority:
            edit && priorityvalue == ""
              ? editcall.priority
              : mapPriority(priorityvalue),
          status:
            edit && activeFlag == "" ? editcall.status : StatusFilter[0]?.value,
          customerId: editcall.customerId,
          companyId: Number(sessionStorage.getItem("companyId")),
        }
      : {
          approvedBy: values.approvedBy,
          categoryId:
            edit && category === ""
              ? editcall.categoryId
              : CategoryTypeIdFilter[0]?.categoryId,
          lastUpdatedBy: sessionStorage.getItem("id"),
          equipmentTypeId:
            edit && equipment === ""
              ? editcall.equipmentTypeId
              : EquipmentTypeIdFilter[0]?.equipmentTypeId,
          description: values?.description,
          errorMessage: values?.errorMessage,
          errorCode: stdField?.errorCode,
          messageType: values?.messageType,
          priority:
            edit && priorityvalue == ""
              ? editcall.priority
              : mapPriority(priorityvalue),
          status:
            edit && activeFlag == "" ? editcall.status : StatusFilter[0]?.value,
          customerId: editcall.customerId,
          companyId: Number(sessionStorage.getItem("companyId")),
        };
    console.log(data);
    try {
      setToBackend(true);
      let responsedata = await putErrortype(data, id);
      // fetchData();
      // console.log(responsedata);
      const updatedTable = replaceObjectById(
        siteError,
        "errorId",
        id,
        responsedata
      );
      setSnack(errorMsg.success);
      fetchData(
        edit && equipment === ""
          ? values.equipmentTypeId
          : Number(EquipmentTypeIdFilter[0]?.equipmentTypeId)
      );
      setValuedata(
        edit && equipment === ""
          ? values.equipmentType
          : Number(EquipmentTypeIdFilter[0]?.equipmentType)
      );
      handleClose();
      setToBackend(false);
      return 1;
    } catch (e) {
      setSnack(errorMsg.failure);
      console.error("Error:", e.response.status);
      setToBackend(false);
      return 0;
    }
  };
  /*------------------------- handle save --------------------------------------------*/

  const handleSave = async (values) => {
    let EquipmentTypeIdFilter = equipmetvalue?.filter((data) => {
      return data.equipmentType === equipment;
    });
    let CategoryTypeIdFilter = categoryvalue?.filter((data) => {
      return data.equipmentCategory === category;
    });
    let StatusFilter = statusDrop?.filter((data) => {
      return data.label === activeFlag;
    });
    console.log(values.errorCode);
    const SelectedCustomerIds = isOEM
      ? getSelectedIds(
          stdField.customerName,
          customerId,
          "customerName",
          "customerId"
        )
      : [Number(sessionStorage.getItem("customerId"))];
    console.log(values.errorCode);
    let data = [
      isOEM
        ? {
            approvedBy: values.approvedBy,
            categoryId: CategoryTypeIdFilter[0]?.categoryId,
            createdBy: sessionStorage.getItem("id"),
            equipmentTypeId: EquipmentTypeIdFilter[0]?.equipmentTypeId,
            description: values?.description,
            errorMessage: values?.errorMessage,
            messageType: values?.messageType,
            errorCode: stdField?.errorCode,
            priority: mapPriority(priorityvalue),
            status: 1,
            customerIds: SelectedCustomerIds,
          }
        : {
            approvedBy: values.approvedBy,
            categoryId: CategoryTypeIdFilter[0]?.categoryId,
            createdBy: sessionStorage.getItem("id"),
            equipmentTypeId: EquipmentTypeIdFilter[0]?.equipmentTypeId,
            description: values?.description,
            errorMessage: values?.errorMessage,
            messageType: values?.messageType,
            errorCode: stdField?.errorCode,
            priority: mapPriority(priorityvalue),
            status: 1,
            customerIds: SelectedCustomerIds,
            companyId: Number(sessionStorage.getItem("companyId")),
          },
    ];
    // console.log(data, 'data of save');
    // return 0;
    try {
      setToBackend(true);
      const responseData = await postErrortype(data);
      setValuedata(data[0]?.equipmentType);
      fetchData(data[0]?.equipmentTypeId);
      if (responseData.statusCode === 200) {
        fetchData();
        setSnack({
          ...snack,
          open: true,
          severity: "success",
          message: responseData.statusDescription,
        });
        handleClose();
        setToBackend(false);
        return 1;
      }
    } catch (e) {
      setSnack(errorMsg.failure);
      console.error("Error:", e.response.status);
      console.log("error:", e.message);
      setToBackend(false);
      return 0;
    }
  };

  /*-------------------------Table get call --------------------------------------------*/
  const fetchData = async (equipmentTypeId) => {
    setloading(true);
    try {
      const data = await fetcherrorTableById(equipmentTypeId);
      // const reverse = [...data].reverse();
      if (Array.isArray(data)) {
        setSiteError(data);
        setloading(false);
      } else if (typeof data === "object" && data !== null) {
        setSiteError([data]);
        setloading(false);
      }

      console.log(data);
    } catch (e) {
      setloading(false);
      console.error(e);
    }
  };

  const getCustomerList = async () => {
    try {
      let data = await GetCustomerdropdowndata();
      console.log(data, "GetCustomerdropdowndata");
      if (Array.isArray(data)) {
        setCustomerId(data);
      } else if (typeof data === "object" && data !== null) {
        setCustomerId([data]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  // const getcompanylist = async () => {
  //   try {
  //     let data = await Getdropdowndata();
  //     setcustomerId(data);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  // const handleCompany = (data, value) => {
  //   console.log(value, "comp name");
  //   if (value === null) {
  //     setstdcompany("");
  //   } else {
  //     setstdcompany(value);
  //   }
  // };

  const fetchEquipmenttype = async () => {
    const data = await Equipmenttypedropdown();
    if (Array.isArray(data)) {
      // If data is an array, set tableValue to data directly
      setEquipmevalue(data);
    } else if (typeof data === "object" && data !== null) {
      // If data is an object, wrap it in an array and set tableValue
      setEquipmevalue([data]);
    }
    console.log(data);
  };

  const fetchEquipmentcategory = async () => {
    const data = await Equipmentcategorydropdown();
    if (Array.isArray(data)) {
      setCategoryvalue(data);
    } else if (typeof data === "object" && data !== null) {
      setCategoryvalue([data]);
    }
  };

  const fetchStatus = async () => {
    const data = await Statusdropdown();
    if (Array.isArray(data)) {
      setStatusDrop(data);
    } else if (typeof data === "object" && data !== null) {
      setStatusDrop([data]);
    }
  };

  const fetchCategoryByCustomerID = async (customerId) => {
    const data = await categoryByCustomerId(customerId);
    if (Array.isArray(data)) {
      setCategoryvalue(data);
    } else if (typeof data === "object" && data !== null) {
      setCategoryvalue([data]);
    }
  };
  const requiredHeader = [
    "Error Code",
    "Equipment Category",
    "Equipment Type",
    "Description",
    "Error Message",
    "Error Type",
    "Priority",
    "Approved By",
  ];
  if (isOEM) {
    requiredHeader.push("Customer Name");
  }
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setModalvalue(false);
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const headers = [];
        const range = XLSX.utils.decode_range(sheet["!ref"]);
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: C });
          const cell = sheet[cellAddress];
          if (cell) {
            headers.push(cell.v);
          }
        }
        const headersPresented = requiredHeader
          .map((header) => header.toLowerCase().replace(/ /g, ""))
          .every((header) =>
            headers
              .map((head) => head.toLowerCase().replace(/ /g, ""))
              .includes(header)
          );
        console.log(headers, "headers");
        const extraHeaders = headers.filter(
          (header) => !requiredHeader.includes(header)
        );
        const hasExtraHeaders = extraHeaders.length > 0;
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        const jsonDataTable = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const updatedJsonData = jsonData.map((row) => {
          requiredHeader.forEach((header) => {
            if (!row.hasOwnProperty(header)) {
              row[header] = ""; // Add missing header with empty string value
            }
          });
          return row;
        });
        if (!headersPresented || jsonData.length === 0) {
          console.log("headersPresented", !headersPresented);
          setSnack({
            ...snack,
            open: true,
            severity: "error",
            message: !headersPresented
              ? "Enter the correct format"
              : jsonData.length === 0
              ? "Excel couldn't be empty"
              : null,
          });
          setExcelOpen(false);
          handleClose();
        } else {
          setExcelOpen(true);
          setSelectedFileData(updatedJsonData);
        }
        // }
        const errors = [];
        jsonData.forEach((row, rowIndex) => {
          let header = Object.keys(row);
          let cellValue = Object.values(row);
          cellValue.forEach((value, cellIndex) => {
            let columnName = header[cellIndex];
            if (value === undefined || value === null || value === "") {
              errors.push({ rowIndex, columnName });
            }
          });
        });
        setErrorIndex(errors);
      };
      reader.readAsBinaryString(file);
    }
  };
  /*-------------------------report Name list dropdown --------------------------------------------*/
  const handleExcelUpload = async () => {
    const file = selectedFile;
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        const errors = [];
        selectedFileData.forEach((row, rowIndex) => {
          // Ensure that these fields are treated as strings before calling .length or .toLowerCase()
          const approver = row[requiredHeader[7]]
            ? row[requiredHeader[7]].toString()
            : "";
          const descriptionLen = row[requiredHeader[3]]
            ? row[requiredHeader[3]].toString().length
            : 0;
          const errMsgLen = row[requiredHeader[4]]
            ? row[requiredHeader[4]].toString().length
            : 0;
          const priority = row[requiredHeader[6]]
            ? row[requiredHeader[6]].toString().toLowerCase()
            : "";

          const validationForm = [
            {
              condition: approver.length > 50,
              errorMessage: "Approved by cannot exceed 50 characters.",
            },
            {
              condition: descriptionLen > 115,
              errorMessage: "Description cannot exceed 100 characters.",
            },
            {
              condition: errMsgLen > 50,
              errorMessage: "Error message cannot exceed 50 characters.",
            },
            {
              condition: !["high", "medium", "low"].includes(priority),
              errorMessage: 'Priority must be "High", "Medium", or "Low".',
            },
          ];

          validationForm.forEach((validation) => {
            if (validation.condition) {
              errors.push(validation.errorMessage);
              let error = [
                {
                  rowIndex,
                  columnName: validation.errorMessage,
                  lengthValidation: true,
                },
              ];
              setErrorIndex((prev) => [...prev, ...error]);
            }
          });
        });

        console.log(errorIndex, "errors");
        console.log(errorIndex, "errors");
        const processedData = jsonData.map((item) => {
          const lowercaseItem = {};
          for (const key in item) {
            if (Object.prototype.hasOwnProperty.call(item, key)) {
              lowercaseItem[key.toLowerCase().replace(/\s/g, "")] =
                typeof item[key] === "string" ? item[key].trim() : item[key];
            }
          }
          const customerName = !isOEM
            ? sessionStorage.getItem("customerName")
            : lowercaseItem.customername;
          let data = {
            errorCode: lowercaseItem.errorcode,
            approvedBy: lowercaseItem.approvedby,
            equipmentCategory: lowercaseItem?.equipmentcategory,
            createdBy: Number(sessionStorage.getItem("id")),
            equipmentType: lowercaseItem?.equipmenttype,
            description: lowercaseItem.description,
            errorMessage: lowercaseItem.errormessage,
            messageType: lowercaseItem.errortype,
            priority: mapPriority(lowercaseItem.priority),
            status: 1,
            customerName: customerName,
          };
          // console.log(lowercaseItem, 'lowercaseItem');
          return data;
        });
        console.log(processedData, "processedData");
        // let headerError = `'${headerCheck}' Column is missing!`;
        // // if (headerCheck.length !== 0) {
        // //   setSnack({
        // //     ...snack,
        // //     open: true,
        // //     severity: 'warning',
        // //     message: headerError,
        // //   });
        // } else {
        try {
          console.log(errors, "wrong is correct!");
          if (errorIndex.length > 0) {
            setSnack({
              ...snack,
              open: true,
              severity: "error",
              message: `${errorIndex[0].columnName} is missing`,
            });
          } else if (errors.length > 0) {
            // errors.forEach((error) => {
            setSnack({
              ...snack,
              open: true,
              severity: "error",
              message: errors[0],
              // });
            });
          } else {
            setToBackend(true);
            console.log(processedData);
            
            const responseData = await postErrorexceldata(processedData);
            // const responseData = ""
            if (responseData.status === 200) {
              fetchData();
              handleClose();
              // setSnack(errorMsg.success);
              setSnack({
                ...snack,
                open: true,
                severity: "success",
                message: responseData.data.statusDescription,
              });
            }
            console.log(responseData, "responseData");
            setToBackend(false);
          }
        } catch (e) {
          if (e.response.status === 400) {
            const errorResponse = e.response.data;
            const errorValue = Object.keys(errorResponse).map(
              (key) => errorResponse[key]?.error
            );
            setErrorVal(errorValue);
            console.log(errorValue, "errorResponse");
            // const errorResponsee = e.response.data;
            // console.log(errorResponsee);
            const indexValue = Object.keys(errorResponse).map(
              (key) => errorResponse[key]?.index
            );
            setIndexVal(indexValue);
            console.log(indexValue);
            if (errorValue?.length == 1) {
              setSnack({
                ...snack,
                open: true,
                severity: "error",
                message: errorValue,
              });
            } else {
              setSnack(errorMsg.failure);
            }
          } else {
            setSnack(errorMsg.failure);
          }
          setToBackend(false);
        }
      };
      reader.readAsBinaryString(file);
    }
  };
  console.log(errorIndex, "categoryvaluez");
  const handleactiveFlag = (data, event) => {
    setactiveFlag(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      status: data.target.innerHTML,
    }));
  };
  const handleEquipmentvalue = (data, value) => {
    setEquipment(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      equipmentType: data.target.innerHTML,
    }));
  };
  const handlePriority = (data, event) => {
    Setpriorityvalue(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      priority: data.target.innerHTML,
    }));
  };
  const handleCategoryvalue = (data, value) => {
    setCategory(data.target.innerHTML);
    setstdField((prevState) => ({
      ...prevState,
      equipmentCategory: data.target.innerHTML,
      equipmentType: null,
    }));
  };
  console.log(category);
  function convertToOptions(array) {
    if (!Array.isArray(array)) {
      array = [array];
    }
    return array.map((label) => ({ label }));
  }

  const handleDropDownChanges = (event, values, name) => {
    console.log(values, "handle Drop Down Changes", name);

    values = values.map((item) =>
      typeof item === "string" ? { label: item } : item
    );

    const labelCounts = {};
    values.forEach((item) => {
      labelCounts[item.label] = (labelCounts[item.label] || 0) + 1;
    });

    const filteredValues = values.filter(
      (item) => labelCounts[item.label] === 1
    );

    console.log("Current values selected:", filteredValues);

    setstdField((prev) => {
      const updated = {
        ...prev,
        [name]: filteredValues.map((value) => value.label),
      };
      console.log("Updated state:", updated);
      return updated;
    });
    if (name === "customerName") {
      let customId = customerId.filter(
        (custom) => custom.customerName === values[0]?.label
      );
      console.log(customId, "customId");
      if (customId.length > 0) {
        fetchCategoryByCustomerID(customId[0]?.customerId);
      }
    }
  };

  /*---------------------------------- New Error Call -----------------------*/
  const handleCrate = () => {
    setOpen(true);
    seteditcall("");
  };
  const handleClose = () => {
    setSelectedFileData(null);
    setstdField(initalState);
    seteditcall("");
    setCategory("");
    setactiveFlag("");
    setModalvalue(true);
    setEdit(false);
    setOpen(false);
    setErrorVal(null);
    setIndexVal(null);
    setExcelOpen(false);
    isOEM && setCategoryvalue([]);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "80%",
    height: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 5,
  };
  const styleTable = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  const status = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ];

  const handleEditOpen = async (errorId) => {
    let filterederrorId = Object.keys(initalState).reduce((acc, key) => {
      if (errorId.hasOwnProperty(key)) {
        acc[key] = errorId[key];
      }
      return acc;
    }, {});
    seteditcall(errorId);
    setstdField(filterederrorId);
    setCategory(errorId.equipmentCategory);
    setEdit(true);
    setOpen(true);
    let customId = customerId.filter(
      (custom) => custom.customerName === filterederrorId.customerName
    );
    fetchCategoryByCustomerID(customId[0].customerId);
  };

  const handleDownloadTable = async (val) => {
    const status = await Download(val, "ErrorConfig");
    if (status === 0) {
      setSnack(errorMsg.Download);
    }
  };
  const fetchTypeList = async () => {
    const data = await fetchErrorTypeDetails();
    if (Array.isArray(data) && data.length > 0) {
      setEquipmTypeList(data);
      setValuedata(data[0]?.equipmentType); // Set first value by default
      await fetchData(data[0]?.equipmentTypeId); // Fetch data for the first item
    }
  };

  // const fetchTypeList = async () => {
  //   const data = await fetchErrorTypeDetails();
  //   await fetchData(data[0]?.equipmentTypeId);
  //   setValuedata(data[0]?.equipmentType);
  //   if (Array.isArray(data)) {
  //     setEquipmTypeList(data);
  //   } else if (typeof data === 'object' && data !== null) {
  //     setEquipmTypeList([data]);
  //   }
  // };
  const handleEquipTypeFilter = (val) => {
    console.log("Selected value:", val);
    if (val) {
      const selectedType = equipmTypeList.find(
        (data) => data.equipmentType === val
      );
      if (selectedType) {
        setValuedata(selectedType.equipmentType);
        fetchData(selectedType.equipmentTypeId); // Fetch table data for the selected type
      }
    } else {
      setValuedata(null);
      setSiteError([]); // Clear the table data
    }
  };
  useEffect(() => {
    // Fetch the equipment type list when the component mounts
    fetchTypeList();
  }, []);
  // const handleEquipTypeFilter = (val) => {
  //   console.log('Selected value:', val);
  //   if (val) {
  //     const equipmentIdFilter = equipmTypeList?.find((data) => data.equipmentType === val);
  //     console.log('Filtered site:', equipmentIdFilter);
  //     if (equipmentIdFilter) {
  //       setValuedata(equipmentIdFilter.equipmentType);
  //       fetchData(equipmentIdFilter.equipmentTypeId);
  //     }
  //   } else {
  //     // Clear the site selection
  //     setValuedata(null);
  //     // Clear the table data
  //     setSiteError([]);
  //     // Optionally fetch all equipment data
  //     // fetchData();
  //   }
  // };

  const headCells = [
    {
      label: "Error Code",
      id: "errorCode",
      view: true,
    },
    {
      label: "Equipment Category",
      id: "equipmentCategory",
      view: true,
      default: true,
    },
    {
      label: "Equipment Type",
      id: "equipmentType",
      view: true,
      default: true,
    },
    {
      label: "Description",
      id: "description",
      view: false,
    },
    {
      label: "Error Message",
      id: "errorMessage",
      view: true,
    },
    {
      label: "Error Type",
      id: "messageType",
      view: true,
    },
    {
      label: "Priority",
      id: "priority",
      view: true,
    },
    {
      label: "Approved By",
      id: "approvedBy",
      view: false,
    },
    {
      label: "Created On",
      id: "createdDate",
      view: false,
    },
    {
      label: "Status",
      id: "status",
      view: true,
    },
    {
      label: "Action",
      id: "action",
      view: true,
      default: true,
    },
  ];
  // const tableValues = siteError.filter((row) => {
  //   if (siteTab !== '') {
  //     return Number(row.status) === Number(siteTab);
  //   } else {
  //     return row;
  //   }
  // });
  const tableValues = siteError.filter((row) => {
    if (Valuedata) {
      return row.equipmentType === Valuedata; // Match the equipmentType with selected Valuedata
    } else {
      return row; // Return all rows if no specific Valuedata
    }
  });

  const handleChangeTab = (newValue) => {
    switch (newValue) {
      case "all":
        setSiteTab("");
        break;
      case "Active":
        setSiteTab(1);
        break;
      case "Inactive":
        setSiteTab(0);
        break;
      default:
        setSiteTab("");
    }
  };
  const Total = siteError.length;

  const Active = siteError.filter((site) => site.status == 1).length;

  const Inactive = siteError.filter((site) => site.status == 0).length;
  console.log(Active);
  const tabsData = [
    { name: "All Error", value: "all", badgeCount: Total },
    { name: "Active Error", value: "Active", badgeCount: Active },
    {
      name: "Inactive Error",
      value: "Inactive",
      badgeCount: Inactive,
    },
  ];
  useEffect(() => {
    if (!isOEM) {
      let customId = sessionStorage.customerId;
      fetchCategoryByCustomerID(customId);
    }
  }, [isOEM]);
  useEffect(() => {
    const SiteTypeIdFilter = categoryvalue?.filter((data) => {
      return data.equipmentCategory == category;
    });
    setSiteTypeIdFilter(SiteTypeIdFilter);
  }, [category, categoryvalue, stdField.status]);

  console.log(SiteTypeIdFilter, "SiteTypeIdFilter");
  const filteredCatOption = categoryvalue?.map(
    (option) => option?.equipmentCategory
  );
  console.log(filteredCatOption, "filteredCatOption");

  const filteredTypeOptions = equipmetvalue
    ?.filter((option) => SiteTypeIdFilter[0]?.categoryId === option?.categoryId)
    ?.map((option) => option?.equipmentType);

  function getOptions(propName) {
    let option = [];
    switch (propName) {
      case "equipmentCategory":
        return (option = filteredCatOption);
      case "equipmentType":
        return (option = filteredTypeOptions);
      case "priority":
        return (option = priorityOptions);
      case "status":
        return (option = statusDrop);
      case "customerName":
        return (option = customerId.map((option) => {
          return `${option.customerName}`;
        }));
      default:
    }
    return option;
  }
  console.log(errorIndex, "errorIndexy");
  const paths = [
    { label: "Home", path: "/menu" },
    { label: "Error Configuration", path: "error" },
  ];
  const customerValdiation = yup.object().shape({
    customerName: edit
      ? yup.string()
      : yup.array().min(1, "Customer Name is Required"),
  });
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <FadeLoader color="#000440" loading={true} />
        </div>
      ) : (
        <div>
          <Formik
            key={edit ? "edit" : "create"}
            enableReinitialize={true}
            initialValues={stdField}
            validationSchema={
              isOEM && !edit
                ? ErrorConfigSchema.concat(customerValdiation)
                : ErrorConfigSchema
            }
            onSubmit={async (values, { resetForm }) => {
              let submitValue = {
                errorMessage: values.errorMessage,
                messageType: values.messageType,
                description: values.description,
                approvedBy: values.approvedBy,
              };
              if (edit) {
                const result = await handleUpdate(submitValue);
                if (result === 1) {
                  resetForm();
                }
              } else {
                console.log(values, "valueses");
                const result = await handleSave(submitValue);
                if (result === 1) {
                  resetForm();
                }
              }
            }}
          >
            {({ values, handleChange, handleSubmit, handleReset }) => (
              <Form>
                <div>
                  {/* ---------   -----save andupdate---- popup----------- */}
                  {modalvalue ? (
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                      }}
                    >
                      <Fade in={open}>
                        <Box
                          className={"styleModalSmall"}
                          sx={{
                            border: "none",
                            outline: "none",
                          }}
                        >
                          <Card
                            sx={{
                              borderRadius: "20px",
                              // height: '68vh',
                              height: "fit-content",
                            }}
                          >
                            <CardHeader
                              sx={{
                                padding: "20px 20px 20px 20px",
                                background: CheckForLight()
                                  ? "rgb(246, 248, 252)"
                                  : "#4f4f4f",
                              }}
                              action={
                                <Stack spacing={2} direction="row">
                                  {edit ? (
                                    <Button
                                      variant="contained"
                                      disabled={toBackend}
                                      style={{
                                        borderRadius: "25px",
                                        textTransform: "capitalize",
                                      }}
                                      startIcon={
                                        toBackend ? (
                                          <CircularProgress
                                            size={20}
                                            color="inherit"
                                          />
                                        ) : (
                                          <SaveIcon />
                                        )
                                      }
                                      onClick={toBackend ? null : handleSubmit}
                                    >
                                      {toBackend ? "Updating..." : "Update"}
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      disabled={toBackend}
                                      style={{
                                        borderRadius: "25px",
                                        textTransform: "capitalize",
                                      }}
                                      startIcon={
                                        toBackend ? (
                                          <CircularProgress
                                            size={20}
                                            color="inherit"
                                          />
                                        ) : (
                                          <SaveIcon />
                                        )
                                      }
                                      onClick={toBackend ? null : handleSubmit}
                                    >
                                      {toBackend ? "Saving..." : "Save"}
                                    </Button>
                                  )}
                                  <Button
                                    variant="contained"
                                    disabled={toBackend}
                                    style={{
                                      borderRadius: "25px",
                                      textTransform: "capitalize",
                                    }}
                                    onClick={() => {
                                      handleClose();
                                      handleReset();
                                    }}
                                    startIcon={<CancelIcon />}
                                  >
                                    Cancel
                                  </Button>
                                </Stack>
                              }
                              title={edit ? "Update Error" : "Add New Error"}
                            />
                            <Divider style={{ borderColor: "#888" }} />
                            <div className="modelGrid">
                              <Card
                                sx={{
                                  overflowY: "visible",
                                  marginLeft: "-1vw",
                                  // paddingLeft: '-1vw',
                                  marginTop: "-2%",
                                  marginBottom: "-2%",
                                  scrollBehavior: "smooth",
                                  scrollbarGutter: "stable",
                                  scrollbarWidth: "thin",
                                  "&::-webkit-scrollbar": {
                                    width: "0.4em",
                                  },
                                  "&::-webkit-scrollbar-track": {
                                    background: "#f1f1f1",
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "#888",
                                    borderRadius: "20px",
                                  },
                                  "&::-webkit-scrollbar-thumb:hover": {
                                    background: "#555",
                                  },
                                }}
                                elevation={0}
                              >
                                <CardContent>
                                  <Grid
                                    container
                                    spacing={2}
                                    columns={16}
                                    sx={{
                                      marginBottom: "20px",
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    {textField?.length > 0
                                      ? textField.map(
                                          (data, index) =>
                                            data.view && (
                                              <Grid item xs={4}>
                                                <div key={index}>
                                                  <Typography
                                                    variant="body1"
                                                    className="modal-typo"
                                                    gutterBottom
                                                  >
                                                    {data.label}
                                                  </Typography>
                                                  {data.type === "textField" ? (
                                                    <>
                                                      <Field
                                                        as={TextField}
                                                        disabled={toBackend}
                                                        id={`outlined-basic-${index}`}
                                                        size="small"
                                                        variant="outlined"
                                                        name={data.name}
                                                        inputProps={{
                                                          maxLength:
                                                            data.length,
                                                        }}
                                                        onChange={(e) => {
                                                          handleChange(e);
                                                          handleChangeTxt(e);
                                                        }}
                                                        placeholder={
                                                          data.placeholder
                                                        }
                                                        value={
                                                          values[data.name]
                                                        }
                                                        sx={{ width: "13vw" }}
                                                      />
                                                      <ErrorMessage
                                                        name={data.name}
                                                        component="div"
                                                        className="errorStyle"
                                                        style={{
                                                          color: "red",
                                                          marginTop: "1%",
                                                          textAlign: "left",
                                                          marginLeft: "0%",
                                                        }}
                                                      />
                                                    </>
                                                  ) : data.type ===
                                                      "dropdown" &&
                                                    data.view ? (
                                                    <>
                                                      <Tooltip
                                                        title={
                                                          !edit &&
                                                          data.name === "status"
                                                            ? "Active"
                                                            : edit
                                                            ? data.name ===
                                                                "status" &&
                                                              activeFlag === ""
                                                              ? values[
                                                                  data.name
                                                                ] == 1
                                                                ? "Active"
                                                                : "Inactive"
                                                              : data.name ===
                                                                "priority"
                                                              ? values[
                                                                  data.name
                                                                ] === 1
                                                                ? "Low"
                                                                : values[
                                                                    data.name
                                                                  ] === 2
                                                                ? "Medium"
                                                                : values[
                                                                    data.name
                                                                  ] === 3
                                                                ? "High"
                                                                : values[
                                                                    data.name
                                                                  ]
                                                              : values[
                                                                  data.name
                                                                ]
                                                            : values[data.name]
                                                        }
                                                      >
                                                        <Field
                                                          name={data.name}
                                                          render={({
                                                            field,
                                                            form,
                                                          }) => (
                                                            <Tooltip
                                                              title={
                                                                !edit &&
                                                                data.name ===
                                                                  "status"
                                                                  ? "Active"
                                                                  : edit
                                                                  ? data.name ===
                                                                      "status" &&
                                                                    activeFlag ===
                                                                      ""
                                                                    ? values[
                                                                        data
                                                                          .name
                                                                      ] == 1
                                                                      ? "Active"
                                                                      : "Inactive"
                                                                    : data.name ===
                                                                      "priority"
                                                                    ? values[
                                                                        data
                                                                          .name
                                                                      ] === 1
                                                                      ? "Low"
                                                                      : values[
                                                                          data
                                                                            .name
                                                                        ] === 2
                                                                      ? "Medium"
                                                                      : values[
                                                                          data
                                                                            .name
                                                                        ] === 3
                                                                      ? "High"
                                                                      : values[
                                                                          data
                                                                            .name
                                                                        ]
                                                                    : values[
                                                                        data
                                                                          .name
                                                                      ]
                                                                  : values[
                                                                      data.name
                                                                    ]
                                                              }
                                                            >
                                                              <Autocomplete
                                                                disableClearable
                                                                autoHighlight
                                                                disabled={
                                                                  toBackend ||
                                                                  (!edit &&
                                                                    data.name ===
                                                                      "status") ||
                                                                  (isOEM &&
                                                                    values
                                                                      .customerName
                                                                      .length ===
                                                                      0 &&
                                                                    data.name ===
                                                                      "equipmentCategory") ||
                                                                  (isOEM &&
                                                                    data.name ===
                                                                      "equipmentType" &&
                                                                    (category ===
                                                                      null ||
                                                                      category.length ===
                                                                        0))
                                                                }
                                                                options={
                                                                  getOptions(
                                                                    data?.name
                                                                  ) || []
                                                                }
                                                                disablePortal
                                                                size="small"
                                                                id={`combo-box-demo-${index}`}
                                                                onChange={(
                                                                  event,
                                                                  values
                                                                ) =>
                                                                  data.name ===
                                                                  "equipmentCategory"
                                                                    ? handleCategoryvalue(
                                                                        event
                                                                      )
                                                                    : data.name ===
                                                                      "equipmentType"
                                                                    ? handleEquipmentvalue(
                                                                        event
                                                                      )
                                                                    : data.name ===
                                                                      "priority"
                                                                    ? handlePriority(
                                                                        event
                                                                      )
                                                                    : handleactiveFlag(
                                                                        event
                                                                      )
                                                                }
                                                                value={
                                                                  !edit &&
                                                                  data.name ===
                                                                    "status"
                                                                    ? "Active"
                                                                    : edit
                                                                    ? data.name ===
                                                                        "status" &&
                                                                      activeFlag ===
                                                                        ""
                                                                      ? values[
                                                                          data
                                                                            .name
                                                                        ] == 1
                                                                        ? "Active"
                                                                        : "Inactive"
                                                                      : data.name ===
                                                                        "priority"
                                                                      ? values[
                                                                          data
                                                                            .name
                                                                        ] === 1
                                                                        ? "Low"
                                                                        : values[
                                                                            data
                                                                              .name
                                                                          ] ===
                                                                          2
                                                                        ? "Medium"
                                                                        : values[
                                                                            data
                                                                              .name
                                                                          ] ===
                                                                          3
                                                                        ? "High"
                                                                        : values[
                                                                            data
                                                                              .name
                                                                          ]
                                                                      : values[
                                                                          data
                                                                            .name
                                                                        ]
                                                                    : values[
                                                                        data
                                                                          .name
                                                                      ]
                                                                }
                                                                sx={{
                                                                  width: "13vw",
                                                                }}
                                                                ListboxProps={{
                                                                  style: {
                                                                    maxHeight:
                                                                      "200px",
                                                                  },
                                                                }}
                                                                renderInput={(
                                                                  params
                                                                ) => (
                                                                  <TextField
                                                                    {...params}
                                                                    placeholder={
                                                                      data.placeholder
                                                                    }
                                                                  />
                                                                )}
                                                              />
                                                            </Tooltip>
                                                          )}
                                                        />
                                                      </Tooltip>
                                                      <ErrorMessage
                                                        name={data.name}
                                                        component="div"
                                                        className="errorStyle"
                                                        style={{
                                                          color: "red",
                                                          marginTop: "1%",
                                                          textAlign: "left",
                                                          marginLeft: "0%",
                                                        }}
                                                      />
                                                    </>
                                                  ) : data.type ===
                                                    "dropdownMulti" ? (
                                                    // =<Field
                                                    //  name={data.name}
                                                    //  render={({ field, form }) => (
                                                    <>
                                                      <Autocomplete
                                                        multiple
                                                        id="checkboxes-tags-demo"
                                                        options={getOptions(
                                                          data.name
                                                        )}
                                                        limitTags={1}
                                                        disableClearable
                                                        disableCloseOnSelect
                                                        sx={{ width: "13vw" }}
                                                        size="small"
                                                        disabled={edit}
                                                        onChange={(
                                                          event,
                                                          values
                                                        ) => {
                                                          console.log(
                                                            values,
                                                            "handler"
                                                          );
                                                          handleDropDownChanges(
                                                            event,
                                                            values,
                                                            data.name
                                                          );
                                                        }}
                                                        value={
                                                          stdField[data.name]
                                                            ? convertToOptions(
                                                                stdField[
                                                                  data.name
                                                                ]
                                                              )
                                                            : []
                                                        }
                                                        placeholder={
                                                          data.placeholder
                                                        }
                                                        renderOption={(
                                                          props,
                                                          option
                                                        ) => (
                                                          <li {...props}>
                                                            <Checkbox
                                                              icon={icon}
                                                              checkedIcon={
                                                                checkedIcon
                                                              }
                                                              style={{
                                                                marginRight: 8,
                                                              }}
                                                              checked={stdField[
                                                                data.name
                                                              ].includes(
                                                                option
                                                              )}
                                                            />
                                                            {console.log(
                                                              option
                                                            )}
                                                            {option}
                                                          </li>
                                                        )}
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <TextField
                                                            {...params}
                                                            placeholder={
                                                              data.placeholder
                                                            }
                                                          />
                                                        )}
                                                      />
                                                      <ErrorMessage
                                                        name={data.name}
                                                        component="div"
                                                        className="errorStyle"
                                                        style={{
                                                          color: "red",
                                                          marginTop: "1%",
                                                          textAlign: "left",
                                                          marginLeft: "0%",
                                                        }}
                                                      />
                                                    </>
                                                  ) : data.type === "number" ? (
                                                    <>
                                                      <Field
                                                        as={TextField}
                                                        id={`outlined-basic-${index}`}
                                                        size="small"
                                                        variant="outlined"
                                                        name={data.name}
                                                        type={data.type}
                                                        onChange={(e) => {
                                                          handleChange(e);
                                                          handleChangeTxt(e);
                                                        }}
                                                        inputProps={{
                                                          maxLength: 10,
                                                        }}
                                                        placeholder={
                                                          data.placeholder
                                                        }
                                                        value={
                                                          values[data.name] ===
                                                          null
                                                            ? ""
                                                            : values[data.name]
                                                        }
                                                        sx={{
                                                          width: "12vw",
                                                        }}
                                                      />
                                                      <ErrorMessage
                                                        name={data.name}
                                                        component="div"
                                                        className="errorStyle"
                                                        style={{
                                                          color: "red",
                                                          marginTop: "1%",
                                                          textAlign: "left",
                                                          marginLeft: "0%",
                                                        }}
                                                      />
                                                    </>
                                                  ) : null}
                                                </div>
                                              </Grid>
                                            )
                                        )
                                      : null}
                                  </Grid>
                                </CardContent>
                              </Card>
                            </div>
                          </Card>
                        </Box>
                      </Fade>
                    </Modal>
                  ) : (
                    <div>
                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={excelOpen}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                          backdrop: {
                            timeout: 500,
                          },
                        }}
                      >
                        <Fade in={excelOpen}>
                          <Box
                            className={"styleModalSmall"}
                            sx={{
                              border: "none",
                              outline: "none",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <Card
                              sx={{
                                borderRadius: "20px",
                                height: "fit-content",
                                width: "fit-content",
                                marginLeft: "-2.5%",
                              }}
                            >
                              <CardHeader
                                sx={{
                                  padding: "20px 20px 20px 20px",
                                  background: CheckForLight()
                                    ? "rgb(246, 248, 252)"
                                    : "#4f4f4f",
                                }}
                                action={
                                  <Stack spacing={2} direction="row">
                                    <Button
                                      variant="contained"
                                      disabled={
                                        errorIndex?.length > 0 ||
                                        indexVal?.length > 0 ||
                                        toBackend
                                      }
                                      style={{
                                        borderRadius: "25px",
                                        textTransform: "capitalize",
                                      }}
                                      startIcon={
                                        toBackend ? (
                                          <CircularProgress
                                            size={20}
                                            color="inherit"
                                          />
                                        ) : (
                                          <SaveIcon />
                                        )
                                      }
                                      onClick={() =>
                                        toBackend ? null : handleExcelUpload()
                                      }
                                    >
                                      Submit
                                    </Button>
                                    <Button
                                      variant="contained"
                                      disabled={toBackend}
                                      style={{
                                        borderRadius: "25px",
                                        textTransform: "capitalize",
                                      }}
                                      onClick={handleClose}
                                      startIcon={<CancelIcon />}
                                    >
                                      Cancel
                                    </Button>
                                  </Stack>
                                }
                                title={"Add New Errors"}
                              />
                              <Divider
                                sx={{ marginTop: "0%", borderColor: "#888" }}
                              />
                              <CardContent>
                                <Card
                                  sx={{
                                    overflowY: "auto",
                                    maxHeight: "40vh",
                                    maxWidth: "75vw",
                                    marginLeft: "0vw",
                                    // padding: '-1vw',
                                    marginTop: "0%",
                                    marginBottom: "-1%",
                                    scrollBehavior: "smooth",
                                    scrollbarGutter: "stable",
                                    scrollbarWidth: "thin",
                                    "&::-webkit-scrollbar": {
                                      width: "0.4em",
                                    },
                                    "&::-webkit-scrollbar-track": {
                                      background: "#f1f1f1",
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                      backgroundColor: "#888",
                                      borderRadius: "20px",
                                    },
                                    "&::-webkit-scrollbar-thumb:hover": {
                                      background: "#555",
                                    },
                                  }}
                                  elevation={2}
                                >
                                  <CardContent sx={{ padding: 0 }}>
                                    {selectedFileData && (
                                      <div>
                                        <Table
                                          sx={{ width: "100%" }}
                                          size="small"
                                          aria-label="a dense table"
                                        >
                                          {/* <TableHead>
                                <TableRow>
                                  {Object.keys(selectedFileData[0]).map(
                                    (header, index) => (
                                      <TableCell key={index}>
                                        {header}
                                      </TableCell>
                                    )
                                  )}
                                </TableRow>
                              </TableHead> */}
                                          <TableHead>
                                            <TableRow>
                                              {requiredHeader.map(
                                                (header, index) => (
                                                  <TableCell
                                                    sx={{
                                                      borderBottom: "none",
                                                      textAlign: "center",
                                                    }}
                                                    key={index}
                                                  >
                                                    {header}
                                                  </TableCell>
                                                )
                                              )}
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {selectedFileData.map(
                                              (row, rowIndex) => {
                                                return (
                                                  <Tooltip
                                                    key={rowIndex}
                                                    title={(() => {
                                                      let errorMessage = "";
                                                      // for (
                                                      //   let i = 0;
                                                      //   i < errorIndex.length;
                                                      //   i += 2
                                                      // ) {
                                                      //   if (
                                                      //     errorIndex[i] === rowIndex
                                                      //   ) {
                                                      //     errorMessage = `${errorIndex[i + 1]
                                                      //       } is missing`;
                                                      //     break;
                                                      //   }
                                                      // }
                                                      errorIndex.forEach(
                                                        (error) => {
                                                          if (
                                                            !errorMessage &&
                                                            error?.rowIndex ===
                                                              rowIndex
                                                          ) {
                                                            errorMessage =
                                                              error?.lengthValidation
                                                                ? error?.columnName
                                                                : `${error?.columnName} is Missing`;
                                                          }
                                                        }
                                                      );
                                                      if (
                                                        !errorMessage &&
                                                        indexVal?.includes(
                                                          rowIndex
                                                        )
                                                      ) {
                                                        errorMessage =
                                                          errorVal[
                                                            indexVal.indexOf(
                                                              rowIndex
                                                            )
                                                          ];
                                                      }
                                                      return errorMessage;
                                                    })()}
                                                    placement="top"
                                                  >
                                                    <TableRow
                                                      key={rowIndex}
                                                      style={
                                                        // {
                                                        // border:
                                                        //   errorIndex?.includes(
                                                        //     rowIndex
                                                        //   ) &&
                                                        //     errorIndex?.includes(rowIndex)
                                                        //     ? '2px solid red'
                                                        //     : indexVal?.includes(
                                                        //       rowIndex
                                                        //     ) &&
                                                        //       indexVal.includes(
                                                        //         rowIndex
                                                        //       )
                                                        //       ? '2px solid red'
                                                        //       : 'none',
                                                        // backgroundColor:
                                                        //   errorIndex.length !== 0
                                                        //     ? errorIndex.includes(
                                                        //       rowIndex
                                                        //     )
                                                        //       ? '#ff070721'
                                                        //       : 'none'
                                                        //     : indexVal?.includes(
                                                        //       rowIndex
                                                        //     )
                                                        //       ? '#ff070721'
                                                        //       : 'none',
                                                        // }
                                                        errorIndex.some(
                                                          (error) =>
                                                            error?.rowIndex ===
                                                            rowIndex
                                                        ) ||
                                                        indexVal?.includes(
                                                          rowIndex
                                                        )
                                                          ? {
                                                              border:
                                                                "2px solid red",
                                                              backgroundColor:
                                                                "#ff070721",
                                                            }
                                                          : {}
                                                      }
                                                    >
                                                      {requiredHeader.map(
                                                        (header, colIndex) => (
                                                          <TableCell
                                                            sx={{}}
                                                            key={colIndex}
                                                            style={{
                                                              border: "none",
                                                              borderTop: "none",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            {row[header]}
                                                          </TableCell>
                                                        )
                                                      )}
                                                    </TableRow>
                                                  </Tooltip>
                                                );
                                              }
                                            )}
                                          </TableBody>
                                        </Table>
                                      </div>
                                    )}
                                  </CardContent>
                                </Card>
                              </CardContent>
                            </Card>
                          </Box>
                        </Fade>
                      </Modal>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>

          {/* ---------------pass props value----------- */}
          <div style={{ marginTop: "-0.5%" }}>
            <CustomSnackbar
              open={snack.open}
              onClose={handleSnackClose}
              autoHideDuration={5000}
              severity={snack.severity}
              message={snack.message}
            />
            <TableTemplate
              PageName={"Error Configuration"}
              uploadButton={true}
              addButton={"Error"}
              SearchLabel={"Search Error Here... "}
              header={headCells}
              rowsValue={tableValues}
              tabsData={tabsData}
              userRole={errorConfiguration[0]}
              rawData={siteError}
              handleChange={handleChangeTab}
              // handleEquipmentTypeChange={(val) => handleEquipTypeFilter(val)}
              typevalue={Valuedata}
              handleEquipmentTypeChange={handleEquipTypeFilter}
              equipmentTypeList={equipmTypeList}
              handleDownloadTemplate={() =>
                downloadExcelTemplate("Error", isOEM ? true : false)
              }
              handleUploadProp={(e) => handleFileUpload(e)}
              handleAddPopupOpen={(val) => handleCrate(val)}
              handleEditPopupOpen={(val) => handleEditOpen(val)}
              handleDownloadExcel={(val) => {
                handleDownloadTable(val);
              }}
              paths={paths}
              fallbackText={`No${
                siteTab === 1 ? " Active" : siteTab === 0 ? " Inactive" : ""
              } Error has been configured yet`}
            />
          </div>

          {/*--------------------------------------- Modal Download Popup ---------------*/}
        </div>
      )}
    </div>
  );
}
