import React, { useMemo, useEffect } from "react";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { fetchEquipmentIdDetails } from "../../Api/ExcelUploadAPi";
import {
  CheckForLight,
  formatNumber,
  getUnit,
} from "../util/common/CommanFunction";
import { IconButton } from "@mui/material";
// Initialize Highcharts modules
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
const CombinedChart = (props) => {
  const {
    tableData,
    widgetName,
    equipmentListAPI,
    customView,
    chartName,
    fallBack,
    from,
    range,
    basedOn,
    dailyChartType,
    isDashboard,
    customName,
  } = props;
  console.log(props, "props");
  const [barChart, setBarchartData] = React.useState();
  const [showTable, setShowTable] = React.useState(false);
  const [tableContent, setTableContent] = React.useState("");
  const WidgetName = widgetName;
  useEffect(() => {
    setBarchartData(tableData);
  }, [props]);

  const chartData = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => {
      if (chartName === "Actual Vs Expected") {
        return [
          customView?.range === "yearly" ||
          customView?.range === "yearlyCum" ||
          range === "yearly" ||
          range === "yearlyCum"
            ? data.timeStamp
              ? data.timeStamp
              : data.timestamp
            : new Date(
                data.timeStamp ? data.timeStamp : data.timestamp
              ).getTime(),
          data.todayEnergy,
        ];
      }
      return [
        customView.range === "daily"
          ? from === "analytics"
            ? equipmentListAPI.filter(
                (x) => x.equipmentId === data.equipmentId
              )[0].customernaming
            : data.equipmentName
          : customView?.range === "yearly" ||
            customView?.range === "yearlyCum" ||
            range === "yearly" ||
            range === "yearlyCum"
          ? data.timeStamp
            ? data.timeStamp
            : data.timestamp
          : new Date(
              data.timeStamp ? data.timeStamp : data.timestamp
            ).getTime(),
        data.todayEnergy,
      ];
    });
  }, [tableData]);

  const specificYiled = useMemo(() => {
    if (!tableData) return [];
    if (chartName === "Specific Yield Vs Irradiation") {
      return tableData.map((data) => [
        basedOn !== "Site"
          ? data.equipmentName
          : new Date(
              data.timeStamp ? data.timeStamp : data.timestamp
            ).getTime(),
        data.specificYield,
      ]);
    }
    return tableData.map((data) => [
      customView.range === "daily"
        ? from === "analytics"
          ? equipmentListAPI?.filter(
              (x) => x.equipmentId === data.equipmentId
            )[0].customernaming
          : data.equipmentName
        : customView?.range === "yearly" ||
          customView?.range === "yearlyCum" ||
          range === "yearly" ||
          range === "yearlyCum"
        ? data.timeStamp
          ? data.timeStamp
          : data.timestamp
        : new Date(data.timeStamp ? data.timeStamp : data.timestamp).getTime(),
      data.specificYield,
    ]);
  }, [tableData]);

  const irradiation = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => [
      basedOn !== "Site"
        ? data.equipmentName
        : new Date(data.timeStamp ? data.timeStamp : data.timestamp).getTime(),
      data.irradiation,
    ]);
  }, [tableData]);

  const equipmentName = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => data.equipmentName);
  }, [tableData]);

  /*--------------------------- Actual vs Expected ------------------*/

  const Irradiation = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => [
      customView?.range === "yearly" ||
      customView?.range === "yearlyCum" ||
      range === "yearly" ||
      range === "yearlyCum"
        ? data.timeStamp
          ? data.timeStamp
          : data.timestamp
        : new Date(data.timeStamp ? data.timeStamp : data.timestamp).getTime(),
      data.irradiation,
    ]);
  }, [tableData]);

  const ExceptedEnergy = useMemo(() => {
    if (!tableData) return [];
    return tableData.map((data) => [
      customView?.range === "yearly" ||
      customView?.range === "yearlyCum" ||
      range === "yearly" ||
      range === "yearlyCum"
        ? data.timeStamp
          ? data.timeStamp
          : data.timestamp
        : new Date(data.timeStamp ? data.timeStamp : data.timestamp).getTime(),
      data.exceptedEnergy,
    ]);
  }, [tableData]);

  let specificYieldSeries;
  let irradiationSeries;
  const groupedData = tableData.reduce((acc, data) => {
    const equipment = acc.find(
      (item) => item.equipmentName === data.equipmentName
    );
    const timeStamp = new Date(data.timeStamp).getTime();

    if (equipment) {
      // If equipment already exists, push new data
      equipment.specificYield.push([timeStamp, data.specificYield]);
      equipment.irradiation.push([timeStamp, data.irradiation]);
    } else {
      // If equipment does not exist, create a new entry
      acc.push({
        equipmentName: data.equipmentName,

        specificYield: [[timeStamp, data.specificYield]],
        irradiation: [[timeStamp, data.irradiation]],
      });
    }
    return acc;
  }, []);

  specificYieldSeries = groupedData.map((item) => ({
    name: item.equipmentName,
    data: item.specificYield,
    type: dailyChartType === "daily" ? "line" : "column",
    yAxis: 1,
  }));

  // Step 1: Aggregate irradiation values for each unique timestamp
  const irradiationMap = {};

  // Collect irradiation values for each equipment
  groupedData.forEach((item) => {
    item.irradiation.forEach(([timestamp, value]) => {
      if (!irradiationMap[timestamp]) {
        irradiationMap[timestamp] = [];
      }
      irradiationMap[timestamp].push(value); // Collect values for the same timestamp
    });
  });

  // Step 2: Convert the map into the desired format for the irradiation series
  irradiationSeries = [
    {
      name: "Irradiation",
      data: Object.entries(irradiationMap).map(([timestamp, values]) => {
        return [Number(timestamp), values[0]];
      }),
      color: "#AD1378",
      type: "line",
      yAxis: 2,
    },
  ];

  const isLight = CheckForLight();
  const nameForFile = isDashboard ? customName : widgetName;
  const columnChartOptions = {
    chart: {
      type: "column",
      backgroundColor: isLight ? "white" : "#121212",
    },
    title: {
      text: chartName === "Specific Yield" ? ` ${WidgetName} ` : WidgetName,
      style: {
        textTransform: "captalize",
        color: isLight ? "black" : "white",
        fontWeight: "normal",
      },
    },
    credits: {
      enabled: false,
    },

    exporting: {
      filename: nameForFile + "-" + new Date().toLocaleDateString("en-GB"),
      csv: {
        dateFormat: "%d/%m/%Y",
        columnHeaderFormatter: function (item, key) {
          console.log(item, key);
          if (item.coll === "xAxis") {
            if (item.dateTime) {
              return "Date";
            } else {
              return "Equipment";
            }
          }

          if (key === "y") {
            return `${item.name}${getUnit(item.name.replace(/\s+/g, ""))}`;
          }
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadJPEG",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadCSV",
            "downloadXLS",
            // from === 'analytics' ? '' : 'viewData',
            from === "Vanalytics"
              ? {
                  text: "View Table",
                  onclick: function () {
                    const chart = this;
                    let dataHtml = chart.getTable(); // Generate HTML table from chart data

                    // Replace empty cells with 0
                    dataHtml = dataHtml.replace(
                      /<td class="highcharts-empty">\s*<\/td>/g,
                      '<td class="highcharts-number">0</td>'
                    );
                    // Format timestamps in the table
                    dataHtml = dataHtml.replace(
                      /<th class="highcharts-text" scope="row">(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})<\/th>/g,
                      function (match, p1) {
                        const date = new Date(p1);
                        return `<th class="highcharts-text" scope="row">${date.toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "numeric",
                            year: "numeric",
                          }
                        )} </th>`;
                      }
                    );

                    setTableContent(dataHtml); // Store the table content in state
                    setShowTable(true); // Show the table
                  },
                }
              : "",
          ],
        },
      },
      enabled: true,
      // showTable: true,
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick: function () {
            const series = this.chart.series;
            const currentSeries = this;

            if (series.length === 2) {
              const [series1, series2] = series;

              if (currentSeries === series1) {
                // If series1 is clicked
                if (series1.visible && !series2.visible) {
                  // Both series should be visible if series1 is visible and series2 is not
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                } else if (!series1.visible && series2.visible) {
                  // series1 should be visible and series2 should be hidden if series1 is not visible and series2 is visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: false }, false);
                } else if (series1.visible && series2.visible) {
                  // If both are visible, make series2 invisible, and series1 remains visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: false }, false);
                } else if (!series1.visible && !series2.visible) {
                  // If both are not visible, make both visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                }
              } else if (currentSeries === series2) {
                // If series2 is clicked
                if (series2.visible && !series1.visible) {
                  // Both series should be visible if series2 is visible and series1 is not
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                } else if (!series2.visible && series1.visible) {
                  // series2 should be visible and series1 should be hidden if series2 is not visible and series1 is visible
                  series1.update({ visible: false }, false);
                  series2.update({ visible: true }, false);
                } else if (series1.visible && series2.visible) {
                  // If both are visible, make series1 invisible, and series2 remains visible
                  series1.update({ visible: false }, false);
                  series2.update({ visible: true }, false);
                } else if (!series2.visible && !series1.visible) {
                  // If both are not visible, make both visible
                  series1.update({ visible: true }, false);
                  series2.update({ visible: true }, false);
                }
              }

              // Finally, redraw the chart
              this.chart.redraw();
            } else {
              // Handle charts with more than two series or other cases
              this.chart.update(
                {
                  series: series.map((s) => {
                    if (s === currentSeries) {
                      return {
                        visible: currentSeries.visible ? false : true,
                      };
                    } else {
                      const visbleSeries = series.filter(
                        (series) => series.visible
                      );

                      if (
                        visbleSeries.length > 1 &&
                        visbleSeries.length < series.length - 1
                      ) {
                        visbleSeries.forEach((series) => {
                          series.update({ visible: false }, false);
                        });
                      }
                      return {
                        visible: s.visible
                          ? visbleSeries.length > 1 &&
                            visbleSeries.length < series.length - 1
                            ? true
                            : false
                          : true,
                      };
                    }
                  }),
                },
                true
              );
            }

            return false; // Prevent the default action of hiding the clicked series
          },
        },
      },
    },
    xAxis:
      (chartName === "Specific Yield" && customView.range === "daily") ||
      (chartName === "Specific Yield Vs Irradiation" &&
        basedOn !== "Site" &&
        customView.timeperiod === "Daily") ||
      ((customView?.range === "yearly" ||
        customView?.range === "yearlyCum" ||
        range === "yearly" ||
        range === "yearlyCum") &&
        (chartName === "Actual Vs Expected" || chartName === "Specific Yield"))
        ? {
            type: "category",
            labels: {
              style: {
                color: isLight ? "black" : "white",
                fontSize: isDashboard ? "10px" : "14px",
              },
            },
          }
        : {
            type: "datetime",
            dateTimeLabelFormats: {
              day: "%d/%m/%Y",
            },
            title: {
              text: "Time (HH:MM)",
              style: {
                color: isLight ? "black" : "white",
                fontSize: isDashboard ? "10px" : "0.8em",
              },
            },
            labels: {
              style: {
                color: isLight ? "black" : "white",
                fontSize: isDashboard ? "10px" : "14px",
              },
              formatter: function () {
                return Highcharts.dateFormat("%d/%m/%Y", this.value);
              },
            },
          },
    yAxis: [
      {
        title: {
          text: "Energy Generation (kWh)",
          style: {
            color: isLight ? "black" : "white",
            fontSize: isDashboard ? "10px" : "0.8em",
          },
        },
        visible: chartName === "Specific Yield Vs Irradiation" ? false : true,
        style: {
          color: "#666666",
          fontSize: isDashboard ? "10px" : "0.8em",
          fontFamily: "Inter",
        },
        labels: {
          style: {
            color: isLight ? "black" : "white",
            fontSize: isDashboard ? "10px" : "14px",
          },
        },
      },
      {
        title: {
          text: "Specific Yield",
          color: isLight ? "black" : "white",
          fontSize: isDashboard ? "10px" : "14px",
        },
        opposite: true,
        visible: chartName === "Actual Vs Expected" ? false : true,
        min: 0,
        labels: {
          style: {
            color: isLight ? "black" : "white",
            fontSize: isDashboard ? "10px" : "14px",
          },
        },
      },
      {
        title: {
          text: "Irradiation (Wh/m2)",
          color: isLight ? "black" : "white",
          fontSize: isDashboard ? "10px" : "14px",
        },
        opposite: chartName === "Specific Yield Vs Irradiation" ? false : true,
        visible:
          chartName === "Actual Vs Expected" ||
          chartName === "Specific Yield Vs Irradiation"
            ? true
            : false,
        min: 0,
        // max: 10,
        labels: {
          style: {
            color: isLight ? "black" : "white",
            fontSize: isDashboard ? "10px" : "14px",
          },
        },
      },
    ],
    tooltip: {
      useHTML: true,
      backgroundColor: isLight ? "white" : "#121212",
      formatter: function ({ series, seriesIndex, dataPointIndex, w }) {
        const xLabel = this.point.options.name
          ? this.point.options.name
          : Highcharts.dateFormat(
              "%d/%m/%Y",
              new Date(this.point.options.x).getTime()
            );
        if (this.series.name === "Energy Generation (kWh)") {
          return `<div class="custom-tooltip" style="color:${
            isLight ? "black" : "white"
          };">
            <p>${xLabel}</p>
            <p>Energy Generation: ${formatNumber(this.point.y)} (kWh)</p>
          </div>`;
        } else if (this.series.name === "Specific Yield") {
          return `<div class="custom-tooltip" style="color:${
            isLight ? "black" : "white"
          };">
            <p>${xLabel}</p>
            <p>Specific Yield : ${formatNumber(this.point.y)} </p>
          </div>`;
        } else if (this.series.name === "Irradiation") {
          return `<div class="custom-tooltip" style="color:${
            isLight ? "black" : "white"
          };">
            <p>${xLabel}</p>
            <p>Irradiation (Wh/m2): ${formatNumber(this.point.y)} </p>
          </div>`;
        } else if (this.series.name === "Total Energy") {
          const name =
            chartName === "Actual Vs Expected"
              ? "Total Energy"
              : "Today Energy";
          return `<div class="custom-tooltip" style="color:${
            isLight ? "black" : "white"
          };">
            <p>${xLabel}</p>
            <p>${name} (kWh): ${formatNumber(this.point.y)} </p>
          </div>`;
        } else if (this.series.name === "ExceptedEnergy") {
          return `<div class="custom-tooltip" style="color:${
            isLight ? "black" : "white"
          };">
            <p>${xLabel}</p>
            <p>Excepted Energy (kWh): ${formatNumber(this.point.y)} </p>
          </div>`;
        } else {
          return `<div class="custom-tooltip" style="color:${
            isLight ? "black" : "white"
          };">
            <p>${xLabel}</p>
            <p>Name: ${this.series.name} </p>
            <p>Specific Yield: ${formatNumber(this.point.y)} </p>
          </div>`;
        }
      },
    },
    legend: {
      maxHeight: 110,
      itemStyle: {
        color: isLight ? "black" : "white",
        fontSize: isDashboard ? "10px" : "14px",
      },
      itemHoverStyle: {
        color: isLight ? "gray" : "lightgray",
      },
    },
    series:
      chartName === "Specific Yield"
        ? [
            {
              name: "Energy Generation (kWh)",
              data: chartData,
              type: "column",
              color: "LightSkyBlue",
            },
            {
              name: "Specific Yield",
              data: specificYiled,
              type: "line",
              yAxis: 1,
              color: "Navy",
            },
          ]
        : chartName === "Actual Vs Expected"
        ? [
            {
              name: "Total Energy",
              data: chartData,
              yAxis: 0,
              min: 0,
              type: "column",
              color: "LightSkyBlue",
            },
            {
              name: "ExceptedEnergy",
              data: ExceptedEnergy,
              type: "column",
              yAxis: 0,
              min: 0,
              color: "Navy",
            },
            {
              name: "Irradiation",
              data: Irradiation,
              type: "line",
              yAxis: 2,
              min: 0,
              color: "#AD1378",
            },
          ]
        : chartName === "Specific Yield Vs Irradiation" &&
          customView.timeperiod !== "Daily" &&
          basedOn !== "Site"
        ? [...specificYieldSeries, ...irradiationSeries]
        : chartName === "Specific Yield Vs Irradiation"
        ? [
            {
              name: "Specific Yield",
              data: specificYiled,
              type: dailyChartType === "daily" ? "line" : "column",
              yAxis: 1,
              min: 0,
              color: "DodgerBlue",
            },
            {
              name: "Irradiation",
              data: irradiation,
              type: "line",
              yAxis: 2,
              min: 0,
              color: "#AD1378",
            },
          ]
        : "",
  };

  console.log(columnChartOptions, "columnChartOptions");

  return (
    <div
      style={{
        overflowX: "auto",
        width: "100%",
        height: from === "Vanalytics" ? "500px" : null,
      }}
    >
      {tableData?.length > 0 ? (
        showTable ? (
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                  height: "40px",
                  marginRight: "10px",
                }}
                variant="contained"
                size="small"
                onClick={() => setShowTable(false)}
              >
                <ArrowBackOutlinedIcon />
              </IconButton>
              <h2>{WidgetName.split("-")[0]} Data Table</h2>
            </div>
            <div
              className="highcharts-data-table"
              dangerouslySetInnerHTML={{ __html: tableContent }}
            />
          </div>
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{
              style: {
                width: "100%",
                height: from === "Vanalytics" ? "500px" : null,
              },
            }}
            options={columnChartOptions}
          />
        )
      ) : (
        fallBack
      )}
    </div>
  );
};
export default CombinedChart;
