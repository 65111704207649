import axios from 'axios';

export const getFileModeApi = async () => {
  try {
    let responseData = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_FTP_FILEMODE}`
    );

    return responseData.data;
  } catch (error) {
    console.error(`api get error in : ${error.message}`);
  }
};

export const getFileTypeApi = async () => {
  try {
    let responseData = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_FTP_FILETYPE}`
    );

    return responseData.data;
  } catch (error) {
    console.error(`api get error in : ${error.message}`);
  }
};

export const getSiteConfigBySiteId = async (id) => {
  try {
    let responseData = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_FTP_SITE_CONFIG}/${id}`
    );

    return responseData.data;
  } catch (error) {
    console.error(`api get error in : ${error.message}`);
  }
};

export const getSiteMasterData = async (id) => {
  console.log(id);
  try {
    let responseData = await axios.get(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_MASTER_DATA}/${id}`
    );
    return responseData.data;
  } catch (error) {
    console.error(`api get error in : ${error.message}`);
  }
};

export const createFtpDirect = async (data) => {
  try {
    let responseData = await axios.post(
      `${process.env.REACT_APP_FTP_API_PATH}${process.env.REACT_APP_FTP_CREATE_REPO}`,
      data
    );
    console.log(responseData.data, 'API Response Data');
    return responseData;
  } catch (error) {
    console.error(`api get error in : ${error.message}`);
  }
};

export const getFileFrequency = async (siteCode) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_FTP_API_PATH}${process.env.REACT_APP_FILEFREQUENCY}${siteCode}/CONFIG`
    );
    return response.data;
  } catch (error) {
    console.error(`API error in getFileFrequency: ${error.message}`);
    throw error;
  }
};

export const UpdateFTPConfig = async (data) => {
  console.log(data);
  try {
    let responseData = await axios.put(
      `${process.env.REACT_APP_API_PATH}${process.env.REACT_APP_FTP_UPDATE}/${data?.site_Configurationid}`,
      data
    );
    return responseData;
  } catch (error) {
    console.error(`api get error in : ${error.message}`);
  }
};


export const Ftpget = async (obj) => {
  console.log(obj);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_CHART_API_PATH_OLD}${process.env.REACT_APP_API_FTP_GETFILE}`,
      obj,
      {
        responseType: 'blob', 
      }
    );
    return response;
  } catch (error) {
    console.error('Error posting data:', error.message);
    throw error;
  }
};